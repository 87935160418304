import vueI18n from '@/plugins/vue-i18n';
import spreadsheet from '@icon/connection/spreadsheet.svg';
import qb from '@icon/connection/qb.svg';
import amazon from '@icon/connection/amazon.svg';
import customApi from '@icon/connection/custom_api.svg';
import bc365 from '@icon/connection/bc365.svg';
import aggregation from '@icon/new/aggregation.svg';
import database from '@icon/connection/database.svg';
import sap from '@icon/connection/sap.svg';
import netsuite from '@icon/connection/oracle_netsuite.svg';

export const integrationStatuses = {
  UNINITIALIZED: 'Uninitialized',
  LOADING: 'Loading',
  IMPORTED: 'Imported',
  POPULATED: 'Populated',
  FAILED_TO_IMPORT: 'FailedToImport'
};

export const integrationModuleTypes = {
  IMPORT: 'importer',
  EXPORT: 'exporter'
};

export const integrationExportTypes = {
  PLANNED_ORDERS: 'orders',
  REPORTS: 'reports'
};

export const integrationTypes = {
  AGGREGATION: 'aggregation',
  SPREADSHEET: 'orderlist',
  QB_DESKTOP: 'qb',
  AMAZON: 'amazon',
  CUSTOM_API: 'customapi',
  BC365: 'bc365',
  DATABASE: 'qdb',
  SAP: 'sapb1',
  NETSUITE: 'netsuite'
};

export const databaseRelatedIntegrations = [
  integrationTypes.DATABASE,
  integrationTypes.SAP,
  integrationTypes.NETSUITE
];

export const predefinedIntegrations = [
  integrationTypes.AGGREGATION,
  integrationTypes.QB_DESKTOP,
  integrationTypes.AMAZON,
  integrationTypes.BC365,
  integrationTypes.SAP,
  integrationTypes.NETSUITE
];

export const connectionlessIntegrations = [
  integrationTypes.CUSTOM_API
];

// used in route params and locale
export const integrationSteps = {
  // shared between import and export
  CONNECT: 'connect',

  // import related only
  PULL_DATA: 'pull-data',
  TRANSFORM: 'transform',

  // export related only
  SETUP: 'setup',

  // spreadsheet related only
  UPLOAD_FILE: 'upload-file',
  MATCH_SHEETS: 'match-sheets'
};

export const stepStatuses = {
  INCOMPLETE: 'incomplete',
  COMPLETED: 'completed',
  NO_AVAILABLE: 'no-available'
};

export const integrationsConfig = {
  [integrationTypes.AGGREGATION]: {
    icon: aggregation
  },
  [integrationTypes.SPREADSHEET]: {
    icon: spreadsheet,
    steps: {
      [integrationModuleTypes.IMPORT]: {
        [integrationSteps.UPLOAD_FILE]: {
          component: 'SpreadsheetUploadFile'
        },
        [integrationSteps.MATCH_SHEETS]: {
          component: 'SpreadsheetMatchSheets',
          tooltip: vueI18n.tc('Web.Integrations.Tooltips.MatchSheetsDisabled')
        },
        [integrationSteps.PULL_DATA]: {
          component: 'SpreadsheetPullData'
        }
      }
    }
  },
  [integrationTypes.QB_DESKTOP]: {
    icon: qb,
    steps: {
      [integrationModuleTypes.IMPORT]: {
        [integrationSteps.CONNECT]: {
          component: 'QbConnect'
        },
        [integrationSteps.PULL_DATA]: {
          component: 'QbPullData'
        },
        [integrationSteps.TRANSFORM]: {
          component: 'ImportTransform'
        }
      },
      [integrationModuleTypes.EXPORT]: {
        [integrationSteps.CONNECT]: {
          component: 'QbConnect'
        }
      }
    }
  },
  [integrationTypes.AMAZON]: {
    icon: amazon,
    steps: {
      [integrationModuleTypes.IMPORT]: {
        [integrationSteps.CONNECT]: {
          component: 'AmazonConnect'
        },
        [integrationSteps.PULL_DATA]: {
          component: 'AmazonPullData'
        },
        [integrationSteps.TRANSFORM]: {
          component: 'ImportTransform'
        }
      },
      [integrationModuleTypes.EXPORT]: {
        [integrationSteps.CONNECT]: {
          component: 'AmazonConnect'
        }
      }
    }
  },
  [integrationTypes.BC365]: {
    icon: bc365,
    steps: {
      [integrationModuleTypes.IMPORT]: {
        [integrationSteps.CONNECT]: {
          component: 'BC365Connect'
        },
        [integrationSteps.PULL_DATA]: {
          component: 'BC365PullData'
        },
        [integrationSteps.TRANSFORM]: {
          component: 'ImportTransform'
        }
      },
      [integrationModuleTypes.EXPORT]: {
        [integrationSteps.CONNECT]: {
          component: 'BC365Connect'
        },
        [integrationSteps.SETUP]: {
          component: 'BC365PullData'
        }
      }
    }
  },
  [integrationTypes.CUSTOM_API]: {
    icon: customApi,
    steps: {
      [integrationModuleTypes.IMPORT]: {
        [integrationSteps.PULL_DATA]: {
          component: 'CustomApiPullData'
        },
        [integrationSteps.TRANSFORM]: {
          component: 'ImportTransform'
        }
      },
      [integrationModuleTypes.EXPORT]: {
        [integrationSteps.SETUP]: {
          component: 'CustomApiSetUp'
        }
      }
    }
  },
  [integrationTypes.DATABASE]: {
    icon: database,
    steps: {
      [integrationModuleTypes.IMPORT]: {
        [integrationSteps.CONNECT]: {
          component: 'DatabaseConnect'
        },
        [integrationSteps.PULL_DATA]: {
          component: 'ImportTransform'
        }
      },
      [integrationModuleTypes.EXPORT]: {
        [integrationSteps.CONNECT]: {
          component: 'DatabaseConnect'
        },
        [integrationSteps.SETUP]: {
          component: 'ExportTransform'
        }
      }
    }
  },
  [integrationTypes.SAP]: {
    icon: sap,
    steps: {
      [integrationModuleTypes.IMPORT]: {
        [integrationSteps.CONNECT]: {
          component: 'SapConnect'
        },
        [integrationSteps.PULL_DATA]: {
          component: 'ImportTransform'
        }
      },
      [integrationModuleTypes.EXPORT]: {
        [integrationSteps.SETUP]: {
          component: 'SapSetUp'
        }
      }
    }
  },
  [integrationTypes.NETSUITE]: {
    icon: netsuite,
    steps: {
      [integrationModuleTypes.IMPORT]: {
        [integrationSteps.CONNECT]: {
          component: 'NetsuiteConnect'
        },
        [integrationSteps.PULL_DATA]: {
          component: 'ImportTransform'
        }
      },
      [integrationModuleTypes.EXPORT]: {
        [integrationSteps.CONNECT]: {
          component: 'NetsuiteConnect'
        },
        [integrationSteps.SETUP]: {
          component: 'NetsuiteSetUp'
        }
      }
    }
  }
};

export const TEMPLATE_PASSWORD = '********';
