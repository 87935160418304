<template>
  <div class="sl-table-pagination">
    <div class="sl-table-pagination__info body-1 grey-70">
      {{ currentShowing }}
      <span
        v-if="selected"
        class="sl-table-pagination__selected"
      >
        {{ $t('Web.Selection', { 1: selected }) }}
      </span>
    </div>

    <div class="sl-table-pagination__main">
      <div class="sl-table-pagination__prev">
        <SlButton
          variant="secondary"
          color="grey"
          size="md"
          :disabled="value === 1 || disabled"
          icon
          @click="previous"
          @keydown.enter="previous"
        >
          <icon
            data="@icons/arrow_left.svg"
            class="fill-off size-20"
          />
        </SlButton>
      </div>
      <div
        v-for="(n, i) of items"
        :key="`${n}-${i}`"
        class="sl-table-pagination__number body-1-md grey-70"
        :class="{
          'sl-table-pagination--active': n === value,
          'sl-table-pagination--disabled': checkIsNumberDisabled(n)
        }"
        :tabindex="getNumberTabindex(n)"
        @click="select(n)"
        @keydown.enter="select(n)"
      >
        {{ n }}
      </div>

      <div class="sl-table-pagination__next">
        <SlButton
          variant="secondary"
          color="grey"
          size="md"
          :disabled="value >= pageCount || disabled"
          icon
          @click="next"
          @keydown.enter="next"
        >
          <icon
            data="@icons/arrow_right.svg"
            class="fill-off size-20"
          />
        </SlButton>
      </div>
    </div>

    <div class="sl-table-pagination__per-page">
      <div class="body-1 grey-70">
        {{ $t('Web.Table.PerPage') }}
      </div>
      <SlDropdown
        placement="top"
        :target-label="perPage"
      >
        <template #content>
          <SlDropdownOption
            v-for="option in perPageOptions"
            :key="option"
            select-close
            @select="selectPerPage(option)"
          >
            {{ option }}
            <template #append>
              <icon
                v-if="option === perPage"
                data="@icons/check.svg"
                class="fill-off size-16 color-primary-80 check-icon"
              />
            </template>
          </SlDropdownOption>
        </template>
      </SlDropdown>
    </div>
  </div>
</template>

<script>

import { perPageOptions } from '@/config/shared/slTable.config';

export default {
  name: 'SlTablePagination',
  props: {
    value: {
      type: Number,
      required: true
    },
    totalCount: {
      type: Number,
      required: true
    },
    perPage: {
      type: Number,
      required: false,
      default: 100
    },
    totalVisible: {
      type: Number,
      required: false,
      default: 7
    },
    selected: {
      type: Number,
      default: 0
    },
    spacer: {
      type: String,
      default: '...'
    },
    disabled: Boolean
  },
  data() {
    return {
      perPageOptions
    };
  },
  computed: {
    pageCount() {
      return Math.ceil(this.totalCount / this.perPage);
    },
    items() {
      const totalVisible = parseInt(this.totalVisible, 10);

      if (totalVisible === 0) {
        return [];
      }

      const maxLength = Math.min(
        Math.max(0, totalVisible) || this.pageCount,
        this.pageCount
      );

      if (this.pageCount <= maxLength) {
        return this.range(1, this.pageCount);
      }

      const even = maxLength % 2 === 0 ? 1 : 0;
      const left = Math.floor(maxLength / 2);
      const right = this.pageCount - left + 1 + even;

      if (this.value > left && this.value < right) {
        const firstItem = 1;
        const lastItem = this.pageCount;
        const start = this.value - left + 2;
        const end = this.value + left - 2 - even;
        const secondItem = start - 1 === firstItem + 1 ? 2 : this.spacer;
        const beforeLastItem = end + 1 === lastItem - 1 ? end + 1 : this.spacer;

        return [1, secondItem, ...this.range(start, end), beforeLastItem, this.pageCount];
      }

      if (this.value === left) {
        const end = this.value + left - 1 - even;

        return [...this.range(1, end), this.spacer, this.pageCount];
      }

      if (this.value === right) {
        const start = this.value - left + 1;

        return [1, this.spacer, ...this.range(start, this.pageCount)];
      }

      return [
        ...this.range(1, left),
        this.spacer,
        ...this.range(right, this.pageCount)
      ];
    },
    itemsFrom() {
      return ((this.value - 1) * this.perPage) + 1;
    },
    itemsTo() {
      const to = this.value * this.perPage;

      if (to > this.totalCount) {
        return this.totalCount;
      }

      return to;
    },
    currentShowing() {
      return this.$t('Web.Table.PaginationLabel', {
        1: this.itemsFrom,
        2: this.itemsTo,
        3: this.totalCount
      });
    }
  },
  methods: {
    range(from, to) {
      const range = [];

      from = from > 0 ? from : 1;

      for (let i = from; i <= to; i++) {
        range.push(i);
      }

      return range;
    },
    previous() {
      this.$emit('input', this.value - 1);
    },
    next() {
      this.$emit('input', this.value + 1);
    },
    select(n) {
      if (n === this.value) {
        return;
      }

      this.$emit('input', n);
    },
    selectPerPage(pageSize) {
      this.$emit('per-page', pageSize);
    },
    getNumberTabindex(n) {
      return this.checkIsNumberDisabled(n) ? -1 : 0;
    },
    checkIsNumberDisabled(n) {
      return n === this.spacer || this.disabled || this.items.length === 1;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/ui-kit/sl-table/sl-table-pagination.scss";
</style>