import Vue from 'vue';
import i18n from '@/plugins/vue-i18n';
import demandApi from '@/api/demand';
import GenericTableAdapter from '@/adapters/response/GenericTable.adapter';
import ForecastVersionsAdapter from '@/adapters/request/ForecastVersions.adapter';
import statusCodes from '@/config/utils/statusCodes.config';
import { getTableConfigParams, getTableConfigStructure } from '@/helpers/shared/tableConfig';
import { toArray } from '@/helpers/utils/toArray';

const types = {
  SET_TABLE: 'SET_TABLE',
  SET_TABLE_CONFIG: 'SET_TABLE_CONFIG',
  UPDATE_TABLE_CONFIG: 'UPDATE_TABLE_CONFIG'
};

const state = () => ({
  table: null,
  tableConfig: getTableConfigStructure()
});

const mutations = {
  [types.SET_TABLE](state, value) {
    state.table = value;
  },
  [types.SET_TABLE_CONFIG](state, payload) {
    state.tableConfig = payload;
  },
  [types.UPDATE_TABLE_CONFIG](state, { key, value }) {
    Vue.set(state.tableConfig, key, value);
  }
};

const actions = {
  async editForecastVersions({ dispatch }, { payload, type }) {
    try {
      const response = await demandApi.editForecastVersions(ForecastVersionsAdapter(payload));

      if (payload.created && response?.status === statusCodes.OK) {
        Vue.notify({
          type: 'success',
          title: i18n.t('Web.Notification.CreatedSuccessfully', { 1: type })
        });
      }
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'editForecastVersions' });
    } finally {
      dispatch('fetchForecastVersions');
    }
  },
  async fetchForecastVersions({ state, commit }) {
    try {
      const response = await demandApi.fetchForecastVersions(getTableConfigParams(state.tableConfig));

      if (!response?.data) {
        return;
      }

      commit(types.SET_TABLE, GenericTableAdapter(response.data, row => {
        row.edit.roles = toArray(row.edit.roles);
        row.edit.users = toArray(row.edit.users);
        row.view.roles = toArray(row.view.roles);
        row.view.users = toArray(row.view.users);

        return row;
      }));
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchForecastVersions' });
    }
  },
  updateTableConfig({ commit }, payload) {
    commit(types.UPDATE_TABLE_CONFIG, payload);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};