export const contextMenuAdjustment = {
  pageX: 70,
  pageY: 30
};

export const fgsActionTypes = {
  ENABLED: 'enabled',
  VISIBLE: 'visible'
};

export const contextMenuOptionTypes = {
  DIVIDER: 'divider'
};

export const contextMenuKeys = {
  APPROVE: 'approve',
  NEEDS_ATTENTION: 'attention',
  CHECKED: 'checked',
  EXPORT_NODE_TO_XLSX: 'export-node-to-xlsx',
  CLEAR_OVERRIDES: 'clear-overrides',
  CLEAR_SETTINGS: 'clear-settings',
  CUT: 'cut',
  COPY: 'copy',
  PASTE: 'paste',
  DELETE: 'delete',
  APPLY_CELL_TO_FINAL_FORECAST: 'apply-cell-to-final-forecast',
  APPLY_ROW_TO_FINAL_FORECAST: 'apply-row-to-final-forecast',
  PROPAGATE_VALUE: 'propagate-value',
  COPY_ITEM_TO_CLIPBOARD: 'copy-item-to-clipboard',
  SUBSTITUTE: 'substitute',
  HIDE_COLUMN: 'hide-column',
  PIN_COLUMN: 'pin-column',
  INVENTORY_COL_VISIBILITY: 'inventory-col-visibility',
  REMOVE_FROM_IMPORT: 'remove-from-import',
  FILTER_BY_BOM: 'filter-by-bom',
  FILTER_BY: 'filter-by',
  FILTER_ADD: 'filter-add',
  SELECT_ALL: 'select-all',
  EDIT: 'edit',
  ADD_NOTE: 'add-note',
  EDIT_NOTE: 'edit-note',
  FIND_IN_DEMAND: 'find-in-demand',
  FIND_IN_INVENTORY: 'find-in-inventory',
  FIND_IN_REPORTS: 'find-in-reports',
  ITEM_BOM: 'material-product-tree',
  EXPLAIN_CALCULATION: 'explain-calculation',
  COPY_NODE_LINK: 'copy-node-link',
  OPEN_NODE_IN_NEW_TAB: 'open-node-in-new-tab',
  RESET_NEXT_ORDER_DATE: 'reset-next-order-date',
  COPY_CELL_FROM_STATISTICAL_FORECAST: 'copy-cell-from-statistical-forecast',
  COPY_ROW_FROM_STATISTICAL_FORECAST: 'copy-row-from-statistical-forecast',
  COPY_QTY_TO_OVERRIDE: 'copy-qty-to-override',
  ZOOM_OUT: 'zoom-out',
  IGNORE_ACTUAL_SALES_BEFORE: 'ignore-actual-sales-before',
  IGNORE_TREND_BEFORE: 'ignore-trend-before'
};

export const treeContextMenuOptions = (ctx) => ([
  {
    name: ctx.$t('Web.Tree.ContextOpenInNewTab'),
    key: contextMenuKeys.OPEN_NODE_IN_NEW_TAB
  },
  {
    name: ctx.$t('Web.Tree.ContextLinkAddress'),
    key: contextMenuKeys.COPY_NODE_LINK
  },
  {
    name: ctx.$t('Web.Tree.ContextCopyItemCode'),
    key: contextMenuKeys.COPY_ITEM_TO_CLIPBOARD
  },
  {
    type: 'divider'
  },
  {
    name: ctx.$t('Main.Ui.acApprove'),
    key: contextMenuKeys.APPROVE
  },
  {
    name: ctx.$t('Main.Ui.acNeedsAttention'),
    key: contextMenuKeys.NEEDS_ATTENTION
  },
  {
    name: ctx.$t('Main.Ui.acMarkChecked'),
    key: contextMenuKeys.CHECKED
  },
  {
    type: 'divider'
  },
  {
    name: ctx.$t('Main.Ui.acFindInInventoryReport'),
    key: contextMenuKeys.FIND_IN_INVENTORY,
    block: 'find-in'
  },
  {
    name: ctx.$t('Main.Ui.acFindInReports'),
    key: contextMenuKeys.FIND_IN_REPORTS,
    block: 'find-in'
  },
  {
    name: ctx.$t('Main.Ui.acItemBom'),
    key: contextMenuKeys.ITEM_BOM
  },
  {
    type: 'divider',
    block: 'find-in'
  },
  {
    name: ctx.$t('Main.Ui.acBanFromImport'),
    key: contextMenuKeys.REMOVE_FROM_IMPORT
  },
  {
    name: ctx.$t('Main.Ui.acReplaceWith'),
    key: contextMenuKeys.SUBSTITUTE
  },
  {
    name: ctx.$t('Main.AddNote'),
    key: contextMenuKeys.ADD_NOTE
  },
  {
    name: ctx.$t('Main.EditNote'),
    key: contextMenuKeys.EDIT_NOTE
  },
  {
    name: ctx.$t('Main.Ui.acClearOverrides'),
    key: contextMenuKeys.CLEAR_OVERRIDES
  },
  {
    name: ctx.$t('Main.ClearMultiple'),
    key: contextMenuKeys.CLEAR_SETTINGS,
    subList: clearSettingsConfig(ctx)
  },
  {
    name: ctx.$t('Main.LocalExportTable'),
    key: contextMenuKeys.EXPORT_NODE_TO_XLSX,
    subList: exportNodeToXlsxConfig(ctx)
  }
]);

export const treeContextMenuActionsMap = {
  [contextMenuKeys.COPY_ITEM_TO_CLIPBOARD]: 'copyItem',
  [contextMenuKeys.APPROVE]: 'approve',
  [contextMenuKeys.NEEDS_ATTENTION]: 'setNeedsAttention',
  [contextMenuKeys.CHECKED]: 'setChecked',
  [contextMenuKeys.CLEAR_OVERRIDES]: 'confirmClearNodeOverrides',
  [contextMenuKeys.CLEAR_SETTINGS]: 'clearNodeSettings',
  [contextMenuKeys.EXPORT_NODE_TO_XLSX]: 'exportNodeToXlsx',
  [contextMenuKeys.SUBSTITUTE]: 'handleSubstitute',
  [contextMenuKeys.REMOVE_FROM_IMPORT]: 'handleRemoveFromImport',
  [contextMenuKeys.ADD_NOTE]: 'handleEditNote',
  [contextMenuKeys.EDIT_NOTE]: 'handleEditNote',
  [contextMenuKeys.ITEM_BOM]: 'handleOpenBoms',
  [contextMenuKeys.FIND_IN_INVENTORY]: 'handleFindInInventory',
  [contextMenuKeys.FIND_IN_REPORTS]: 'handleFindInReports',
  [contextMenuKeys.COPY_NODE_LINK]: 'handleCopyNodeLink',
  [contextMenuKeys.OPEN_NODE_IN_NEW_TAB]: 'handleOpenNodeInNewTab'
};

export const exportNodeToXlsxConfig = (ctx) => ([
  {
    key: 'EXPORT_CURRENT_NODE_TABLE',
    title: ctx.$t('Main.Ui.acExportCurrentToXlsx')
  },
  {
    key: 'EXPORT_CHILD_NODES_TABLE',
    visitLeafs: true,
    title: ctx.$t('Main.Ui.acExportAllToXlsx')
  }
]);

export const clearSettingsConfig = (ctx) => ([
  {
    key: 'IS_MODEL_ACTIVE',
    fgs: 'IS_MODEL_ACTIVE',
    title: ctx.$t('Main.Ui.lbIsModelActive')
  },
  {
    key: 'FORECAST_APPROACH',
    fgs: 'CLEAR_FORECAST_APPROACH',
    title: ctx.$t('Main.Ui.acClearMultipleApproach')
  },
  {
    key: 'MODEL',
    fgs: 'CLEAR_MODEL_TYPE',
    title: ctx.$t('Main.Ui.acClearMultipleModelType')
  },
  {
    key: 'IGNORE_DATA_BEFORE',
    fgs: 'CLEAR_IGNORE_TREND',
    title: ctx.$t('Main.Ui.acClearMultipleIgnoreDataBefore')
  },
  {
    key: 'ZEROS_AND_LOST_SALES',
    fgs: 'CLEAR_IGNORE_ZERO_SALES',
    title: ctx.$t('Main.Ui.acClearMultipleZerosLostSales')
  },
  {
    key: 'IGNORE_LOST_DAYS',
    fgs: 'CLEAR_IGNORE_STOCKOUT_DAYS',
    title: ctx.$t('Main.Ui.acClearMultipleIgnoreLostDays')
  },
  {
    key: 'AUTODETECT_OUTLIERS',
    fgs: 'CLEAR_AUTODETECT_OUTLIERS',
    title: ctx.$t('Main.Ui.acClearMultipleDetectOutliers')
  },
  {
    key: 'PRICE_ELASTICITY',
    fgs: 'CLEAR_USE_PRICE_ELASTICITY',
    title: ctx.$t('Main.Ui.acClearMultiplePriceElasticity')
  },
  {
    key: 'HOLIDAYS',
    fgs: 'CLEAR_HOLIDAYS',
    title: ctx.$t('Main.Ui.acClearMultipleHolidays')
  },
  {
    key: 'SEASONALITY_PATTERN',
    fgs: 'CLEAR_SEASONAL_PATTERNS',
    title: ctx.$t('Main.Ui.acClearMultipleSeasonalityPatterns')
  },
  {
    key: 'PROMOTIONS',
    fgs: 'CLEAR_USE_PROMOTIONS',
    title: ctx.$t('Main.Ui.acClearMultiplePromotions')
  },
  {
    key: 'MODEL_ADOPTING',
    fgs: 'CLEAR_USE_MODEL_FROM',
    title: ctx.$t('Main.Ui.acClearMultipleModelAdopting')
  },
  {
    key: 'DEMAND_SENSING',
    fgs: 'CLEAR_DEMAND_SENSING',
    title: ctx.$t('Main.Ui.acClearMultipleDemandSensing')
  },
  {
    key: 'BILL_OF_MATERIAL_USE',
    fgs: 'CLEAR_MATERIAL_PROCUREMENT',
    title: ctx.$t('Main.Ui.acClearMultipleManufacture')
  },
  {
    key: 'APPROVAL_HORIZON',
    fgs: 'CLEAR_APPROVAL_HORIZON',
    title: ctx.$t('Main.Ui.acClearMultipleApprovalHorizon')
  },
  {
    key: 'ORDERING_AVAILABILITY',
    fgs: 'CLEAR_ORDERING_AVAILABILITY',
    title: ctx.$t('Main.Ui.acClearMultipleAllowance')
  },
  {
    key: 'MODEL_COEFFS',
    fgs: 'CLEAR_MODEL_COEFFS',
    title: ctx.$t('Main.Ui.acClearMultipleModelCoeffs')
  }
]);

export const demandTableContextMenuOption = (ctx) => ([
  {
    name: ctx.$t('Web.Edit.Cut'),
    key: contextMenuKeys.CUT
  },
  {
    name: ctx.$t('Web.Edit.Copy'),
    key: contextMenuKeys.COPY
  },
  {
    name: ctx.$t('Web.Edit.Paste'),
    key: contextMenuKeys.PASTE
  },
  {
    name: ctx.$t('Web.Edit.Delete'),
    key: contextMenuKeys.DELETE
  },
  {
    type: 'divider'
  },
  {
    name: ctx.$t('Main.AddNote'),
    key: contextMenuKeys.ADD_NOTE
  },
  {
    name: ctx.$t('Web.ContextMenu.CopyCellFromStatisticalForecast'),
    key: contextMenuKeys.COPY_CELL_FROM_STATISTICAL_FORECAST
  },
  {
    name: ctx.$t('Web.ContextMenu.CopyRowFromStatisticalForecast'),
    key: contextMenuKeys.COPY_ROW_FROM_STATISTICAL_FORECAST
  },
  {
    name: ctx.$t('Main.ApplyOvPeriod'),
    key: contextMenuKeys.APPLY_CELL_TO_FINAL_FORECAST
  },
  {
    name: ctx.$t('Main.ApplyOv'),
    key: contextMenuKeys.APPLY_ROW_TO_FINAL_FORECAST
  },
  {
    name: ctx.$t('Main.Propagate'),
    key: contextMenuKeys.PROPAGATE_VALUE
  },
  {
    name: ctx.$t('Main.Ui.acChooseExportToXlsx'),
    alwaysVisible: true,
    key: contextMenuKeys.EXPORT_NODE_TO_XLSX
  }
]);

export const demandTableContextMenuActionsMap = {
  [contextMenuKeys.COPY]: 'copyCell',
  [contextMenuKeys.CUT]: 'cutCell',
  [contextMenuKeys.DELETE]: 'deleteCell',
  [contextMenuKeys.PASTE]: 'pasteCell',
  [contextMenuKeys.ADD_NOTE]: 'handleAddNote',
  [contextMenuKeys.APPLY_CELL_TO_FINAL_FORECAST]: 'applyCellFinalForecast',
  [contextMenuKeys.APPLY_ROW_TO_FINAL_FORECAST]: 'applyRowFinalForecast',
  [contextMenuKeys.PROPAGATE_VALUE]: 'propagateValue',
  [contextMenuKeys.EXPORT_NODE_TO_XLSX]: 'handleExportNodeToXlsx',
  [contextMenuKeys.COPY_CELL_FROM_STATISTICAL_FORECAST]: 'copyCellFromStatisticalForecast',
  [contextMenuKeys.COPY_ROW_FROM_STATISTICAL_FORECAST]: 'copyRowFromStatisticalForecast'
};

export const inventoryHeaderContextMenuOptions = (ctx) => ([
  {
    name: (isPinned) => isPinned ? ctx.$t('Web.Table.Unpin') : ctx.$t('Web.Table.Pin'),
    key: contextMenuKeys.PIN_COLUMN
  },
  {
    name: ctx.$t('Main.CustomizeColumnsSubmenus.Hide'),
    key: contextMenuKeys.HIDE_COLUMN
  }
]);

export const inventoryHeaderContextActionsMap = {
  [contextMenuKeys.PIN_COLUMN]: 'handleToggleColumnPin',
  [contextMenuKeys.HIDE_COLUMN]: 'handleHideColumn'
};

export const inventoryTableContextMenuOptions = (ctx) => ([
  {
    name: ctx.$t('Main.Ui.acFilterBy'),
    key: contextMenuKeys.FILTER_BY
  },
  {
    name: ctx.$t('Main.Ui.acAddToFilter'),
    key: contextMenuKeys.FILTER_ADD
  },
  {
    name: ctx.$t('Main.Ui.acFilterBom'),
    key: contextMenuKeys.FILTER_BY_BOM
  },
  {
    type: 'divider'
  },
  {
    name: ctx.$t('Web.ContextMenu.FindInDemand'),
    key: contextMenuKeys.FIND_IN_DEMAND
  },
  {
    name: ctx.$t('Main.Ui.acFindInInventoryReport'),
    key: contextMenuKeys.FIND_IN_INVENTORY
  },
  {
    name: ctx.$t('Main.Ui.acFindInReports'),
    key: contextMenuKeys.FIND_IN_REPORTS
  },
  {
    type: 'divider'
  },
  {
    name: ctx.$t('Main.Ui.acItemBom'),
    key: contextMenuKeys.ITEM_BOM
  },
  {
    name: ctx.$t('Main.Ui.acBanFromImport'),
    key: contextMenuKeys.REMOVE_FROM_IMPORT
  },
  {
    name: ctx.$t('Main.Ui.acReplaceWith'),
    key: contextMenuKeys.SUBSTITUTE
  },
  {
    type: 'divider'
  },
  {
    name: ctx.$t('Main.Ui.acInventorySelectAll'),
    key: contextMenuKeys.SELECT_ALL
  },
  {
    name: ctx.$t('Main.Ui.acInventoryEdit'),
    key: contextMenuKeys.EDIT
  },
  {
    name: ctx.$t('Web.Edit.Copy'),
    key: contextMenuKeys.COPY
  },
  {
    name: ctx.$t('Web.Context.CopyFromQty'),
    key: contextMenuKeys.COPY_QTY_TO_OVERRIDE
  },
  {
    name: ctx.$t('Web.Tree.ContextCopyItemCode'),
    key: contextMenuKeys.COPY_ITEM_TO_CLIPBOARD
  },
  {
    name: ctx.$t('Main.AddNote'),
    key: contextMenuKeys.ADD_NOTE
  },
  {
    name: ctx.$t('Main.EditNote'),
    key: contextMenuKeys.EDIT_NOTE
  },
  {
    name: ctx.$t('Main.Ui.acClearOverrides'),
    key: contextMenuKeys.CLEAR_OVERRIDES
  },
  {
    name: ctx.$t('Main.Ui.acExplain'),
    key: contextMenuKeys.EXPLAIN_CALCULATION
  },
  {
    name: ctx.$t('Web.ContextMenu.ResetNextOrderDate'),
    key: contextMenuKeys.RESET_NEXT_ORDER_DATE
  }
]);

export const inventoryContextMenuActionsMap = {
  [contextMenuKeys.FILTER_BY]: 'filterBy',
  [contextMenuKeys.FILTER_ADD]: 'addToFilter',
  [contextMenuKeys.FILTER_BY_BOM]: 'filterByRelatedBOMs',
  [contextMenuKeys.COPY]: 'copyCell',
  [contextMenuKeys.COPY_ITEM_TO_CLIPBOARD]: 'copyItemCode',
  [contextMenuKeys.REMOVE_FROM_IMPORT]: 'handleRemoveFromImport',
  [contextMenuKeys.CLEAR_OVERRIDES]: 'handleClearOverrides',
  [contextMenuKeys.SELECT_ALL]: 'handleSelectAll',
  [contextMenuKeys.EDIT]: 'handleEdit',
  [contextMenuKeys.SUBSTITUTE]: 'handleSubstitute',
  [contextMenuKeys.ADD_NOTE]: 'handleEditNote',
  [contextMenuKeys.EDIT_NOTE]: 'handleEditNote',
  [contextMenuKeys.FIND_IN_DEMAND]: 'handleFindInDemand',
  [contextMenuKeys.FIND_IN_INVENTORY]: 'handleFindInInventory',
  [contextMenuKeys.FIND_IN_REPORTS]: 'handleFindInReports',
  [contextMenuKeys.ITEM_BOM]: 'handleOpenBoms',
  [contextMenuKeys.EXPLAIN_CALCULATION]: 'handleExplainCalculation',
  [contextMenuKeys.RESET_NEXT_ORDER_DATE]: 'handleResetNextOrderDate',
  [contextMenuKeys.COPY_QTY_TO_OVERRIDE]: 'handleCopyQtyToOverride'
};

//======================== REDESIGNED ====================================\\
export const demandChartOptions = (ctx, period) => ([
  {
    name: ctx.$t('Main.Ui.acChartZoomOut'),
    key: contextMenuKeys.ZOOM_OUT,
    group: 'canvas'
  },
  {
    name: ctx.$t('Main.IgnoreActualSales', { 1: period }),
    key: contextMenuKeys.IGNORE_ACTUAL_SALES_BEFORE
  },
  {
    name: ctx.$t('Main.IgnoreBefore', { 1: period }),
    key: contextMenuKeys.IGNORE_TREND_BEFORE
  }
]);

export const demandChartActionsMap = {
  [contextMenuKeys.ZOOM_OUT]: 'zoomOut',
  [contextMenuKeys.IGNORE_ACTUAL_SALES_BEFORE]: 'ignoreActualSalesBefore',
  [contextMenuKeys.IGNORE_TREND_BEFORE]: 'ignoreTrendBefore'
};
