<template>
  <SlSelect
    v-bind="$attrs"
    :max-height="1000"
    class="sl-table-select-wrapper"
    :placeholder="' '"
    :class="{
      [`sl-table-select--${type}`]: type,
      [`color--${foreground}`]: foreground,
      'sl-table-select-wrapper--editable-bg': editableBg
    }"
    :append-to-body="appendToBody"
    :append-to-body-option-selector="`sl-table-select-options--${type}`"
    @mousedown.right.native.prevent.stop.capture
    @input="(value) => $emit('input', value)"
  />
</template>

<script>
export default {
  name: 'SlTableSelect',
  inheritAttrs: false,
  props: {
    type: {
      type: String,
      default: 'header',
      validator: (t) => ['header', 'row'].includes(t)
    },
    foreground: {
      type: String,
      default: ''
    },
    appendToBody: {
      type: Boolean,
      default: true
    },
    editableBg: Boolean
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/ui-kit/sl-table/inputs/sl-table-select.scss";
</style>
