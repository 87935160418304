import { colKeys } from '@/config/demand/table.config';

export default {
  table({ data, pKnown }) {
    if (!data) {
      return {
        header: [],
        points: [],
        settings: []
      };
    }

    const { rowsInfo, points, nodeFlags, useModelFrom } = data;
    const header = rowsInfo.filter(row => row.available);
    const overrideCount = header?.filter(h => h.class === colKeys.customRowOverrides.headerClass)?.length || 0;

    points.forEach(point => {
      if (!point.OnHand) {
        point.OnHand = {
          val: '',
          editable: point.LostDays?.editable || false
        };
      }

      if (!point.customRowOverrides) {
        point.customRowOverrides = new Array(overrideCount).fill({
          ovValue: '',
          editable: point.p >= pKnown
        });
      }
    });

    return {
      header,
      points,
      properties: { useModelFrom },
      nodeFlags
    };
  }
};
