var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sl-table-wrapper",class:{
    'sl-table-wrapper--no-data': _vm.isNoData,
    'sl-table-wrapper--inner-no-data': _vm.isNoData && _vm.innerNoData,
    'sl-table-wrapper--inner-no-data-editable': _vm.innerNoDataEditable,
    'sl-table-wrapper--before-outer': _vm.$scopedSlots['before-outer']
  },style:({
    height: _vm.tableHeight,
    maxHeight: (_vm.maxHeight + "px")
  })},[_c('SlTableClipboardMenu',{ref:"clipboardMenu",attrs:{"id":(_vm.id + "-context-menu")}}),(_vm.$scopedSlots.loader)?_vm._t("loader"):_c('SlTableLoader',{attrs:{"show":_vm.loading}}),(_vm.showTable)?_c('div',{staticClass:"sl-table",class:{
      'sl-table--pagination': _vm.showPagination,
      'sl-table--list': _vm.list
    },attrs:{"id":_vm.tableId}},[_c('RecycleScroller',{ref:"scrollContainer",staticClass:"sl-table__scroller",class:{
        'sl-table--off-col-resize': !_vm.colResize,
        'sl-table--num-col': _vm.numCol
      },style:({
        maxHeight: (_vm.maxHeight + "px")
      }),attrs:{"id":"sl-table-scroller","items":_vm.tableRows,"key-field":_vm.uniqueKey,"item-size":_vm.rowHeight,"buffer":_vm.rowBuffer},nativeOn:{"&scroll":function($event){return _vm.updateScroll($event)}},scopedSlots:_vm._u([{key:"before",fn:function(){return [_c('SlTableHeader',{attrs:{"scrolled":_vm.hasVerticalScrollbar && !_vm.scrolledToTop}},[_c('SlTableRow',[_c('SlTableSideCol',{attrs:{"scrolled-right":_vm.hasHorizontalScrollbar && !_vm.scrolledToRight,"scrolled-left":_vm.hasHorizontalScrollbar && !_vm.scrolledToLeft,"height":_vm.sideColHeight,"shadow":_vm.numCol || _vm.selectable}},[(_vm.numCol)?_c('SlTableCellHeader',{staticClass:"sl-table__cell--side-col"},[_vm._v(" "+_vm._s('№')+" ")]):_vm._e(),(_vm.selectable)?_c('SlTableCellHeader',{staticClass:"sl-table__cell--side-col"},[(_vm.$scopedSlots['header-checkbox'])?_vm._t("header-checkbox"):_c('SlCheckbox',{attrs:{"value":_vm.headerCheckboxValue,"indeterminate":_vm.isIndeterminate},on:{"change":_vm.toggleCheckboxAll}})],2):_vm._e()],1),_vm._l((_vm.headers),function(header,headerIndex){return [(_vm.isHeaderCellVisible(header))?_c('SlTableCellHeader',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.getTooltip(header[_vm.headerNameKey])),expression:"getTooltip(header[headerNameKey])"}],key:header[_vm.headerUniqueKey],style:(_vm.getColStyle(headerIndex)),attrs:{"sortable":_vm.apiSortable,"data-resize":headerIndex,"data-test-id":(_vm.id + "-tbl-header-" + (header[_vm.headerUniqueKey].toLowerCase())),"resizable":_vm.colResize},nativeOn:{"click":function($event){return _vm.getColumnSortCallback($event, header[_vm.headerUniqueKey])},"contextmenu":function($event){return _vm.handleHeaderContextMenu(
                  $event,
                  {
                    header: header,
                    headerIndex: headerIndex
                  })}}},[_c('div',{staticClass:"sl-table__cell-text"},[(_vm.$scopedSlots[("header-" + (header[_vm.headerUniqueKey]))])?_vm._t(("header-" + (header[_vm.headerUniqueKey])),null,null,{
                      header: header,
                      headerIndex: headerIndex
                    }):[_c('span',[_vm._v(" "+_vm._s(header[_vm.headerNameKey])+" ")]),(_vm.$scopedSlots[("header-" + (header[_vm.headerUniqueKey]) + "-info")])?_vm._t(("header-" + (header[_vm.headerUniqueKey]) + "-info"),null,null,{
                        header: header,
                        headerIndex: headerIndex
                      }):_vm._e()],(_vm.apiSortable && _vm.sortBy === header[_vm.headerUniqueKey])?_c('icon',{staticClass:"size-12 sl-table__cell-sort-icon",class:{
                      'sl-table__cell-sort-icon--desc': _vm.sortOrder === _vm.sortingOrders.DESCENDING
                    },attrs:{"data":require("@icons/arrow-up.svg")}}):_vm._e()],2)]):(_vm.$scopedSlots[("header-" + (header[_vm.headerUniqueKey]) + "-last")])?_c('SlTableSideCol',{key:("header-" + (header[_vm.headerUniqueKey]) + "-last"),attrs:{"scrolled-right":_vm.hasHorizontalScrollbar && !_vm.scrolledToRight,"scrolled-left":_vm.hasHorizontalScrollbar && !_vm.scrolledToLeft,"height":_vm.sideColHeight,"shadow":"","last":""}},[_c('SlTableCellHeader',{staticClass:"sl-table__cell--side-col"})],1):_vm._e()]})],2),(_vm.lowerHeaders)?_c('SlTableRow',[_c('SlTableSideCol',{attrs:{"scrolled-right":_vm.hasHorizontalScrollbar && !_vm.scrolledToRight,"scrolled-left":_vm.hasHorizontalScrollbar && !_vm.scrolledToLeft}},[(_vm.numCol)?_c('SlTableCellHeader',{staticClass:"sl-table__cell--side-col"}):_vm._e(),(_vm.selectable)?_c('SlTableCellHeader',{staticClass:"sl-table__cell--side-col"}):_vm._e()],1),_vm._l((_vm.lowerHeaders),function(header,headerIndex){return [(_vm.isHeaderCellVisible(header))?_c('SlTableCellHeader',{key:header[_vm.headerUniqueKey],style:(_vm.getColStyle(headerIndex)),attrs:{"data-resize":headerIndex}},[(_vm.$scopedSlots[("lower-header-" + (header[_vm.headerUniqueKey]))])?[_vm._t(("lower-header-" + (header[_vm.headerUniqueKey])),null,null,{
                      header: header,
                      headerIndex: headerIndex
                    })]:_c('div',{staticClass:"sl-table__cell-text"},[_vm._v(" "+_vm._s(header[_vm.headerNameKey])+" ")])],2):_vm._e()]})],2):_vm._e()],1)]},proxy:true},{key:"default",fn:function(ref){
                    var row = ref.item;
                    var rowIndex = ref.index;
return [(_vm.isRowExist(row))?_c('div',{key:((_vm.rowsIds[rowIndex]) + "_" + rowIndex),staticClass:"sl-table__row-wrap",class:{
            'sl-table__row-wrap--even': _vm.isEven(rowIndex),
            'sl-table__row-wrap--last': _vm.isLast(rowIndex),
            'sl-table__row-wrap--highlighted': row[_vm.uniqueKey] === _vm.highlightedRowId
          },attrs:{"data-test-id":(_vm.id + "-tbl-row-" + rowIndex)}},[_c('SlTableRow',{attrs:{"is-even":_vm.isEven(rowIndex),"highlight":_vm.highlightRow}},[_c('SlTableSideCol',[(_vm.numCol)?_c('SlTableCell',{staticClass:"sl-table__cell--side-col"},[(_vm.$scopedSlots['row-num'])?_vm._t("row-num",null,null,{
                    row: _vm.rows[_vm.originalIndexes[row[_vm.uniqueKey]]],
                    rowId: row[_vm.uniqueKey],
                    rowIndex: _vm.originalIndexes[row[_vm.uniqueKey]],
                  }):[_vm._v(" "+_vm._s(_vm.getRowNum(rowIndex))+" ")]],2):_vm._e(),(_vm.selectable)?_c('SlTableCell',{staticClass:"sl-table__cell--side-col"},[(_vm.$scopedSlots['row-checkbox'])?_vm._t("row-checkbox",null,null,{
                    row: _vm.rows[_vm.originalIndexes[row[_vm.uniqueKey]]],
                    rowId: row[_vm.uniqueKey],
                    rowIndex: _vm.originalIndexes[row[_vm.uniqueKey]],
                  }):_c('SlCheckbox',{attrs:{"value":_vm.isSelectedRow(row[_vm.uniqueKey])},on:{"change":function (val) { return _vm.toggleCheckbox(val, row[_vm.uniqueKey]); }}})],2):_vm._e()],1),_vm._l((row),function(cellValue,cellKey,cellIndex){return [(_vm.isBodyCellVisible(cellKey))?_c('SlTableCell',{key:cellKey,style:(_vm.getColStyle(cellIndex - 1)),nativeOn:{"contextmenu":function($event){_vm.handleBodyContextMenu(
                  $event,
                  {
                    row: _vm.rows[_vm.originalIndexes[row[_vm.uniqueKey]]],
                    rowId: row[_vm.uniqueKey],
                    rowIndex: _vm.originalIndexes[row[_vm.uniqueKey]],
                    cellValue: cellValue,
                    cellKey: cellKey,
                    cellIndex: cellIndex,
                    text: _vm.getCellValueText(cellValue)
                  })}}},[(_vm.$scopedSlots[cellKey])?_vm._t(cellKey,null,null,{
                    row: _vm.rows[_vm.originalIndexes[row[_vm.uniqueKey]]],
                    rowId: row[_vm.uniqueKey],
                    rowIndex: _vm.originalIndexes[row[_vm.uniqueKey]],
                    cellValue: cellValue,
                    cellKey: cellKey,
                    cellIndex: cellIndex,
                    text: _vm.getCellValueText(cellValue)
                  }):_c('div',{staticClass:"sl-table__cell-text",attrs:{"title":_vm.getCellValueText(cellValue)}},[_vm._v(" "+_vm._s(_vm.getCellValueText(cellValue))+" ")])],2):(_vm.$scopedSlots[(cellKey + "-last")])?_c('SlTableSideCol',{key:(cellKey + "-last"),attrs:{"last":""}},[_c('SlTableCell',{staticClass:"sl-table__cell--side-col"},[_vm._t((cellKey + "-last"),null,null,{
                      row: _vm.rows[_vm.originalIndexes[row[_vm.uniqueKey]]],
                      rowId: row[_vm.uniqueKey],
                      rowIndex: _vm.originalIndexes[row[_vm.uniqueKey]],
                      cellValue: cellValue,
                      cellKey: cellKey,
                      cellIndex: cellIndex,
                      text: _vm.getCellValueText(cellValue)
                    })],2)],1):_vm._e()]})],2)],1):_vm._e()]}}],null,true)})],1):_vm._e(),(_vm.showPagination)?_c('SlTablePagination',{attrs:{"value":_vm.currentPage,"total-count":_vm.totalRowsCount,"selected":_vm.totalSelected,"per-page":_vm.perPage,"disabled":_vm.loading},on:{"input":_vm.handlePageChange,"per-page":_vm.handlePerPageChange}}):_vm._e(),(_vm.isNoData)?_c('div',{staticClass:"sl-table__no-data"},[(_vm.$slots['no-data'])?_vm._t("no-data"):_c('SlNoData')],2):_vm._e(),_vm._t("before-outer")],2)}
var staticRenderFns = []

export { render, staticRenderFns }