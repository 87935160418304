import sharedReportApi from '@/api/reports/shared';
import filterApi from '@/api/filter/filter';
import ColVisibilityResponseAdapter from '@/adapters/response/ColVisibilityResponse.adapter';
import {
  ordersTypesKeyMap,
  tableColsClasses,
  editableColClasses
} from '@/config/report/inventoryReport';
import {
  PAGE_SIZE,
  DEFAULT_INVENTORY_CELL_INDEX,
  MAX_PINNED_COLUMNS
} from '@/config/report';
import { uiTabByRoute } from '@/config/users/uiSettings.config';
import { routeNames } from '@/config/router/router.config';
import { slErrorCodes } from '@/config/api/slErrorCodes.config';
import { uiSettingsKeys } from '@/config/users/uiSettings.config';
import { DEFAULT_FILTER_ID, filterTypesByRouteName } from '@/config/filter';
import { getFilteredTable } from '@/helpers/report/pinnedTable';
import { getRouteName } from '@/helpers/shared/router';
import { getSearchQuery } from '@/helpers/report/inventoryReport';
import { getResizedIndexesFromClasses, getHeaderKeyWithIndex } from '@/helpers/report/shared';

const types = {
  SET_TABLE: 'SET_TABLE',
  SET_FILTERS_LIST: 'SET_FILTERS_LIST',
  SET_ACTIVE_FILTER_ID: 'SET_ACTIVE_FILTER_ID',
  SET_SORT_PARAMS: 'SET_SORT_PARAMS',
  SET_COL_VISIBILITY_ITEMS: 'SET_COL_VISIBILITY_ITEMS',
  SET_COL_SIZES: 'SET_COL_SIZES',
  SET_PINNED_COLUMNS: 'SET_PINNED_COLUMNS',
  ADD_PINNED_COLUMN: 'ADD_PINNED_COLUMN',
  REMOVE_PINNED_COLUMN: 'REMOVE_PINNED_COLUMN',
  SET_IS_REFETCHED: 'SET_IS_REFETCHED',
  SET_IS_LINK: 'SET_IS_LINK',
  SET_QUERY_PARAMS: 'SET_QUERY_PARAMS',
  SET_SELECTED_ROWS: 'SET_SELECTED_ROWS',
  SET_IS_UPDATING: 'SET_IS_UPDATING'
};

export const sharedReportModule = {
  namespaced: true,
  state() {
    return {
      table: null,
      colVisibilityItems: [],
      pinnedColumns: [],
      colSizes: [],
      sortParams: {},
      filters_list: [],
      active_filter_id: DEFAULT_FILTER_ID,
      isRefetched: false,
      isLink: false,
      query: {
        page: 1
      },
      selectedRows: [],
      is_updating: false
    };
  },
  mutations: {
    [types.SET_TABLE](state, value) {
      state.table = value;
    },
    [types.SET_FILTERS_LIST](state, value) {
      state.filters_list = value;
    },
    [types.SET_ACTIVE_FILTER_ID](state, value) {
      state.active_filter_id = value;
    },
    [types.SET_SORT_PARAMS](state, value) {
      state.sortParams = value;
    },
    [types.SET_COL_VISIBILITY_ITEMS](state, value) {
      state.colVisibilityItems = value;
    },
    [types.SET_COL_SIZES](state, value) {
      state.colSizes = value;
    },
    [types.SET_PINNED_COLUMNS](state, value) {
      state.pinnedColumns = value;
    },
    [types.ADD_PINNED_COLUMN](state, value) {
      if (state.pinnedColumns >= MAX_PINNED_COLUMNS) {
        return;
      }

      state.pinnedColumns.push(value);
    },
    [types.REMOVE_PINNED_COLUMN](state, value) {
      const index = state.pinnedColumns.findIndex(col => {
        return col.class === value.class && col.index === value.index;
      });

      if (index !== -1) {
        state.pinnedColumns.splice(index, 1);
      }
    },
    [types.SET_IS_REFETCHED](state, value) {
      state.isRefetched = value;
    },
    [types.SET_IS_LINK](state, value) {
      state.isLink = value;
    },
    [types.SET_QUERY_PARAMS](state, value) {
      Object.assign(state.query, value);
    },
    [types.SET_SELECTED_ROWS](state, value) {
      state.selectedRows = value;
    },
    [types.SET_IS_UPDATING](state, value) {
      state.is_updating = value;
    }
  },
  actions: {
    setIsUpdating({ commit }, value) {
      commit(types.SET_IS_UPDATING, value);
    },
    setIsLink({ commit }, value) {
      commit(types.SET_IS_LINK, value);
    },
    setQueryParams({ commit }, value) {
      commit(types.SET_QUERY_PARAMS, value);
    },
    setSelectedRows({ commit }, value) {
      commit(types.SET_SELECTED_ROWS, value);
    },
    async fetchPage({ state, commit, dispatch, getters }) {
      try {
        if (!getters.reportType()) {
          return;
        }

        const PAGE_NUM = state.query.page || 1;
        const params = {
          type: getters.reportType(),
          id: getters.activeFilterId,
          query: getSearchQuery(state.isLink),
          skip: PAGE_SIZE * (PAGE_NUM - 1),
          top: PAGE_SIZE
        };

        const response = await sharedReportApi.getReport(params);
        const data = response?.data;

        if (!data) {
          return;
        }

        commit(types.SET_TABLE, data);
        commit(types.SET_COL_VISIBILITY_ITEMS, ColVisibilityResponseAdapter(data.colMenuInfo));

        if (data.colMenuInfo?.sortParams?.order && data.colMenuInfo?.sortParams.colIndex !== '-1') {
          commit(types.SET_SORT_PARAMS, {
            order: data.colMenuInfo.sortParams.order,
            globalIndex: data.colMenuInfo.sortParams.colIndex,
            class: getters.headerByIndex[data.colMenuInfo.sortParams.colIndex]._class
          });
        }
      } catch (e) {
        if (e?.code === slErrorCodes.INSUFFICIENT_ACCOUNT_PERMISSIONS) {
          if (!state.isRefetched) {
            commit(types.SET_IS_REFETCHED, true);

            await dispatch('fetchPage');
            commit(types.SET_IS_REFETCHED, false);

            return;
          }
        }

        this.dispatch('user/logout', { e, from: 'fetchPage' });
      }
    },
    async fetchFiltersList({ commit }) {
      try {
        const response = await filterApi.getFiltersList({
          type: filterTypesByRouteName[getRouteName()]
        });

        if (!response?.data) {
          return;
        }

        commit(types.SET_FILTERS_LIST, response.data.tabs);
      } catch (e) {
        this.dispatch('user/logout', { e, from: 'fetchFiltersList' });
      }
    },
    async updateFiltersOrder({ commit }, list) {
      commit(types.SET_FILTERS_LIST, list);

      try {
        const tab = uiTabByRoute[getRouteName()];

        if (!tab) {
          return;
        }

        await sharedReportApi.postFiltersOrder(
          { order: list.map(i => i.id) },
          { tab }
        );
      } catch (e) {
        this.dispatch('user/logout', { e, from: 'setFilterByKey' });
      }
    },
    async duplicateFilter({ getters }) {
      await sharedReportApi.duplicateFilter({
        filterId: getters.activeFilterId,
        type: filterTypesByRouteName[getRouteName()]
      });
    },
    async deleteFilter({ state, dispatch }, filterId) {
      await sharedReportApi.deleteFilter({
        filterId,
        type: filterTypesByRouteName[getRouteName()]
      });

      if (state.active_filter_id === filterId) {
        dispatch('setActiveFilterId', DEFAULT_FILTER_ID);
      }
    },
    async filterBy(_, { filterBy, ...body }) {
      try {
        const response = await sharedReportApi.filterBy(
          {
            type: filterTypesByRouteName[getRouteName()],
            filterBy
          },
          body
        );

        if (!response?.data) {
          return;
        }

        this.dispatch('filter/setFilter', response.data);
      } catch (e) {
        this.dispatch('user/logout', { e, from: 'filterBy' });
      }
    },
    async addToFilter({ getters }, { filterBy, ...body }) {
      try {
        const response = await sharedReportApi.addToFilter(
          {
            filterId: getters.activeFilterId,
            type: filterTypesByRouteName[getRouteName()],
            filterBy
          },
          body
        );

        if (!response?.data) {
          return;
        }

        this.dispatch('filter/setFilter', response.data);
      } catch (e) {
        this.dispatch('user/logout', { e, from: 'addToFilter' });
      }
    },
    setActiveFilterId({ state, commit, dispatch }, id) {
      commit(types.SET_ACTIVE_FILTER_ID, id);

      if (state.query.page !== 1) {
        dispatch('setQueryParams', { page: 1 });
        dispatch('setSelectedRows', []);
      }

      dispatch('fetchPage');
    },
    async setSortingParams({ commit, getters }, {
      sortOrder,
      itemClass,
      index = DEFAULT_INVENTORY_CELL_INDEX
    }) {
      const sortParams = {
        type: getters.reportType(),
        id: getters.tableId,
        sortOrder,
        class: itemClass,
        index
      };

      commit(types.SET_SORT_PARAMS, sortParams);

      await sharedReportApi.postReportsSort(sortParams);
    },
    async updateVisibleCols({ commit, getters }, payload) {
      try {
        await sharedReportApi.postVisibleCols({
          ...payload,
          type: getters.reportType()
        });

        if (!payload.state) {
          commit(types.REMOVE_PINNED_COLUMN, payload);
        }

        await this.dispatch('user/fetchUiSettings');
      } catch (e) {
        this.dispatch('user/logout', { e, from: 'updateVisibleCols' });
      }
    },
    setPinnedColumns({ commit }, value) {
      commit(types.SET_PINNED_COLUMNS, value);
    },
    toggleColumnPin({ state, commit }, { col, unpin }) {
      if (unpin) {
        commit(types.REMOVE_PINNED_COLUMN, col);
      } else {
        commit(types.ADD_PINNED_COLUMN, col);
      }

      this.dispatch('user/changeUiSettings', {
        key: uiSettingsKeys.COLUMN_PIN,
        value: state.pinnedColumns
      });
    },
    handleColResize({ getters }, { index, width }) {
      const resizedHeader = getters.headerByIndex[index];

      if (!resizedHeader) {
        return;
      }

      const headerKey = getHeaderKeyWithIndex(resizedHeader);

      this.dispatch('user/changeUiSettings', {
        key: uiSettingsKeys.COLUMNS_WIDTH,
        value: {
          clazz: headerKey,
          width
        }
      });
    },
    setResizedColumns({ getters, commit }, data) {
      const resizedColumns = getResizedIndexesFromClasses(getters.headerByIndex, data);

      // dirty hack to override resized cols even if it is an empty array
      // src\mixins\colResize\index.js:184 in _applyOptions method
      const colSizes = resizedColumns.length ? resizedColumns : new Array(2);

      commit(types.SET_COL_SIZES, colSizes);
    },
    exportTableToDatabase({ getters }) {
      const params = {
        id: getters.tableId,
        type: getters.reportType()
      };

      return sharedReportApi.exportTableToDatabase(params);
    }
  },
  getters: {
    reportType: () => () => ordersTypesKeyMap[getRouteName()],
    filterByIdMap: (state) => {
      return state.filters_list.reduce((acc, item) => {
        acc[item.id] = item;

        return acc;
      }, {});
    },
    activeFilter: (state, getters) => getters.filterByIdMap[state.active_filter_id],
    sortParams: (state) => state.sortParams,
    tableId: (state) => {
      return state.active_filter_id === DEFAULT_FILTER_ID
        ? DEFAULT_INVENTORY_CELL_INDEX
        : state.active_filter_id;
    },
    tableColInfo: (state, getters) => {
      const priceIndexes = [];
      const editableIndexes = [];
      const numberIndexes = [];
      const dateIndexes = [];
      const dropdownIndexes = {
        [tableColsClasses.SUPPLIER]: -1,
        [tableColsClasses.DISTRIB_CENTER]: -1,
        [tableColsClasses.GROUP]: -1,
        [tableColsClasses.CONTAINER]: -1,
        [tableColsClasses.BOM_GROUP]: -1,
        [tableColsClasses.ITEM_ORDERING_DAYS]: -1
      };
      let onHandIndex = -1;

      state.table?.head.col.forEach((col, index) => {
        if (
          [
            tableColsClasses.PURCHASE_PRICE,
            tableColsClasses.PURCHASE_VALUE,
            tableColsClasses.PURCHASE_PLAN_I_CURR
          ].includes(col._class)
        ) {
          priceIndexes.push(index);
        }

        if (col._type === 'date') {
          dateIndexes.push(index);
        } else if (
          ['int', 'uint', 'double', 'float', 'percent'].includes(col._type)
        ) {
          numberIndexes.push(index);
        }

        if (editableColClasses.includes(col._class)) {
          editableIndexes.push(index);
        }

        if (Object.keys(dropdownIndexes).includes(col._class)) {
          dropdownIndexes[col._class] = index;

          return;
        }

        if (
          (
            col._class === tableColsClasses.LEADTIME
            || col._class === tableColsClasses.LEADTIME_VARIANCE
          ) && getters.reportType() !== ordersTypesKeyMap[routeNames.INTERSTORE]
        ) {
          editableIndexes.push(index);
        }

        if (col._class === tableColsClasses.ONHAND) {
          onHandIndex = index;
        }
      });

      return {
        priceIndexes,
        editableIndexes,
        numberIndexes,
        dateIndexes,
        dropdownIndexes,
        onHandIndex
      };
    },
    itemLocChanTypeIndexes: (state) => {
      return {
        itemIdx: state.table?.head.col.findIndex((h) => h._class === tableColsClasses.ITEMCODE),
        locationIdx: state.table?.head.col.findIndex((h) => h._class === tableColsClasses.LOCATION),
        channelIdx: state.table?.head.col.findIndex((h) => h._class === tableColsClasses.CHANNEL),
        itemTypeIdx: state.table?.head.col.findIndex((h) => h._class === tableColsClasses.BOMTYPE)
      };
    },
    headerByIndex: (state) => {
      return state.table?.head.col || [];
    },
    locationByIndex: (state, getters) => (index) => {
      const { locationIdx } = getters.itemLocChanTypeIndexes;

      return state.table?.body[locationIdx]?.col[index];
    },
    channelByIndex: (state, getters) => (index) => {
      const { channelIdx } = getters.itemLocChanTypeIndexes;

      return state.table?.body[channelIdx]?.col[index];
    },
    itemByIndex: (state, getters) => (index) => {
      const { itemIdx } = getters.itemLocChanTypeIndexes;

      return state.table?.body[itemIdx]?.col[index];
    },
    itemTypeByIndex: (state, getters) => (index) => {
      const { itemTypeIdx } = getters.itemLocChanTypeIndexes;

      return state.table?.body[itemTypeIdx]?.col[index];
    },
    linkPayloadByIndex: (_, getters) => (index) => {
      const itemCode = getters.itemByIndex(index);
      const itemType = getters.itemTypeByIndex(index);
      const location = getters.locationByIndex(index);
      const channel = getters.channelByIndex(index);

      return {
        item: itemCode?._val || '',
        location: location?._val || '',
        channel: channel?._val || '',
        itemType: itemType?._code || null
      };
    },
    tableStatsLabel: (state) => {
      return state.table?.metaInfo || [];
    },
    reportTable: (state) => getFilteredTable(state.pinnedColumns, state.table, false),
    pinnedReportTable: (state) => getFilteredTable(state.pinnedColumns, state.table, true),
    activeFilterId: (state) => typeof state.active_filter_id === 'number'
      ? state.active_filter_id
      : DEFAULT_FILTER_ID
  }
};
