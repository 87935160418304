import timeMachineApi from '@/api/timeMachine';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { preventTabClose } from '@/helpers/shared/webAPI';
import { projectTypes } from '@/config/project';
import { getRouteName } from '@/helpers/shared/router';
import { mainRoutes, routeNames } from '@/config/router/router.config';

const types = {
  SET_STATUS: 'SET_STATUS',
  SET_PROGRESS: 'SET_PROGRESS',
  SET_OVERLAY_VISIBILITY: 'SET_OVERLAY_VISIBILITY'
};

const state = () => ({
  status: {
    running: false,
    active: false,
    wantNoise: false
  },
  progressPercent: 0,
  isOverlayVisible: false
});

const getters = {};

const mutations = {
  [types.SET_STATUS](state, status) {
    Object.assign(state.status, status);
  },
  [types.SET_PROGRESS](state, value) {
    state.progressPercent = value;
  },
  [types.SET_OVERLAY_VISIBILITY](state, value) {
    state.isOverlayVisible = value;
  }
};

const actions = {
  async start({ commit }) {
    try {
      this.dispatch('initialization/setInitializing', true);

      const { operationData } = await this.dispatch('operations/subscribe', {
        subscriber: () => timeMachineApi.start({ wantNoise: false })
      });

      if (!operationData) {
        return this.dispatch('initialization/setInitializing', false);
      }

      commit(types.SET_STATUS, {
        running: true,
        active: true
      });

      const routeName = getRouteName();
      const redirect = mainRoutes.includes(routeName) ? routeName : routeNames.DEMAND;

      this.dispatch('manageProjects/openProject', {
        pid: operationData,
        type: projectTypes.TIME_MACHINE,
        redirect
      });

    } catch (e) {
      Vue.notify({
        type: 'error',
        text: e.message
      });

      this.dispatch('user/logout', { e, from: 'timeMachine/start' });
      this.dispatch('initialization/setInitializing', false);
    }
  },
  async stop({ commit, rootState, rootGetters }) {
    try {
      this.dispatch('initialization/setInitializing', true);

      const originProjectId = rootGetters['manageProjects/currentProjectMeta']?.originId;
      const currentProjectId = rootState.manageProjects.projectId;

      await this.dispatch('manageProjects/handleProjectStop', { id: currentProjectId });

      commit(types.SET_STATUS, { running: false, active: false });

      this.dispatch('manageProjects/openProject', {
        pid: originProjectId,
        redirect: getRouteName()
      });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'timeMachineStop' });
      this.dispatch('initialization/setInitializing', false);
    }
  },
  async getParams({ commit }) {
    try {
      const response = await timeMachineApi.getParams();

      if (!response?.data) {
        return;
      }

      const wantNoise = response.data.parameters.wantNoise;

      commit(types.SET_STATUS, { wantNoise });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'timeMachineGetParams' });
    }
  },
  async travelAhead({ commit }, { days, noise }) {
    commit(types.SET_OVERLAY_VISIBILITY, true);

    preventTabClose(true);

    try {
      await this.dispatch('operations/subscribe', {
        subscriber: () => timeMachineApi.setStep({
          nSimulationDays: days,
          wantNoise: noise || false
        }),
        in_progress: ({ progress }) => {
          commit(types.SET_PROGRESS, Math.round(progress?.current));
        }
      });

      commit(types.SET_PROGRESS, 100);
    } catch (e) {
      commit(types.SET_OVERLAY_VISIBILITY, false);

      const errorMessage = e?.message?.error?._fallback;

      if (errorMessage) {
        Vue.notify({
          type: 'error',
          title: VueI18n.t('Error.TimeMachineError'),
          text: errorMessage,
          duration: -1
        });
      }
    } finally {
      setTimeout(() => {
        commit(types.SET_OVERLAY_VISIBILITY, false);
        commit(types.SET_PROGRESS, 0);
        preventTabClose(false);
      }, 200);
    }
  },
  exportOrdersToCsv() {
    try {
      return timeMachineApi.exportOrdersToCsv();
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'timeMachineExport' });
    }
  },
  setStatus({ commit }, payload) {
    commit(types.SET_STATUS, payload);
  },
  reset({ commit }) {
    commit(types.SET_OVERLAY_VISIBILITY, false);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
