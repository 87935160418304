import tree from './tree';
import table from './table';
import chart from './chart';
import seasonPattern from './seasonPattern';
import promotion from './promotion';
import settings from './settings';
import forecastVersions from './forecastVersions';

const actions = {
  async fetchDemandNode() {
    try {
      await Promise.allSettled([
        this.dispatch('demand/table/fetchTable'),
        this.dispatch('demand/settings/fetchForecastSettings'),
        this.dispatch('demand/chart/fetchChart')
      ]);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchDemandNode' });
    }
  }
};

export default {
  namespaced: true,
  modules: {
    tree,
    table,
    chart,
    seasonPattern,
    promotion,
    settings,
    forecastVersions
  },
  actions
};
