<template>
  <div class="connector-wrap connector-wrap--simple">
    <div class="spire-connector__heading">
      <div class="spire-connector__heading-col">
        <h4 class="heading-4-sb">
          {{ $t('Web.BaseConnectors.Form.DbToImport') }}
        </h4>
        <p class="body-1 grey-70">
          {{ $t('Web.BaseConnectors.Form.DbToImportDescr') }}
        </p>
      </div>
      <SlButton
        v-tooltip="getTooltip($t('Web.Table.Refresh'))"
        variant="secondary"
        color="grey"
        size="sm"
        icon
        @click="handleDatabasesRefresh"
      >
        <icon
          data="@icons/refresh.svg"
          class="fill-off size-16 color-grey-80"
          :class="{
            'spin-reverse': isSpinning
          }"
        />
      </SlButton>
    </div>
    <SlAccordion
      v-for="database in databases"
      :key="database._name"
      :disabled="!databases.length"
    >
      <template #trigger="{ isOpen, toggle }">
        <div class="checkbox-item__trigger">
          <SlCheckbox
            :value="isAllSelected(database)"
            :indeterminate="isIndeterminate(database)"
            @input="(checked) => selectAll({ database, checked })"
          />
          <div
            class="checkbox-item__content"
            @click="toggle"
          >
            <div class="body-1 grey-90">
              {{ database._name }}
            </div>
            <icon
              data="@icons/chevron_down.svg"
              class="fill-off size-20"
              :class="{
                'rotate-180': isOpen
              }"
            />
          </div>
        </div>
      </template>
      <div
        v-for="location in database.location"
        :key="location.name"
        class="checkbox-item"
      >
        <SlCheckbox
          :value="location.checked"
          :label="location.name"
          @input="(checked) => selectItem({
            location,
            checked
          })"
        />
      </div>
    </SlAccordion>
    <Periods
      :type="type"
      :is-new="isNew"
    />
  </div>
</template>

<script>
import Periods from '@/components/Connections/Common/Periods.vue';
import { connectionTypes } from '@/config/connection';
import { connection } from '@/mixins/connection';
import { mapActions } from 'vuex';

export default {
  name: 'SpireRequiredTab',
  components: {
    Periods
  },
  mixins: [connection],
  props: {
    isNew: Boolean
  },
  data() {
    return {
      type: connectionTypes.SPIRE,
      isSpinning: false
    };
  },
  inject: [
    'getTooltip'
  ],
  computed: {
    databases() {
      return this.connectionData.required.databases;
    },
    accountId: {
      get() {
        return this.connectionData.required.id;
      },
      set(value) {
        this.setRequiredValue({
          key: 'id',
          value
        });
      }
    },
    accountKey: {
      get() {
        return this.connectionData.required.key;
      },
      set(value) {
        this.setRequiredValue({
          key: 'key',
          value
        });
      }
    }
  },
  methods: {
    ...mapActions({
      getSpireDatabases: 'spire/getSpireDatabases'
    }),
    async handleDatabasesRefresh() {
      if (this.isSpinning) {
        return;
      }

      try {
        this.isSpinning = true;

        await this.getSpireDatabases();
      } finally {
        this.isSpinning = false;
      }
    },
    isAllSelected(database) {
      return database.location.every(location => location.checked) || this.isIndeterminate(database);
    },
    isIndeterminate(database) {
      const checkedLocations = database.location.filter(location => location.checked);

      return Boolean(checkedLocations.length) && Boolean(checkedLocations.length < database.location.length);
    },
    isSelected({ key, item }) {
      return this[key].some(selectedItem => selectedItem._id === item._id);
    },
    selectItem({ location, checked }) {
      location.checked = checked;
    },
    selectAll({ database, checked }) {
      database.location.forEach(location => {
        location.checked = checked;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.connector-content-block__row--combine {
  padding-left: 12px;
}

.spire-connector__heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

::v-deep {
  .checkbox-item__trigger {
    display: flex;
    align-items: center;
    column-gap: 8px;
    flex: 1;
    height: 100%;

    .checkbox-item__content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 8px;
      width: 100%;
      height: 100%;
    }
  }

  .checkbox-item {
    display: flex;
    align-items: center;
    column-gap: 8px;
    margin-bottom: 12px;
    padding-left: 24px;

    &__label {
      display: list-item;
      list-style-type: disc;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .connector-content-additional {
    margin: 4px 0;
  }
}
</style>
