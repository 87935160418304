import Vue from 'vue';
import i18n from '@/plugins/vue-i18n';
import treeApi from '@/api/tree';
import TreeResponseAdapter from '@/adapters/response/tree/TreeResponse.adapter';
import TreeSearchResponseAdapter from '@/adapters/response/tree/TreeSearchResponse.adapter';
import TreeCategoriesResponseAdapter from '@/adapters/response/tree/TreeCategoriesResponse.adapter';
import { treeFgs } from '@/config/shared/fgs.config';
import { ROOT_NODE_ID, SEARCH_RESULT_PORTION } from '@/config/demand/tree/tree.config';
import { fgsActionTypes } from '@/config/shared/contextMenu.config';
import { routeNames } from '@/config/router/router.config';
import { slErrorCodes } from '@/config/api/slErrorCodes.config';
import {
  getAttentionToggleStatus,
  getCheckedToggleStatus,
  getOpenNodes
} from '@/helpers/demand/tree';
import { getRouteName } from '@/helpers/shared/router';
import { dealWith } from '@/api/helpers/errorRegistry';

const types = {
  SET_TREE: 'SET_TREE',
  APPEND_TREE_CHILD: 'APPEND_TREE_CHILD',
  REMOVE_TREE_CHILD: 'REMOVE_TREE_CHILD',
  ADD_OPEN_NODE: 'ADD_OPEN_NODE',
  SET_OPEN_NODES: 'SET_OPEN_NODES',
  SET_ACTIVE_NODE: 'SET_ACTIVE_NODE',
  SET_ACTIVE_NODE_PATH: 'SET_ACTIVE_NODE_PATH',
  SET_INDEX_SCROLL_TO: 'SET_INDEX_SCROLL_TO',
  SET_METADATA: 'SET_METADATA',
  SET_TREE_SEARCH: 'SET_TREE_SEARCH',
  SET_TREE_SEARCH_RESULTS: 'SET_TREE_SEARCH_RESULTS',
  ADD_TREE_SEARCH_RESULTS: 'ADD_TREE_SEARCH_RESULTS',
  SET_ACTIVE_ITEM_CHILDREN_NODE_IDS: 'SET_ACTIVE_ITEM_CHILDREN_NODE_IDS',
  SET_IS_FETCHING: 'SET_IS_FETCHING',
  SET_IS_IS_LINK_REDIRECT: 'SET_IS_IS_LINK_REDIRECT',
  SET_TREE_ORDERS: 'SET_TREE_ORDERS',
  SET_CATEGORIES_LIST: 'SET_CATEGORIES_LIST',
  SET_CATEGORIES: 'SET_CATEGORIES',
  RESET_STATE: 'RESET_STATE',
  SET_RESET: 'SET_RESET'
};

const initialState = () => ({
  tree: [],
  metadata: {},
  open_nodes: [ROOT_NODE_ID],
  active_node: null,
  active_node_path: [],
  index_scroll_to: null,
  order: null,
  orders: [],
  tree_search: '',
  tree_search_results: [],
  childrenNodeIds: [],
  is_fetching: false,
  is_link_redirect: false,
  categories_list: [],
  categories: [],
  reset: false
});

const state = initialState();

const getters = {
  firstNodeId: state => state.tree[0]?.nodeId ?? ROOT_NODE_ID,
  activeNodeId: state => state.active_node?.nodeId ?? ROOT_NODE_ID,
  activeNodeState: state => state.active_node?.state || '',
  activeNodeMultipleState: state => state.active_node?.multipleState,
  fgsByShift: state => shift => state.active_node?.fgs & shift,
  editableForSomeChildren: (_, getters) => !!getters.fgsByShift(treeFgs.EDITABLE_FOR_SOME_CHILDREN),
  childrenNodeIds: state => state.childrenNodeIds,
  location: state => state.active_node?.uniId?.loc || '',
  channel: state => state.active_node?.uniId?.chan || '',
  itemName: state => state.active_node?.uniId?.item || '',
  orders: state => state.orders,
  treeBreadcrumbs: state => state.active_node_path || [],
  treeActivePathId: state => state.active_node_path[state.active_node_path.length - 1]?.id ?? ROOT_NODE_ID,
  activeNodeThings: state => ({
    type: state.active_node?.uniId?.type || '',
    item: state.active_node?.uniId?.item || '',
    loc: state.active_node?.uniId?.loc || '',
    chan: state.active_node?.uniId?.chan || ''
  }),
  actionsFgs: state => ({
    [fgsActionTypes.ENABLED]: state.active_node?.enabledAcs,
    [fgsActionTypes.VISIBLE]: state.active_node?.visibleAcs
  }),
  categoriesParams: state => () => {
    return state.metadata.categories.reduce((acc, item) => {
      acc[item.type].push(item.value);

      return acc;
    }, {
      item_cats: [],
      loc_cats: [],
      channel_cats: []
    });
  },
  openNodes: state => {
    return state.open_nodes.join(',') || ROOT_NODE_ID;
  },
  filterParameters: (state, getters) => () => ({
    ...getters.categoriesParams(),
    order: state.metadata.order,
    wantAbc: state.metadata.wantAbc,
    wantHideDistortions: state.metadata.wantHideDistortions,
    wantHideInactive: state.metadata.wantHideInactive
  })
};

const mutations = {
  [types.SET_TREE](state, { tree, metadata }) {
    state.tree = Object.freeze(tree.map(node => Object.freeze(node)));
    state.metadata = metadata || state.metadata;
  },
  [types.APPEND_TREE_CHILD](state, { child, index, nodeId }) {
    const tempTree = [...state.tree];

    tempTree.splice(index + 1, 0, ...child);

    state.tree = Object.freeze(tempTree);

    state.open_nodes.push(nodeId);
  },
  [types.REMOVE_TREE_CHILD](state, { index, count, nodeId }) {
    const tempTree = [...state.tree];

    const removedNodeIds = tempTree.splice(index + 1, count).map(node => node.nodeId);

    state.tree = Object.freeze(tempTree);

    state.open_nodes = state.open_nodes.filter(node_id => node_id !== nodeId && !removedNodeIds.includes(node_id));
  },
  [types.ADD_OPEN_NODE](state, value) {
    state.open_nodes.push(value);
  },
  [types.SET_OPEN_NODES](state, value) {
    state.open_nodes = value;
  },
  [types.SET_ACTIVE_NODE](state, value) {
    state.active_node = value;
  },
  [types.SET_ACTIVE_NODE_PATH](state, value) {
    state.active_node_path = value;
  },
  [types.SET_INDEX_SCROLL_TO](state, value) {
    state.index_scroll_to = value;
  },
  [types.SET_METADATA](state, value) {
    Object.assign(state.metadata, value);
  },
  [types.SET_TREE_SEARCH](state, value) {
    state.tree_search = value;
  },
  [types.SET_TREE_SEARCH_RESULTS](state, value) {
    state.tree_search_results = value;
  },
  [types.ADD_TREE_SEARCH_RESULTS](state, value = []) {
    state.tree_search_results.push(...value);
  },
  [types.SET_ACTIVE_ITEM_CHILDREN_NODE_IDS](state, value) {
    state.childrenNodeIds = value;
  },
  [types.SET_IS_FETCHING](state, value) {
    state.is_fetching = value;
  },
  [types.SET_IS_IS_LINK_REDIRECT](state, value) {
    state.is_link_redirect = value;
  },
  [types.SET_TREE_ORDERS](state, value) {
    state.orders = value;
  },
  [types.SET_CATEGORIES_LIST](state, value) {
    state.categories_list = value;
  },
  [types.SET_CATEGORIES](state, value) {
    state.categories = value;
  },
  [types.RESET_STATE](state) {
    const initial = initialState();

    Object.keys(state).forEach(key => {
      state[key] = initial[key];
    });
  },
  [types.SET_RESET](state, value) {
    state.reset = value;
  }
};

const actions = {
  async fetchTree({ state, getters, rootGetters, commit, dispatch }, { resetTree = false, reFetch = false } = {}) {
    try {
      if (state.is_link_redirect && state.tree) {
        return;
      }

      commit(types.SET_IS_FETCHING, true);

      if (rootGetters['note/hasNotes']) {
        this.dispatch('note/resetNodeNotes');
      }

      const openNodes = !resetTree
        ? getters.openNodes ?? getters.treeActivePathId
        : ROOT_NODE_ID;
      const activeNodeId = !resetTree
        ? getters.treeActivePathId ?? ROOT_NODE_ID
        : ROOT_NODE_ID;

      const response = await treeApi.getTree({ openNodes });

      if (!response) {
        return;
      }

      const tree = response.data;

      if (!tree?.nodes && !reFetch) {
        // if nodes empty load root tree (incorrect node id returns empty nodes)
        await dispatch('setActiveNodePath', []);

        return dispatch('fetchTree', { reFetch: true });
      }

      commit(types.SET_TREE, TreeResponseAdapter(tree));

      const existingIndex = tree.nodes.findIndex(node => node.nodeId === activeNodeId);
      const activeIndex = existingIndex !== -1
        ? existingIndex
        : 0;

      await dispatch('setActiveNode', {
        node: tree.nodes[activeIndex],
        index: activeIndex,
        needScroll: true
      });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchTree' });
    } finally {
      commit(types.SET_IS_FETCHING, false);
    }
  },
  async fetchTreeChild({ commit }, nodeId) {
    try {
      commit(types.SET_IS_FETCHING, true);

      const response = await treeApi.getTreeChild({ nodeId });

      if (!response?.data) {
        return;
      }

      return response.data.nodes;
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchTreeChild' });
    } finally {
      commit(types.SET_IS_FETCHING, false);
    }
  },
  async openTreeChild({ commit, dispatch }, { nodeId, index }) {
    const child = await dispatch('fetchTreeChild', nodeId);

    if (child) {
      commit(types.APPEND_TREE_CHILD, {
        child,
        index,
        nodeId
      });
    }
  },
  hideTreeChild({ state, commit }, { index, nodeId }) {
    const parentDepth = state.tree[index].depth;
    const endIndex = state.tree.findIndex((node, nodeIndex) => {
      if (nodeIndex > index) {
        return node.depth <= parentDepth;
      }
    });
    let count;

    if (endIndex === -1) {
      count = state.tree.length - (index + 1);
    } else if (index === endIndex - 1) {
      count = 1;
    } else {
      count = endIndex - (index + 1);
    }

    commit(types.REMOVE_TREE_CHILD, {
      index,
      count,
      nodeId
    });
  },
  updateNode() {
    this.dispatch('demand/table/fetchTable');
    this.dispatch('demand/chart/fetchChart');
    this.dispatch('demand/settings/fetchForecastSettings');
  },
  setActiveNodePath({ commit }, path) {
    commit(types.SET_ACTIVE_NODE_PATH, path);
  },
  async fetchNodePath(_, { nodeId }) {
    try {
      const response = await treeApi.getNodePath({
        nodeId
      });

      if (!response) {
        return;
      }

      return response.data.nodes;
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchNodePath' });
    }
  },
  async updateNodePath({ commit, dispatch }, nodeId) {
    try {
      const path = await dispatch('fetchNodePath', {
        nodeId,
        block: true
      });

      if (!path) {
        return;
      }

      commit(types.SET_ACTIVE_NODE_PATH, path);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'updateNodePath' });
    }
  },
  async setActiveNode({ commit, dispatch, rootGetters }, { node, index, needScroll = false }) {
    if (!node) {
      return;
    }

    if (rootGetters['project/hasBom']) {
      this.dispatch('bom/setSelectedEntry', {
        item: node.uniId.item,
        location: node.uniId.loc,
        route: routeNames.DEMAND
      });
    }

    if (node.nodeId !== ROOT_NODE_ID) {
      await dispatch('updateNodePath', node.nodeId);
    } else {
      commit(types.SET_ACTIVE_NODE_PATH, []);
    }

    commit(types.SET_ACTIVE_NODE, node);
    dispatch('updateNode');

    this.dispatch('demand/chart/resetHiddenChartData');
    this.dispatch('demand/chart/setChartTitle', node?.text);
    dispatch('setChildNodeIds', node.nodeId);

    if (needScroll) {
      return commit(types.SET_INDEX_SCROLL_TO, index);
    }

    commit(types.SET_INDEX_SCROLL_TO, null);
  },
  async setChildNodeIds({ commit, dispatch }, nodeId) {
    const childNodes = await dispatch('fetchTreeChild', nodeId);
    const childNodeIds = childNodes?.map(node => node.nodeId) || [];

    commit(types.SET_ACTIVE_ITEM_CHILDREN_NODE_IDS, childNodeIds);
  },
  setActiveNodeByNodeId({ state, dispatch }, nodeId) {
    const activeNodeIndex = state.tree.findIndex(node => node.nodeId === nodeId);

    dispatch('setActiveNode', {
      node: state.tree[activeNodeIndex],
      index: activeNodeIndex,
      needScroll: true
    });
  },
  setIsLinkRedirect({ commit }, value) {
    commit(types.SET_IS_IS_LINK_REDIRECT, value);
  },
  async openTreeNodeLink({ getters, commit, dispatch }, payload) {
    try {
      await dispatch('fetchTree');

      // to prevent on mount requests (off after tree mounted reqs finished in Tree.vue)
      dispatch('setIsLinkRedirect', true);

      const response = await treeApi.getTreeByLink({
        item: payload.item,
        loc: payload.location,
        chan: payload.channel,
        type: getRouteName() === routeNames.REPORTS ? 'tuple' : 'entry',
        openNodes: getters.openNodes
      });

      const treeData = response.data;

      if (!treeData) {
        return;
      }

      const { activeIndex, tree, metadata } = TreeResponseAdapter(treeData);

      commit(types.SET_TREE, { tree, metadata });
      commit(types.SET_OPEN_NODES, getOpenNodes(tree));

      dispatch('setActiveNode', {
        node: tree[activeIndex],
        index: activeIndex,
        needScroll: true
      });
    } catch (e) {
      dealWith({
        [slErrorCodes.ABSENT_TREE_NODE]: {
          before: () => dispatch('fetchTree'),
          text: () => i18n.tc(
            'Web.Tree.NotifyItemInactiveAndHidden',
            null,
            { 1: payload.item }
          )
        }
      })(e);

      this.dispatch('user/logout', { e, from: 'openTreeNodeLink' });
    }
  },
  async setFilterByKey({ commit, getters }, { needUpdate = true, ...filterData }) {
    commit(types.SET_METADATA, filterData);

    if (!needUpdate) {
      return;
    }

    try {
      commit(types.SET_OPEN_NODES, []);

      await treeApi.changeTreeSettings(getters.filterParameters());
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'setFilterByKey' });
    }
  },
  async updateTree({ commit, dispatch }) {
    dispatch('resetSearch');
    await dispatch('fetchTree', { resetTree: true });

    commit(types.SET_RESET, true);
    Vue.nextTick(() => commit(types.SET_RESET, false));
  },
  setTreeOrders({ commit, getters }, orders) {
    if (getters.orders.length) {
      return;
    }

    commit(types.SET_TREE_ORDERS, orders);
  },
  exportNodeToXlsx({ getters }, visitLeafs) {
    return treeApi.exportNodeToXlsx({
      nodeId: getters.activeNodeId,
      visitLeafs
    });
  },
  async approve({ commit, getters }) {
    try {
      commit(types.SET_IS_FETCHING, true);

      await treeApi.postApprovePrediction({
        nodeId: getters.activeNodeId
      });

    } catch (e) {
      this.dispatch('user/logout', { e, from: 'approve' });
    } finally {
      commit(types.SET_IS_FETCHING, false);
    }
  },
  toggleAttentionItemState({ dispatch, getters }) {
    dispatch('toggleItemState', getAttentionToggleStatus(getters.activeNodeState));
  },
  toggleCheckedItemState({ dispatch, getters }) {
    dispatch('toggleItemState', getCheckedToggleStatus(getters.activeNodeState));
  },
  async toggleItemState({ commit, getters }, value) {
    try {
      commit(types.SET_IS_FETCHING, true);

      await treeApi.postCheckChannel({
        nodeId: getters.activeNodeId,
        state: value
      });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'toggleItemState' });
    } finally {
      commit(types.SET_IS_FETCHING, false);
    }
  },
  async updateSettings({ commit, getters }, data) {
    try {
      commit(types.SET_IS_FETCHING, true);

      await treeApi.postSettings(
        data,
        {
          nodeId: getters.activeNodeId,
          allowForAvailableChildren: getters.editableForSomeChildren
        });

    } catch (e) {
      this.dispatch('user/logout', { e, from: 'updateSettings' });
    } finally {
      commit(types.SET_IS_FETCHING, false);
    }
  },
  setSearchValue({ commit }, value = '') {
    commit(types.SET_TREE_SEARCH, value);
  },
  resetSearch({ commit }) {
    commit(types.SET_TREE_SEARCH, '');
    commit(types.SET_TREE_SEARCH_RESULTS, []);
  },
  async searchTreeNodes({ state, commit }) {
    try {
      if (!state.tree_search) {
        commit(types.SET_TREE_SEARCH_RESULTS, []);

        return;
      }

      const response = await treeApi.searchTreeNodes({
        query: state.tree_search,
        top: SEARCH_RESULT_PORTION,
        skip: 0
      });

      commit(types.SET_TREE_SEARCH_RESULTS, TreeSearchResponseAdapter(response?.data));
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'searchTreeNodes' });
    }
  },
  async loadFoundNodes({ state, commit }, portion) {
    try {
      const response = await treeApi.searchTreeNodes({
        query: state.tree_search,
        top: SEARCH_RESULT_PORTION,
        skip: SEARCH_RESULT_PORTION * portion
      });

      commit(types.ADD_TREE_SEARCH_RESULTS, TreeSearchResponseAdapter(response?.data, true));
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'loadFoundNodes' });
    }
  },
  async fetchFoundNodeTree({ commit, dispatch, getters }, nodeId) {
    try {
      // reset search results after select one
      commit(types.SET_TREE_SEARCH_RESULTS, []);
      commit(types.ADD_OPEN_NODE, nodeId);

      const response = await treeApi.getTree({
        openNodes: getters.openNodes
      });

      const tree = response?.data;

      if (!tree) {
        return;
      }

      const activeIndex = tree.nodes.findIndex(node => node.nodeId === nodeId);

      commit(types.SET_TREE, TreeResponseAdapter(tree));

      dispatch('setActiveNode', {
        node: tree.nodes[activeIndex],
        index: activeIndex,
        needScroll: true
      });

    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchFoundNodeTree' });
    }
  },
  async getTreeCategories({ state, commit }) {
    try {
      const response = await treeApi.getTreeCategories();

      if (response?.data) {
        const categories = TreeCategoriesResponseAdapter(response.data);

        if (!state.categories.length && state.categories_list.length !== categories.length) {
          commit(types.SET_CATEGORIES, categories);
        }

        commit(types.SET_CATEGORIES_LIST, categories);
      }
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'getTreeCategories' });
    }
  },
  resetState({ commit }) {
    commit(types.RESET_STATE);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
