import Vue from 'vue';
import spireResponseAdapter from '@/adapters/response/connection/connectors/spire';
import spireRequestAdapter from '@/adapters/request/connection/connectors/spire';
import spireApi from '@/api/connections/spire';
import { connectionTypes } from '@/config/connection';

const initialState = () => ({});

const state = initialState();

const getters = {};

const mutations = {};

const actions = {
  async getSpireDatabases({ rootState }, { silent } = {}) {
    try {
      const connectionInfo = rootState.connection.connectionData[connectionTypes.SPIRE];
      const body = spireRequestAdapter.databases(connectionInfo);

      const response = await spireApi.getSpireDatabases(body);

      const spireDatabases = response?.data?.spireDatabases;

      if (!spireDatabases) {
        return true;
      }

      this.dispatch('connection/setConnectorValueByKey', {
        field: 'required',
        key: 'databases',
        value: spireResponseAdapter.databases(spireDatabases, connectionInfo.required.databases),
        edit: false
      });

      return true;
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'getSpireDatabases' });

      if (silent) {
        return;
      }

      const message = e?.message;

      if (message) {
        Vue.notify({
          type: 'error',
          text: message,
          duration: 9000
        });
      }

      return false;
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
