<template>
  <div
    class="sl-tab-list"
    :class="{
      'sl-tab-list--horizontal': horizontal,
      'sl-tab-list--collapsed': localCollapsed
    }"
  >
    <div
      v-if="collapsable"
      class="sl-tab-list__header--collapsable"
    >
      <SlButton
        variant="tertiary"
        color="grey"
        class="sl-tab-list__toggle"
        icon
        @click.stop="toggleList"
      >
        <template #prepend>
          <icon
            v-tooltip.bottom="toggleButton.tooltip"
            :data="toggleButton.icon"
            class="size-16 color-grey-70"
          />
        </template>
        <transition name="fade">
          <div
            v-if="!localCollapsed"
            class="sl-tab-list__toggle-text"
          >
            <slot
              v-if="$scopedSlots.collapseText"
              name="collapseText"
            />
            <template v-else>
              {{ $t('Web.Sidebar.TabsSideMenuCollapse') }}
            </template>
          </div>
        </transition>
      </SlButton>
    </div>
    <transition name="fade">
      <div v-if="!localCollapsed">
        <div
          v-if="$slots.header"
          class="sl-tab-list__header body-4-md grey-60"
        >
          <slot name="header" />
        </div>

        <div class="sl-tab-list__tabs">
          <SlTab
            v-for="tab in tabs"
            :key="tab.value"
            :is-link="!!tab.link"
            :to="tab.link && tab.link.to"
            :active="checkIsActive(tab)"
            :disabled="tab.disabled || disabled"
            :required="tab.required"
            :horizontal="horizontal"
            @click.native.stop="handleSelectTab(tab)"
          >
            {{ tab.label }}

            <template
              v-if="tab.restricted"
              #append
            >
              <icon
                data="@icons/upgrade-plan.svg"
                class="fill-off size-16"
              />
            </template>
          </SlTab>
        </div>

        <div
          v-if="$scopedSlots.nestedList"
          class="sl-tab-list__nested-list"
        >
          <slot
            name="nestedList"
            v-bind="{
              collapsed: localCollapsed
            }"
          />
        </div>

        <div
          v-if="$scopedSlots.append"
          class="sl-tab-list__append"
        >
          <slot name="append" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import SlTab from '@/components/UIKit/SlTabs/SlTab';
import { modal } from '@/mixins/modal';
import modalsId from '@/config/shared/modalsId.config';
import { getTooltip } from '@/helpers/shared/tooltip';

export default {
  name: 'SlTabList',
  components: {
    SlTab
  },
  mixins: [modal],
  props: {
    value: {
      type: String,
      default: ''
    },
    tabs: {
      type: Array,
      default: () => ([])
    },
    disabled: Boolean,
    horizontal: Boolean,
    collapsable: Boolean,
    collapsed: Boolean
  },
  data() {
    return {
      isCollapsed: false
    };
  },
  computed: {
    toggleButton() {
      return {
        icon: this.isCollapsed
          ? require('@sidebar/expand.svg')
          : require('@sidebar/collapse.svg'),
        tooltip: this.isCollapsed
          ? getTooltip(this.$t('Web.Sidebar.TabsSideMenuExpand'))
          : ''
      };
    },
    localCollapsed() {
      return this.isCollapsed || this.collapsed;
    }
  },
  mounted() {
    if (!this.value && this.tabs) {
      const firstTab = this.tabs.find(tab => !tab.disabled) || this.tabs[0];

      this.handleSelectTab(firstTab);
    }
  },
  methods: {
    checkIsActive(tab) {
      return this.value === tab.value;
    },
    handleSelectTab(tab) {
      if (tab.restricted) {
        return this.showModal(modalsId.UPGRADE_PLAN);
      }

      this.$emit('input', tab.value);

      if (tab.hash && tab.hash !== this.$route.hash) {
        this.$router.replace({
          name: this.$sl_routeName,
          hash: tab.hash
        });
      }
    },
    toggleList() {
      this.isCollapsed = !this.isCollapsed;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/ui-kit/sl-tabs/sl-tab-list.scss";
</style>
