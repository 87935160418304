import { render, staticRenderFns } from "./SlTabList.vue?vue&type=template&id=514b2493&scoped=true&"
import script from "./SlTabList.vue?vue&type=script&lang=js&"
export * from "./SlTabList.vue?vue&type=script&lang=js&"
import style0 from "./SlTabList.vue?vue&type=style&index=0&id=514b2493&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "514b2493",
  null
  
)

export default component.exports