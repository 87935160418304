import { routeNames } from '@/config/router/router.config';

export const exportTypes = {
  XLSX: 'xlsx',
  CSV: 'csv'
};

export const sortingOrders = {
  ASCENDING: 'Ascending',
  DESCENDING: 'Descending'
};

export const ordersTypesKeyMap = {
  [routeNames.INVENTORY]: 'Inventory',
  [routeNames.INVENTORY_PLANNED_ORDERS]: 'Inventory',
  [routeNames.INTERSTORE]: 'Optimization',
  [routeNames.INTERSTORE_PLANNED_ORDERS]: 'Optimization',
  [routeNames.REPORTS]: 'Report'
};

export const inventoryReportTypesKeyMap = {
  [routeNames.INVENTORY]: ordersTypesKeyMap[routeNames.INVENTORY],
  [routeNames.INTERSTORE]: ordersTypesKeyMap[routeNames.INTERSTORE]
};

export const inTransitionDateTypes = {
  ESTIMATED: 'estimated',
  EXACT: 'exact'
};

export const tableColsClasses = {
  ITEMCODE: 'ITEMCODE',
  LOCATION: 'LOCATION',
  SUPPLIER: 'SUPPLIER',
  CHANNEL: 'CHANNEL',
  BOMTYPE: 'BOMTYPE',
  AUXFIELD_I: 'AUXFIELD_I',
  ORDER_CYCLE: 'ORDER_CYCLE',
  BOXROUND: 'BOXROUND',
  MINBUNDLE: 'MINBUNDLE',
  MAXBUNDLE: 'MAXBUNDLE',
  SHELF_LIFE: 'SHELF_LIFE',
  SAFETY_SHELF: 'SAFETY_SHELF',
  SAFESTOCK: 'SAFESTOCK',
  SERVICE_LEVEL: 'SERVICE_LEVEL',
  SHELF_DISCARD: 'SHELF_DISCARD',
  SOURCE_FROM: 'SOURCE_FROM',
  NPERIODS: 'NPERIODS',
  ONORDER: 'ONORDER',
  ONORDER_DATE: 'ONORDER_DATE',
  ORDER_NOW_OVERRIDE: 'ORDER_NOW_OVERRIDE',
  CONTAINER: 'CONTAINER',
  ONHAND: 'ONHAND',
  DISTRIB_CENTER: 'DISTRIB_CENTER',
  BACKORDER: 'BACKORDER',
  ITEM_SALES_PRICE: 'ITEM_SALES_PRICE',
  PURCHASE_PRICE: 'PURCHASE_PRICE',
  PURCHASE_VALUE: 'PURCHASE_VALUE',
  GROUP: 'GROUP',
  BOM_GROUP: 'BOM_GROUP',
  ITEM_VOLUME: 'ITEM_VOLUME',
  ITEM_WEIGHT: 'ITEM_WEIGHT',
  ITEM_PALLET: 'ITEM_PALLET',
  NOTE: 'NOTE',
  PURCHASE_PLAN_I_CURR: 'PURCHASE_PLAN_I_CURR',
  LEADTIME: 'LEADTIME',
  LEADTIME_VARIANCE: 'LEADTIME_VARIANCE',
  ITEM_ORDERING_DAYS: 'ITEM_ORDERING_DAYS',
  TRANSFER_REGION: 'TRANSFER_REGION',
  SS_DC_PERIODS: 'SS_DC_PERIODS',
  SS_BOM_PERIODS: 'SS_BOM_PERIODS'
};

export const editableColClasses = [
  tableColsClasses.SUPPLIER,
  tableColsClasses.AUXFIELD_I,
  tableColsClasses.ORDER_CYCLE,
  tableColsClasses.BOXROUND,
  tableColsClasses.MINBUNDLE,
  tableColsClasses.MAXBUNDLE,
  tableColsClasses.SHELF_LIFE,
  tableColsClasses.SAFETY_SHELF,
  tableColsClasses.SERVICE_LEVEL,
  tableColsClasses.SHELF_DISCARD,
  tableColsClasses.ITEM_ORDERING_DAYS,
  tableColsClasses.SAFESTOCK,
  tableColsClasses.NPERIODS,
  tableColsClasses.ONORDER_DATE,
  tableColsClasses.ORDER_NOW_OVERRIDE,
  tableColsClasses.CONTAINER,
  tableColsClasses.ONHAND,
  tableColsClasses.DISTRIB_CENTER,
  tableColsClasses.ITEM_SALES_PRICE,
  tableColsClasses.PURCHASE_PRICE,
  tableColsClasses.GROUP,
  tableColsClasses.BOM_GROUP,
  tableColsClasses.ITEM_VOLUME,
  tableColsClasses.ITEM_WEIGHT,
  tableColsClasses.ITEM_PALLET,
  tableColsClasses.NOTE,
  tableColsClasses.TRANSFER_REGION,
  tableColsClasses.SS_DC_PERIODS,
  tableColsClasses.SS_BOM_PERIODS
];
