import forecastReportApi from '@/api/reports/forecastReport';
import { namespaceByRoute } from '@/config/report';
import { getRouteName } from '@/helpers/shared/router';
import { routeNames } from '@/config/router/router.config';

const types = {
  SET_TOOLBAR: 'SET_TOOLBAR'
};

const state = {
  toolbar: null
};

const getters = {};

const mutations = {
  [types.SET_TOOLBAR](state, value) {
    state.toolbar = value;
  }
};

const actions = {
  async fetchToolbar({ commit }) {
    try {
      const response = await forecastReportApi.getToolbar();

      if (!response?.data) {
        return;
      }

      commit(types.SET_TOOLBAR, response.data.components);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchToolbar' });
    }
  },
  async setDataForLink({ dispatch }, { request, sort }) {
    const namespace = namespaceByRoute[routeNames.REPORTS];

    await dispatch('setReportToolbar', request);
    await this.dispatch(`${namespace}/setSortingParams`, sort);
  },
  exportTableToXlsx({ rootGetters }, transposed) {
    const namespace = namespaceByRoute[getRouteName()];

    return forecastReportApi.exportTableToXlsx({
      id: rootGetters[`${namespace}/tableId`],
      transposed
    });
  },
  importChangesFromXlsx({ rootGetters }, body) {
    const namespace = namespaceByRoute[getRouteName()];

    return forecastReportApi.importChanges({
      id: rootGetters[`${namespace}/tableId`]
    }, body);
  },
  async setReportToolbar(_, payload) {
    try {
      await forecastReportApi.postForecastReport(payload);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'setReportToolbar' });
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
