import { routeNames } from '@/config/router/router.config';
import billOfMaterials from '@/router/middleware/subPages/billOfMaterials.middleware';
import backups from '@/router/middleware/backups.middleware';

const projectRoutes = [
  {
    path: ':projectId/removal-substitution-rules',
    name: routeNames.REMOVAL_SUBSTITUTION_RULES,
    component: () => import('../views/SubPages/RemovalSubstitutionRules.vue')
  },
  {
    path: ':projectId/batches-on-hand',
    name: routeNames.BATCHES_ON_HAND,
    component: () => import('../views/SubPages/BatchesOnHand.vue')
  },
  {
    path: ':projectId/settings/',
    name: routeNames.SETTINGS,
    component: () => import('../views/SubPages/Settings.vue')
  },
  {
    path: ':projectId/forecast-versions',
    name: routeNames.FORECAST_VERSIONS,
    component: () => import('../views/SubPages/ForecastVersions.vue')
  },
  {
    path: ':projectId/suppliers',
    name: routeNames.SUPPLIERS,
    component: () => import('../views/SubPages/Suppliers.vue')
  },
  {
    path: ':projectId/bill-of-materials/:type',
    name: routeNames.BILL_OF_MATERIALS,
    component: () => import('../views/SubPages/BillOfMaterials.vue'),
    meta: {
      middleware: [billOfMaterials]
    }
  },
  {
    path: ':originId/version-history',
    name: routeNames.BACKUPS,
    component: () => import('../views/Backups.vue'),
    meta: {
      middleware: [backups]
    }
  },
  {
    path: ':projectId/logs-page',
    name: routeNames.LOGS_PAGE,
    component: () => import('../views/LogsPage.vue')
  },
  {
    path: ':projectId/seasonality-patterns',
    name: routeNames.SEASONALITY_PATTERNS,
    component: () => import('../views/SubPages/SeasonalityPatterns.vue')
  }
];

export default projectRoutes;
