<template>
  <div
    class="sl-context-menu__option"
    :class="{
      'sl-context-menu__option--disabled': disabled
    }"
    @click.stop="clickCallback"
  >
    <div
      v-if="$scopedSlots.icon || option.icon"
      class="sl-context-menu__option-icon"
    >
      <slot
        v-if="$scopedSlots.icon"
        name="icon"
      />
      <icon
        v-else-if="option.icon"
        :data="icon"
        class="size-16 fill-off"
      />
    </div>
    <div class="sl-context-menu__option-text">
      <slot />
    </div>
    <div
      v-if="$scopedSlots.shortcut || option.shortcut"
      class="sl-context-menu__option-shortcut"
    >
      <slot
        v-if="$scopedSlots.shortcut"
        name="shortcut"
      />
      <template v-if="option.shortcut">
        <SlShortcut
          v-for="value in option.shortcut"
          :key="value"
          :value="value"
        />
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SlContextMenuOption',
  props: {
    handler: {
      type: Function,
      required: true
    },
    option: {
      type: Object,
      default: () => ({})
    },
    disabled: Boolean
  },
  computed: {
    icon() {
      return require(`@icons/${this.option.icon}.svg`);
    },
    clickCallback() {
      if (this.disabled) {
        return;
      }

      return this.handler;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/components/ui-kit/sl-context-menu/sl-context-menu-option.scss';
</style>
