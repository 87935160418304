<template>
  <div class="connector-content-additional">
    <UploadModal
      :id="modalsId.UPLOAD_FILE"
      :file-format="format"
      :upload-callback="uploadFile"
    />
    <div
      v-if="title || subtitle"
      class="connector-content-additional__header"
    >
      <div
        v-if="title"
        class="connector-content-additional__title"
      >
        {{ title }}
      </div>
      <div
        v-if="subtitle && (file || existingFile)"
        class="connector-content-additional__subtitle"
      >
        {{ subtitle }}
      </div>
    </div>
    <div class="connector-content-additional__content">
      <div
        v-if="file || existingFile"
        class="connector-content-row"
      >
        <SlDropzonePreviewItem
          :file="dropzoneFile"
          @remove="handleRemoveFile"
        />
      </div>
      <div class="connector-content-row">
        <SlButton
          variant="secondary"
          color="grey"
          @click="showModal(modalsId.UPLOAD_FILE)"
        >
          <template #prepend>
            <icon
              data="@icons/upload_cloud.svg"
              class="fill-off size-16"
            />
          </template>
          {{ buttonLabel }}
        </SlButton>
      </div>
    </div>
  </div>
</template>

<script>
import modalsId from '@/config/shared/modalsId.config';
import UploadModal from '@/components/Modals/UploadModal.vue';
import { modal } from '@/mixins/modal';

export default {
  name: 'UploadFile',
  components: {
    UploadModal
  },
  mixins: [modal],
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    format: {
      type: String,
      required: true
    },
    existingFile: {
      type: [File, Object],
      default: null
    },
    uploadCallback: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      modalsId,
      file: null
    };
  },
  computed: {
    buttonLabel() {
      if (this.file) {
        return this.$t('Web.File.UploadNewFile');
      }

      return this.$t('Web.File.UploadFile');
    },
    dropzoneFile() {
      return this.file || this.existingFile;
    }
  },
  methods: {
    async uploadFile(data) {
      this.file = data;

      await this.uploadCallback(data);
    },
    handleRemoveFile() {
      this.file = null;

      this.$emit('remove');
    }
  }
};
</script>
