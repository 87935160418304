<template>
  <div
    class="sl-breadcrumb"
    :class="{
      'sl-breadcrumb--active': active
    }"
  >
    <div
      v-if="index !== 0"
      class="sl-breadcrumb__divider grey-70"
    >
      <slot
        v-if="$scopedSlots.divider"
        name="divider"
      />
      <template v-else>
        <icon
          data="@icons/chevron_right.svg"
          class="size-16 fill-off"
        />
      </template>
    </div>
    <SlLink
      v-bind="$attrs"
      v-on="$listeners"
    >
      <slot />
    </SlLink>
  </div>
</template>

<script>
export default {
  name: 'SlBreadcrumb',
  props: {
    index: {
      type: Number,
      default: 0
    },
    active: Boolean
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/utils/_mixins.scss";

.sl-breadcrumb {
  display: flex;
  align-items: center;

  overflow: hidden;

  &__divider {
    height: 16px;
    margin: 0 8px;
  }

  .sl-link {
    @include heading-5;
    color: $grey-70;
    text-decoration: underline;

    &:hover, &:active {
      color: $grey-90;
    }
  }

  &.sl-breadcrumb--active {
    .sl-link {
      display: block;

      color: $grey-90;
      @include heading-5-md;
      @include text-ellipsis;
      text-decoration: none;
    }
  }
}
</style>