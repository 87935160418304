import logger from '@/api/logger';

export const copyToClipboard = async(text) => {
  if (navigator.clipboard && window.isSecureContext) {
    await navigator.clipboard.writeText(text);
  } else {
    const textarea = document.createElement('textarea');

    textarea.value = text;
    textarea.style.position = 'absolute';
    textarea.style.left = '-99999999px';

    document.body.prepend(textarea);

    textarea.select();

    try {
      document.execCommand('copy');
    } catch (e) {
      logger.formatAndWriteError({
        e,
        from: 'copyToClipboard'
      });
    } finally {
      textarea.remove();
    }
  }
};

export const readClipboardText = async() => {
  return navigator.clipboard?.readText();
};