import { toArray } from '@/helpers/utils/toArray';

export default function({ availableSlots, slotsIndexes }) {
  return toArray(availableSlots).reduce((acc, { colMeaning }) => {
    const indexes = slotsIndexes[colMeaning];

    if (indexes?.length) {
      acc.push({
        _colMeaning: colMeaning,
        index: indexes
      });
    }

    return acc;
  }, []);
}