import axios from '@/plugins/axios';

export default {
  updateTableData({ body, type }) {
    return axios.put('/inventory_report', body, {
      params: {
        type
      }
    });
  },
  massOverride({ body, type, id, query }) {
    return axios.put('/inventory_report/mass_override', body, {
      params: {
        type,
        id,
        query
      }
    });
  },
  getExplanation(params) {
    return axios.get('/inventory_report/explain', { params });
  },
  getOrderingDays(params) {
    return axios.get('/inventory_report/ordering_days', { params });
  },
  getDcs(params) {
    return axios.get('/project/dcs', { params });
  },
  clearOverrides(data) {
    return axios.post('/inventory_report/clear_overrides', data);
  },
  massClearOverrides(data) {
    return axios.post('/inventory_report/mass_clear_overrides', data);
  },
  exportTableToXlsx(params) {
    return axios.get('/inventory_report/export/table', {
      params,
      responseType: 'blob',
      headers: {
        'Accept': 'application/octet-stream'
      }
    });
  },
  exportParamsToXlsx(params) {
    return axios.get('/inventory_report/parameters', {
      params,
      responseType: 'blob',
      headers: {
        'Accept': 'application/octet-stream'
      }
    });
  },
  importParams(params, body) {
    return axios.post('/inventory_report/parameters', body, {
      params,
      headers: {
        'Content-Type': 'application/octet-stream'
      }
    });
  },
  resetNextOrderDate(body) {
    return axios.post('/inventory_report/reset_next_order_date', body);
  },
  copyQtyToOverride(params, body) {
    return axios.post('inventory_report/copy_qty_to_override', body, { params });
  }
};
