<template>
  <SlInput
    ref="searchInput"
    v-model="vModel"
    type="text"
    :placeholder="$t('Main.SearchStub')"
    v-bind="$attrs"
    @keydown="autoSubmitCallback"
    @keydown.enter.stop="submit"
  >
    <template #prepend>
      <SlLoader
        v-if="loading"
        key="loader"
        size="xxs"
        :text="false"
        class="sl-search-input__prepend"
      />
      <icon
        v-else
        key="icon"
        data="@icons/search.svg"
        class="fill-off size-16 sl-search-input__prepend"
      />
    </template>
    <template
      v-if="vModel"
      #append
    >
      <SlButton
        variant="tertiary"
        color="grey"
        size="2xs"
        icon
        class="sl-search-input__append size-16"
        @click.stop="clearSearch"
      >
        <icon
          data="@icons/close.svg"
          class="fill-off size-16"
        />
      </SlButton>
    </template>
  </SlInput>
</template>

<script>
import { keyCodes } from '@/config/utils/statusCodes.config';
import { customDebounce } from '@/helpers/shared/listeners';

export default {
  name: 'SlSearchInput',
  props: {
    value: {
      type: String,
      default: ''
    },
    autoSubmit: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    vModel: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
    autoSubmitCallback() {
      if (!this.autoSubmit) {
        return () => {};
      }

      return this.autoSubmitHandler;
    }
  },
  methods: {
    submit() {
      this.$emit('submit', this.$refs.searchInput.$refs.input.value);
    },
    autoSubmitHandler: customDebounce(function(e) {
      if (e.keyCode !== keyCodes.enter) {
        this.submit();
      }
    }, 500),
    clearSearch() {
      this.$refs.searchInput.$refs.input.value = '';
      this.vModel = '';
      this.submit();
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep .append-element {
  .sl-search-input__append {
    padding: unset;
  }
}

.sl-search-input__prepend {
  position: absolute;
  top: 50%;
  left: calc(50% + 8px);

  transform: translate(-50%, -50%);
}
</style>
