import Vue from 'vue';
import router from './router/index';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import config from '@/config/global.config';
import store from './store';
import { RecycleScroller, DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller';
import vClickOutside from 'v-click-outside';
import Notifications from 'vue-notification';
import FloatingVue from 'floating-vue';
import VueCookies from 'vue-cookies';
import { Frontegg } from '@frontegg/vue';
import Multiselect from 'vue-multiselect';
import DatePicker from 'vue2-datepicker';
import Draggable from 'vuedraggable';
import SlModal from '@/components/UIKit/SlModal/Index.vue';
import SlModalFooter from '@/components/UIKit/SlModal/SlModalFooter';
import SlButton from '@/components/UIKit/SlButton';
import SlValidate from '@/components/UIKit/SlValidate';
import SlInput from '@/components/UIKit/SlInput';
import SlConfirmModal from '@/components/UIKit/Modals/SlConfirmModal';
import SlRenameModal from '@/components/UIKit/Modals/SlRenameModal';
import SlLoadingModal from '@/components/UIKit/Modals/SlLoadingModal';
import SlErrorModal from '@/components/UIKit/Modals/SlErrorModal';
import SlImportFileModal from '@/components/UIKit/Modals/SlImportFileModal';
import SlPasswordInput from '@/components/UIKit/SlPaswordInput';
import SlSearchInput from '@/components/UIKit/SlSearchInput';
import SlSelect from '@/components/UIKit/SlSelect';
import SlCombobox from '@/components/UIKit/SlCombobox';
import SlCheckbox from '@/components/UIKit/SlCheckbox';
import SlControlInput from '@/components/UIKit/SlControlInput';
import SlDatePicker from '@/components/UIKit/SlDatePicker';
import SlTimePicker from '@/components/UIKit/SlTimePicker';
import SlControl from '@/components/UIKit/SlControl';
import SlHiddenInput from '@/components/UIKit/SlHiddenInput';
import SlInfoButton from '@/components/UIKit/SlInfoButton';
import SlTextArea from '@/components/UIKit/SlTextArea';
import SlInfoItem from '@/components/UIKit/SlInfoItem';
import SlRadioButton from '@/components/UIKit/SlRadioButton';
import SlRadioPanel from '@/components/UIKit/SlRadioPanel';
import SlAccordion from '@/components/UIKit/SlAccordion';
import SlDropzone from '@/components/UIKit/SlDropzone/Index';
import SlDropzonePreviewItem from '@/components/UIKit/SlDropzone/SlDropzonePreviewItem';
import SlOverlay from '@/components/UIKit/SlOverlay';
import SlTabList from '@/components/UIKit/SlTabs/SlTabList';
import SlTabContent from '@/components/UIKit/SlTabs/SlTabContent';
import SlNoData from '@/components/UIKit/SlNoData';
import SlTable from '@/components/UIKit/SlTable/Index';
import SlTableSelect from '@/components/UIKit/SlTable/Inputs/SlTableSelect';
import SlTableInput from '@/components/UIKit/SlTable/Inputs/SlTableInput';
import SlTableDatepicker from '@/components/UIKit/SlTable/Inputs/SlTableDatepicker';
import SlLoader from '@/components/UIKit/SlLoader';
import SlAlert from '@/components/UIKit/SlAlert/SlAlert';
import SlAlertLine from '@/components/UIKit/SlAlert/SlAlertLine';
import SlLinkCard from '@/components/UIKit/SlLinkCard';
import SlProgressLoader from '@/components/UIKit/SlProgressLoader';
import SlLinearLoader from '@/components/UIKit/SlLinearLoader.vue';
import SlBreadcrumb from '@/components/UIKit/SlBreadcrumb';
import SlDropdown from '@/components/UIKit/SlDropdown/Index';
import SlDropdownOption from '@/components/UIKit/SlDropdown/SlDropdownOption';
import SlTreeSearchOption from '@/components/UIKit/SlDropdown/SlTreeSearchOption';
import SlNoResultsOption from '@/components/UIKit/SlDropdown/SlNoResultsOption';
import SlUploadLink from '@/components/UIKit/SlUploadLink';
import SlOptionalInput from '@/components/UIKit/SlOptionalInput';
import SlBadge from '@/components/UIKit/SlBadge';
import SlTag from '@/components/UIKit/SlTag';
import SlContextMenu from '@/components/UIKit/SlContextMenu/Index.vue';
import SlContextMenuOption from '@/components/UIKit/SlContextMenu/SlContextMenuOption.vue';
import SlShortcut from '@/components/UIKit/SlShortcut.vue';
import SlSelectionInfo from '@/components/UIKit/SlSelectionInfo.vue';
import UploadFile from '@/components/Shared/UploadFile.vue';
import SubPageWrapper from '@/components/Shared/Page/SubPageWrapper';
import SystemPageWrapper from '@/components/Shared/Page/SystemPageWrapper';
import ContentBlock from '@/components/Shared/Page/ContentBlock';
import TransitionExpand from '@/components/Shared/TransitionExpand';
import SlSwiperContainer from '@/components/UIKit/SlSwiper/SlSwiperContainer';
import SlSwiperSlide from '@/components/UIKit/SlSwiper/SlSwiperSlide';
import SlLink from '@/components/UIKit/SlLink';
import SlNodePath from '@/components/UIKit/SlNodePath';
import SlErrorBoundary from '@/components/UIKit/SlErrorBoundary';
import SlInfoSection from '@/components/UIKit/SlInfoSection.vue';
import SlColorPicker from '@/components/UIKit/SlColorPicker/Index.vue';
import SlSkeletonLoader from '@/components/UIKit/SlSkeletonLoader.vue';
import { ResizeObserve } from '@/directives/resizeObserve.directive';
import { SelectOverflow } from '@/directives/selectOverflow.directive';
import { UndoRedo } from '@/directives/undoRedo.directive';
import i18n from './plugins/vue-i18n';
import logger from '@/api/logger';

import 'swiper/swiper-element-bundle.min.js';

import { fronteggMixin } from '@/mixins/frontegg';
import { routeMixin } from '@/mixins/route';

import './style/main.scss';

import './plugins/vue-svgicon.plugin';
import './plugins/vee-validate.plugin';
import './plugins/highlightjs.plugin';
import { VueMaskDirective } from 'v-mask';
import { themeOptions } from '@/config/plugins/frontegg.config';

Vue.component('RecycleScroller', RecycleScroller);
Vue.component('DynamicScroller', DynamicScroller);
Vue.component('DynamicScrollerItem', DynamicScrollerItem);
Vue.component('Multiselect', Multiselect);
Vue.component('DatePicker', DatePicker);
Vue.component('UploadFile', UploadFile);
Vue.component('Draggable', Draggable);

Vue.component('SlButton', SlButton);
Vue.component('SlModal', SlModal);
Vue.component('SlModalFooter', SlModalFooter);
Vue.component('SlValidate', SlValidate);
Vue.component('SlInput', SlInput);
Vue.component('SlConfirmModal', SlConfirmModal);
Vue.component('SlRenameModal', SlRenameModal);
Vue.component('SlLoadingModal', SlLoadingModal);
Vue.component('SlErrorModal', SlErrorModal);
Vue.component('SlImportFileModal', SlImportFileModal);
Vue.component('SlPasswordInput', SlPasswordInput);
Vue.component('SlSearchInput', SlSearchInput);
Vue.component('SlSelect', SlSelect);
Vue.component('SlCombobox', SlCombobox);
Vue.component('SlCheckbox', SlCheckbox);
Vue.component('SlControlInput', SlControlInput);
Vue.component('SlDatePicker', SlDatePicker);
Vue.component('SlTimePicker', SlTimePicker);
Vue.component('SlControl', SlControl);
Vue.component('SlHiddenInput', SlHiddenInput);
Vue.component('SlInfoButton', SlInfoButton);
Vue.component('SlTextArea', SlTextArea);
Vue.component('SlInfoItem', SlInfoItem);
Vue.component('SlRadioButton', SlRadioButton);
Vue.component('SlRadioPanel', SlRadioPanel);
Vue.component('SlAccordion', SlAccordion);
Vue.component('SlDropzone', SlDropzone);
Vue.component('SlDropzonePreviewItem', SlDropzonePreviewItem);
Vue.component('SlOverlay', SlOverlay);
Vue.component('SlTabList', SlTabList);
Vue.component('SlTabContent', SlTabContent);
Vue.component('SlNoData', SlNoData);
Vue.component('SlTable', SlTable);
Vue.component('SlTableSelect', SlTableSelect);
Vue.component('SlTableInput', SlTableInput);
Vue.component('SlTableDatepicker', SlTableDatepicker);
Vue.component('SlLoader', SlLoader);
Vue.component('SlAlert', SlAlert);
Vue.component('SlAlertLine', SlAlertLine);
Vue.component('SlLinkCard', SlLinkCard);
Vue.component('SlProgressLoader', SlProgressLoader);
Vue.component('SlLinearLoader', SlLinearLoader);
Vue.component('SlBreadcrumb', SlBreadcrumb);
Vue.component('SlDropdown', SlDropdown);
Vue.component('SlDropdownOption', SlDropdownOption);
Vue.component('SlTreeSearchOption', SlTreeSearchOption);
Vue.component('SlNoResultsOption', SlNoResultsOption);
Vue.component('SlUploadLink', SlUploadLink);
Vue.component('SlOptionalInput', SlOptionalInput);
Vue.component('SlBadge', SlBadge);
Vue.component('SlTag', SlTag);
Vue.component('SlContextMenu', SlContextMenu);
Vue.component('SlContextMenuOption', SlContextMenuOption);
Vue.component('SlShortcut', SlShortcut);
Vue.component('SlSelectionInfo', SlSelectionInfo);
Vue.component('SubPageWrapper', SubPageWrapper);
Vue.component('SystemPageWrapper', SystemPageWrapper);
Vue.component('ContentBlock', ContentBlock);
Vue.component('SlSwiperContainer', SlSwiperContainer);
Vue.component('SlSwiperSlide', SlSwiperSlide);
Vue.component('TransitionExpand', TransitionExpand);
Vue.component('SlLink', SlLink);
Vue.component('SlNodePath', SlNodePath);
Vue.component('SlErrorBoundary', SlErrorBoundary);
Vue.component('SlInfoSection', SlInfoSection);
Vue.component('SlColorPicker', SlColorPicker);
Vue.component('SlSkeletonLoader', SlSkeletonLoader);

Vue.directive('resize-observe', ResizeObserve);
Vue.directive('select-overflow', SelectOverflow);
Vue.directive('undo-redo', UndoRedo);
Vue.directive('mask', VueMaskDirective);

Vue.use(FloatingVue, {
  themes: {
    tooltip: {
      triggers: ['hover', 'touch']
    },
    dark: {
      $extend: 'tooltip',
      triggers: ['hover', 'touch', 'focus']
    },
    error: {
      $extend: 'tooltip',
      triggers: ['hover', 'touch', 'focus']
    }
  }
});

Vue.use(Notifications, {
  duration: 10000
});
Vue.use(vClickOutside);
Vue.use(VueCookies);

const fronteggEnv = Vue.$cookies.get('env') === config.APP_MODES.PROD
  ? config.APP_MODES.PROD
  : config.APP_MODES.DEV;
const fronteggConfig = config.FRONTEGG_CONFIG_BY_ENV[fronteggEnv];

const tenantResolver = () => {
  const host = window.location.host;
  const matchedHost = fronteggConfig.aliases?.
    find(alias => host.includes(alias.includesInHost));

  return {
    tenant: matchedHost?.alias || ''
  };
};

Vue.use(Frontegg, {
  contextOptions: {
    baseUrl: fronteggConfig.URL,
    clientId: fronteggConfig.ID,
    tenantResolver
  },
  themeOptions,
  hostedLoginBox: true,
  router
});

Vue.mixin(fronteggMixin);
Vue.mixin(routeMixin);

Vue.config.productionTip = false;

Vue.config.errorHandler = (e, _, info) => {
  console.error(e);

  logger.formatAndWriteError({
    e,
    info
  });
};

new Vue({
  router,
  vuetify,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app');
