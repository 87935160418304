<template>
  <SlModal
    :id="id"
    :width="720"
    :title="$t('Web.Project.ChooseProjectType')"
    @hide="onModalHide"
  >
    <div class="modal-content create-project">
      <SlRadioPanel
        v-model="type"
        :selected="projectCreationTypes.SINGLE"
        :icon="false"
        @radio-click="handleNext"
      >
        <div class="create-project__card">
          <icon
            data="@icons/illustration/single-data-sources.svg"
            class="fill-off create-project__illustration"
          />
          <div class="create-project__card-title">
            {{ $t('Web.Project.SingleDatasource') }}
          </div>
          <div class="create-project__card-description">
            {{ $t('Web.Project.SingleDatasourceDescr') }}
          </div>
        </div>
      </SlRadioPanel>
      <SlRadioPanel
        v-model="type"
        :selected="projectCreationTypes.MULTIPLE"
        :icon="false"
        @radio-click="handleNext"
      >
        <div class="create-project__card">
          <icon
            data="@icons/illustration/multiple-data-sources.svg"
            class="fill-off create-project__illustration"
          />
          <div class="create-project__card-title">
            {{ $t('Web.Project.MultipleDatasource') }}
          </div>
          <div class="create-project__card-description">
            {{ $t('Web.Project.MultipleDatasourceDescr') }}
          </div>
        </div>
      </SlRadioPanel>
    </div>
  </SlModal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { modal } from '@/mixins/modal';
import modalsId from '@/config/shared/modalsId.config';
import { routeNames } from '@/config/router/router.config';
import { projectCreationTypes } from '@/config/project';

export default {
  name: 'SlRenameModal',
  mixins: [modal],
  data() {
    return {
      projectCreationTypes,
      id: modalsId.CREATE_PROJECT,
      type: null
    };
  },
  computed: {
    ...mapGetters({
      existingNames: 'manageProjects/existingNames'
    })
  },
  methods: {
    ...mapActions({
      createProject: 'manageProjects/createProject'
    }),
    onModalHide() {
      this.type = null;
    },
    createSingle() {
      this.$router.push({
        name: routeNames.CREATE_PROJECT
      });
    },
    createMultiple() {
      this.showModal(modalsId.SL_RENAME_MODAL, {
        text: '',
        title: this.$t('Web.Project.ProjectName'),
        validationRules: {
          'sl_not_includes': {
            values: this.existingNames,
            message: this.$t('Web.Error.AlreadyExist', {
              1: this.$t('Web.Error.Names.Project')
            })
          }
        },
        submitText: this.$t('Web.Modals.BtnCreate'),
        placeholder: this.$t('Web.Project.PlaceholderProjectName'),
        saveCallback: this.createProject
      });
    },
    handleNext() {
      if (this.type === projectCreationTypes.SINGLE) {
        this.createSingle();
      } else {
        this.createMultiple();
      }

      this.hideModal(this.id);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/style/components/modals/create-project-modal";
</style>
