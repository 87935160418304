import Vue from 'vue';
import operationsApi from '@/api/operations';
import vueI18n from '@/plugins/vue-i18n';
import { activeOperationStatuses, operationStatuses, operationTypes } from '@/config/api/operations.config';
import { toArray } from '@/helpers/utils/toArray';
import { fetchUpdatedData } from '@/helpers/lastChange/fetchUpdatedData';

const PING_INTERVAL = 500;

const types = {
  SET_OPERATION: 'SET_OPERATION'
};

const state = () => ({
  operations: {}
});

const mutations = {
  [types.SET_OPERATION](state, { id, value }) {
    Vue.set(state.operations, id, value);
  }
};

const actions = {
  async initOperations({ dispatch }) {
    dispatch('updateOperations', operationTypes.GLOBAL);
    dispatch('updateOperations', operationTypes.PROJECT);

  },
  async updateOperations({ commit }, type) {
    try {
      const apiCall = type === operationTypes.GLOBAL
        ? operationsApi.getOperations
        : operationsApi.getProjectOperations;
      const response = await apiCall();

      if (!response) {
        return;
      }

      const operations = toArray(response?.data?.operations);

      operations.forEach(operation => {
        if (activeOperationStatuses.includes(operation.state)) {
          commit(types.SET_OPERATION, {
            id: operation.id,
            value: {
              interval: null,
              data: null
            }
          });
        }
      });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'operations/updateOperations' });
    }
  },
  async subscribe({ dispatch }, { subscriber, ignoreLastChanges, ...callbacks }) {
    this.dispatch('lastChanges/setUpdateStatus', false);

    const response = await subscriber();
    const data = response?.data;
    const id = data?.operationId;

    if (!data?.successful) {
      throw new Error('Can\'t subscribe to operation');
    }

    return dispatch('waitOperation', {
      id,
      data,
      callbacks,
      ignoreLastChanges
    });
  },
  async waitOperation({ commit, dispatch }, { id, data, callbacks, ignoreLastChanges }) {
    const pingCallback = (resolve, reject) => dispatch('ping', {
      id,
      ignoreLastChanges,
      callbacks,
      resolve,
      reject
    });

    return new Promise((resolve, reject) => {
      commit(types.SET_OPERATION, {
        id,
        value: {
          interval: setInterval(() => pingCallback(resolve, reject), PING_INTERVAL),
          data
        }
      });

      // initial ping before interval
      pingCallback(resolve, reject);
    });
  },
  async ping({ state, commit, dispatch }, { id, ignoreLastChanges, callbacks, resolve, reject } = {}) {
    try {
      const response = await operationsApi.getOperationData({ id });
      const data = response?.data;

      if (!data) {
        return;
      }

      const { data: operationData, progress, status, type } = data;
      const result = {
        operationData: operationData ?? {},
        progress: progress ?? null,
        type,
        subscriberData: state.operations[id].data ?? {}
      };

      if (status === operationStatuses.PAUSED && state.operations[id].status === operationStatuses.PAUSED) {
        return;
      }

      callbacks[status] && callbacks[status](result);

      if (status === operationStatuses.FINISHED) {
        resolve && resolve(result);

        await dispatch('processLastChanges', {
          id,
          ignoreLastChanges,
          operationData,
          callbacks
        });
      }

      if (status === operationStatuses.FAILED) {
        reject && reject(operationData?.error);
      }

      if (!activeOperationStatuses.includes(status)) {
        dispatch('unsubscribe', id);
      }

      commit(types.SET_OPERATION, {
        id,
        value: {
          ...state.operations[id],
          status
        }
      });
    } catch (e) {
      Vue.notify({
        type: 'error',
        text: vueI18n.t('Web.Error.SomethingGoingWrong')
      });

      dispatch('unsubscribe', id);

      this.dispatch('user/logout', { e, from: 'operations/ping' });
    }
  },
  async processLastChanges({ state, rootState }, { id, ignoreLastChanges, operationData, callbacks }) {
    const getActiveProjectId = () => rootState.manageProjects.projectId;

    const { pid } = state.operations[id].data || {};

    // update project id before last changes
    if (pid && pid !== getActiveProjectId()) {
      await this.dispatch('manageProjects/resetStores');
      await this.dispatch('manageProjects/pingProject', { pid });
    }

    if (!getActiveProjectId()) {
      return;
    }

    const requestsToUpdate = toArray(operationData?.uiFlags);

    if (requestsToUpdate.length) {
      !ignoreLastChanges && fetchUpdatedData(requestsToUpdate);

      callbacks?.ui_flags && callbacks.ui_flags({
        flags: requestsToUpdate,
        operationId: id
      });
    }
  },
  cancel(_, { id }) {
    return operationsApi.cancelOperation({ id });
  },
  unsubscribe({ state, commit }, id) {
    clearInterval(state.operations[id]?.interval);

    commit(types.SET_OPERATION, {
      id,
      value: null
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
