<template>
  <SlModal
    :id="id"
    :title="$t('Web.InTransition.ImportFile')"
    :loading="isUploading"
    @created="onCreated"
    @on-enter="handleSave"
    @hidden="onModalHide"
  >
    <ValidationObserver
      ref="uploadObserver"
      class="modal-content"
    >
      <SlValidate
        v-slot="{ invalid, validate }"
        vid="file"
        :rules="uploadConfigByFormat[fileFormat].validate"
      >
        <SlDropzone
          ref="dropzone"
          :accepted="uploadConfigByFormat[fileFormat].accepted"
          :validator="validate"
          :is-invalid="invalid"
          @files-updated="handleFilesUpdated"
        >
          <template #accepted>
            {{ $t(uploadConfigByFormat[fileFormat].label) }}
          </template>
        </SlDropzone>
      </SlValidate>
    </ValidationObserver>

    <template #footer>
      <SlModalFooter between>
        <div class="sl-modal__footer-group">
          <SlButton
            variant="secondary"
            color="grey"
            @click="handleCancel"
          >
            {{ $t('Common.Cancel') }}
          </SlButton>
          <SlButton @click="handleSave">
            {{ $t('Common.Save') }}
          </SlButton>
        </div>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { modal } from '@/mixins/modal';
import { fileFormats, uploadConfigByFormat } from '@/config/utils/fileUpload.config';
import modalsId from '@/config/shared/modalsId.config';

export default {
  name: 'ImportFileModal',
  mixins: [modal],
  data() {
    return {
      id: modalsId.SL_IMPORT_FILE,
      uploadConfigByFormat,
      fileFormat: fileFormats.xlsx,
      newFile: null,
      isUploading: false,
      errorsHandler: null,
      saveHandler: null
    };
  },
  methods: {
    onModalHide() {
      this.newFile = false;
      this.isUploading = false;
      this.errorsHandler = null;
    },
    handleCancel() {
      this.hideModal(this.id);
    },
    async handleSave() {
      const valid = await this.$refs.uploadObserver.validate();
      const dropzoneValid = await this.$refs.dropzone.validate();

      if (!valid || !dropzoneValid) {
        return;
      }

      try {
        this.isUploading = true;

        await this.saveHandler(this.newFile);

        this.hideModal(this.id);
      } catch (e) {
        if (this.errorsHandler) {
          return this.errorsHandler(e);
        }

        const text = e?.message;

        this.$notify({
          type: 'error',
          title: this.$t('Error.HeadImport'),
          text
        });
      } finally {
        this.isUploading = false;
      }
    },
    async handleFilesUpdated(files) {
      this.newFile = files[0];
    }
  }
};
</script>

<style lang="scss" scoped>
.sl-modal .modal-content {
  min-height: 100px;
}
</style>
