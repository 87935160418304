<template>
  <div class="connector-wrap connector-wrap--simple">
    <div class="connector-content-block__row">
      <SlValidate
        v-slot="{ invalid }"
        vid="username"
        mode="eager"
        rules="required"
      >
        <SlInput
          v-model="login"
          label="API username"
          :is-invalid="invalid"
        />
      </SlValidate>
    </div>

    <div class="connector-content-block__row">
      <SlValidate
        v-slot="{ invalid }"
        key="key"
        vid="key"
        rules="required"
      >
        <SlPasswordInput
          v-model="password"
          label="API key"
          :is-invalid="invalid"
        />
      </SlValidate>
    </div>

    <Periods
      :type="type"
      :is-new="isNew"
    />
  </div>
</template>

<script>
import { connectionTypes } from '@/config/connection';
import { connection } from '@/mixins/connection';
import Periods from '@/components/Connections/Common/Periods.vue';
import SlValidate from '@/components/UIKit/SlValidate';

export default {
  name: 'Cin7RequiredTab',
  components: {
    Periods,
    SlValidate
  },
  mixins: [connection],
  props: {
    isNew: Boolean
  },
  data() {
    return {
      type: connectionTypes.CIN7
    };
  },
  computed: {
    login: {
      get() {
        return this.connectionData.required.login;
      },
      set(value) {
        this.setRequiredValue({
          key: 'login',
          value
        });
      }
    },
    password: {
      get() {
        return this.connectionData.required.password;
      },
      set(value) {
        this.setRequiredValue({
          key: 'password',
          value
        });
      }
    }
  }
};
</script>
