import Vue from 'vue';
import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import amplitudeConfig from '@/config/plugins/amplitude.config';

function getAuthState() {
  return Vue.mapAuthState()?.authState;
}

export function initAmplitude() {
  amplitude.init(amplitudeConfig.API_KEY, undefined, {
    serverUrl: amplitudeConfig.SERVER_URL,
    autocapture: {
      elementInteractions: true
    },
    defaultTracking: {
      fileDownloads: false
    }
  });

  amplitude.add(sessionReplayPlugin());

  const authState = getAuthState();
  const { email, tenantId } = authState.user;
  const { tenantsState } = authState;

  $amp_setUserId(email);

  const tenantName = tenantsState.tenants.find(t => t.tenantId === tenantId).name;
  const tenantNames = tenantsState.tenants.map(t => t.name);

  $amp_identifyGroup('Tenant', tenantId, {
    tenant_name: tenantName,
    user_tenant_names: tenantNames
  });
}

async function performAmplitudeRequest(f, args) {
  if (process.env.VUE_APP_MODE !== 'production') {
    return () => {};
  }

  let authState = getAuthState();

  if (!authState) {
    return () => {};
  }

  let { user, tenantsState } = authState;

  while (!user || !tenantsState?.tenants?.length) {
    await new Promise(res => setTimeout(() => {
      authState = getAuthState();

      user = authState.user;
      tenantsState = authState.tenantsState;

      res();
    }, 100));
  }

  const tenantId = user.tenantId;
  const userTenant = tenantsState.tenants.find(t => t.tenantId === tenantId);
  const tenantMetadata = JSON.parse(userTenant.metadata);

  if (tenantMetadata.pipeline !== amplitudeConfig.PIPELINE) {
    return () => {};
  }

  return f(...args);
}

const setUserId = (userId) => {
  amplitude.setUserId(userId);
};

const logEvent = (type, props = {}) => {
  const eventProps = {
    type: 'primary',
    ...props
  };

  amplitude.logEvent(type, eventProps);
};

const identifyGroup = (type, name, props = {}) => {
  amplitude.setGroup(type, name);

  const groupIdentifyEvent = new amplitude.Identify();

  Object.entries(props).forEach(([key, value]) => {
    groupIdentifyEvent.set(key, value);
  });

  amplitude.groupIdentify(type, name, groupIdentifyEvent);
};

export const $amp_initAmplitude = (...args) => performAmplitudeRequest(initAmplitude, args);
export const $amp_setUserId = (...args) => performAmplitudeRequest(setUserId, args);
export const $amp_logEvent = (...args) => performAmplitudeRequest(logEvent, args);
export const $amp_identifyGroup = (...args) => performAmplitudeRequest(identifyGroup, args);
