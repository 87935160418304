import cloneObject from '@/helpers/utils/cloneObject';

export default function(data) {
  if (!data) {
    return null;
  }

  const initial = cloneObject(data.subLists) || [];
  const disabledColumns = ['ITEMCODE', 'LOCATION', 'CHANNEL'];

  return data.cols?.reduce((acc, col) => {
    // kostyl for itemcode link (will be refactor)
    if (disabledColumns.includes(col._class || col.class)) {
      col.disabled = true;
    }

    if (col._colMenuSection) {
      const itemIndex = acc.findIndex(item => item._val === col._colMenuSection);

      if (itemIndex !== -1) {
        if (acc[itemIndex].sublist) {
          acc[itemIndex].sublist.push(col);
        } else {
          acc[itemIndex].sublist = [col];
        }
      }

      return acc;
    }

    acc.push(col);

    return acc;
  }, initial);
}