import Vue from 'vue';
import i18n from '@/plugins/vue-i18n';
import userApi from '@/api/user';
import GenericTableResponseAdapter from '@/adapters/response/GenericTable.adapter';
import UpdateRoleRequestAdapter from '@/adapters/request/users/UpdateRoleRequest.adapter';
import RolesResponseAdapter from '@/adapters/response/user/RolesResponse.adapter';
import statusCodes from '@/config/utils/statusCodes.config';
import { accessStateKeys } from '@/config/shared/access.config';
import { slCmdErrorCodes } from '@/config/api/slErrorCodes.config';

const types = {
  SET_USERS: 'SET_USERS',
  SET_OLD_USERS: 'SET_OLD_USERS',
  SET_ROLES: 'SET_ROLES',
  SET_PERMISSIONS: 'SET_PERMISSIONS',
  SET_CATALOGS: 'SET_CATALOGS',
  REMOVE_CATALOGS: 'REMOVE_CATALOGS',
  APPEND_CATALOGS: 'APPEND_CATALOGS',
  ADD_OPENED_CATALOG_NODES: 'ADD_OPENED_CATALOG_NODES',
  REMOVE_OPENED_CATALOG_NODES: 'REMOVE_OPENED_CATALOG_NODES',
  CLEAR_OPENED_CATALOG_NODES: 'CLEAR_OPENED_CATALOG_NODES',
  SET_CATALOG_ID: 'SET_CATALOG_ID',
  SET_EDITED_PERMISSIONS: 'SET_EDITED_PERMISSIONS',
  SET_IS_FETCHING: 'SET_IS_FETCHING',
  SET_IS_FETCHING_PART: 'SET_IS_FETCHING_PART'
};

const state = () => ({
  users: null,
  old_users: null,
  roles: null,
  permissions: null,
  catalogs: null,
  catalog_id: null,
  is_fetching: false,
  is_fetching_part: false,
  openCatalogNodes: []
});

const getters = {
  rolesItems: (state) => {
    if (state.roles) {
      return state.roles?.rows?.map(role => ({
        text: role?.Name?.val,
        value: role?.id
      })) ?? [];
    }

    return [];
  },
  permissionsItems: (state) => {
    if (state.permissions) {
      return state.permissions?.rows?.map(permission => ({
        text: permission?.Name?.val,
        value: permission?.id
      })) ?? [];
    }

    return [];
  },
  takenNamesByKey: (state) => (key, prepFunc = _ => _?.Name?.val) => {
    return state[key]?.rows?.map(item => {
      return prepFunc(item);
    }) || [];
  },
  userByIdMap: (state) => {
    return state.users?.rows?.reduce((acc, user) => {
      acc[user.id] = user;

      return acc;
    }, {}) || {};
  },
  roleByIdMap: (state) => {
    return state.roles?.rows?.reduce((acc, role) => {
      acc[role.id] = role;

      return acc;
    }, {}) || {};
  }
};

const mutations = {
  [types.SET_USERS](state, value) {
    state.users = value;
  },
  [types.SET_OLD_USERS](state, value) {
    state.old_users = value;
  },
  [types.SET_ROLES](state, value) {
    state.roles = value;
  },
  [types.SET_CATALOGS](state, value) {
    state.catalogs = value;
  },
  [types.REMOVE_CATALOGS](state, { index, count }) {
    state.catalogs.rows.splice(index + 1, count);
  },
  [types.APPEND_CATALOGS](state, { rowIndex, additionalRows }) {
    state.catalogs.rows.splice(rowIndex + 1, 0, ...additionalRows);
  },
  [types.ADD_OPENED_CATALOG_NODES](state, nodeId) {
    state.openCatalogNodes.push(nodeId);
  },
  [types.REMOVE_OPENED_CATALOG_NODES](state, nodeId) {
    state.openCatalogNodes = state.openCatalogNodes.filter(id => id !== nodeId);
  },
  [types.CLEAR_OPENED_CATALOG_NODES](state) {
    state.openCatalogNodes = [];
  },
  [types.SET_CATALOG_ID](state, value) {
    state.catalog_id = value;
  },
  [types.SET_PERMISSIONS](state, value) {
    state.permissions = value;
  },
  [types.SET_EDITED_PERMISSIONS](state, { index, type, item }) {
    const isCat = !!item?.id;
    const currentCatalogItem = state.permissions[index];
    const currentCatalogs = currentCatalogItem[type] || [];
    const itemIndex = currentCatalogs?.findIndex(i => {
      if (isCat) {
        return i.id === item.id;
      }

      return i._s === item._s;
    });

    if (itemIndex !== -1) {
      const currentItem = currentCatalogs[itemIndex];

      if (currentItem.type === item.type || item.type === accessStateKeys.NONE) {
        return currentCatalogs.splice(itemIndex, 1);
      }

      return currentCatalogs.splice(itemIndex, 1, item);
    }

    Vue.set(currentCatalogItem, type, [
      ...currentCatalogs,
      item
    ]);
  },
  [types.SET_IS_FETCHING](state, value) {
    state.is_fetching = value;
  },
  [types.SET_IS_FETCHING_PART](state, value) {
    state.is_fetching_part = value;
  }
};

const actions = {
  async fetchUsers({ commit }) {
    try {
      commit(types.SET_IS_FETCHING, true);

      const response = await userApi.getUsers();

      if (!response?.data) {
        return;
      }

      const newUsers = GenericTableResponseAdapter(
        response.data?.newUsers
      );
      const oldUsers = GenericTableResponseAdapter(
        response.data?.oldUsers,
        (row) => ({ ...row, forMigration: true })
      );

      commit(types.SET_USERS, newUsers);
      commit(types.SET_OLD_USERS, oldUsers);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchUsers' });
    } finally {
      commit(types.SET_IS_FETCHING, false);
    }
  },
  async createUser({ commit, dispatch }, payload) {
    try {
      commit(types.SET_IS_FETCHING, true);

      const response = await userApi.createUser(payload);

      if (response.status === statusCodes.OK) {
        Vue.notify({
          type: 'success',
          title: i18n.t('Web.Notification.AddedSuccessfully', { 1: i18n.t('Web.Users.User') })
        });

        await dispatch('fetchUsers');
      }
    } catch (e) {
      const errorData = e?.error;

      if (slCmdErrorCodes.includes(errorData?.code)) {
        throw errorData;
      }

      this.dispatch('user/logout', { e, from: 'createUser' });
    } finally {
      commit(types.SET_IS_FETCHING, false);
    }
  },
  async updateUser({ commit }, payload) {
    try {
      commit(types.SET_IS_FETCHING, true);

      const response = await userApi.updateUser(payload);

      return response;
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'updateUser' });
    } finally {
      commit(types.SET_IS_FETCHING, false);
    }
  },
  async updateOldUser({ commit }, payload) {
    try {
      commit(types.SET_IS_FETCHING, true);

      const response = await userApi.updateOldUser(payload);

      return response;
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'updateOldUser' });
    } finally {
      commit(types.SET_IS_FETCHING, false);
    }
  },
  async deleteUser({ commit }, payload) {
    try {
      commit(types.SET_IS_FETCHING, true);

      const deleteMethod = payload.row.forMigration ? userApi.deleteOldUser : userApi.deleteUser;

      await deleteMethod(payload.row.id);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'deleteUser' });
    } finally {
      commit(types.SET_IS_FETCHING, false);
    }
  },
  async migrateUsers({ commit }) {
    try {
      commit(types.SET_IS_FETCHING, true);

      const response = await userApi.migrateUsers();

      if (response.status === statusCodes.OK) {
        Vue.notify({
          type: 'success',
          title: i18n.t('Web.Notification.SentSuccessfully', { 1: i18n.t('Web.Users.Invitation') })
        });
      }
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'migrateUsers' });

      throw e;
    } finally {
      commit(types.SET_IS_FETCHING, false);
    }
  },
  async fetchRoles({ commit }) {
    try {
      commit(types.SET_IS_FETCHING, true);

      const response = await userApi.getRoles();

      if (response?.data) {
        commit(types.SET_ROLES, GenericTableResponseAdapter(response.data));
      }
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchRoles' });
    } finally {
      commit(types.SET_IS_FETCHING, false);
    }
  },
  async fetchRoleById(_, { id, settings }) {
    try {
      const response = await userApi.getRoles({ id });

      if (!response.data) {
        return;
      }

      return RolesResponseAdapter(response.data, settings);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchRoles' });
    }
  },
  async createRole({ commit }, payload) {
    try {
      commit(types.SET_IS_FETCHING, true);

      const response = await userApi.createRole(UpdateRoleRequestAdapter(payload));

      if (response.status === statusCodes.OK) {
        Vue.notify({
          type: 'success',
          title: i18n.t('Web.Notification.CreatedSuccessfully', { 1: i18n.t('Web.Users.Role') })
        });
      }
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'createRole' });
    } finally {
      commit(types.SET_IS_FETCHING, false);
    }
  },
  async updateRole({ commit }, payload) {
    try {
      commit(types.SET_IS_FETCHING, true);

      await userApi.updateRole(UpdateRoleRequestAdapter(payload));
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'updateRole' });
    } finally {
      commit(types.SET_IS_FETCHING, false);
    }
  },
  async deleteRole({ commit }, id) {
    try {
      commit(types.SET_IS_FETCHING, true);

      await userApi.deleteRole(id);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'deleteRole' });
    } finally {
      commit(types.SET_IS_FETCHING, false);
    }
  },
  async fetchCatalogAccessTree({ commit, state }) {
    if (!state.catalog_id) {
      return;
    }

    try {
      commit(types.SET_IS_FETCHING, true);

      const response = await userApi.getCatalogAccessTree({
        id: state.catalog_id,
        nodes: state.openCatalogNodes.join(',') || undefined
      });

      if (response?.data) {
        commit(
          types.SET_CATALOGS,
          GenericTableResponseAdapter(response.data)
        );
      }
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchCatalogAccessTree' });
    } finally {
      commit(types.SET_IS_FETCHING, false);
    }
  },
  setCatalogTreeId({ commit }, id) {
    commit(types.SET_CATALOG_ID, id);
  },
  async resetCatalogTree({ commit }, id) {
    try {
      await userApi.deleteCatalogTreeCache({ id });

      commit(types.SET_CATALOG_ID, null);
      commit(types.CLEAR_OPENED_CATALOG_NODES);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'resetCatalogTree' });
    }
  },
  async fetchCatalogTreeChildNodes({ state, commit }, { rowIndex, nodeId, type }) {
    try {
      commit(types.SET_IS_FETCHING, true);

      const response = await userApi.getCatalogTreeChildNodes({
        id: state.catalog_id,
        node: nodeId,
        type
      });

      if (response?.data) {
        const additionalRows = GenericTableResponseAdapter(response.data).rows;

        commit(types.APPEND_CATALOGS, {
          rowIndex,
          additionalRows
        });

        commit(types.ADD_OPENED_CATALOG_NODES, nodeId);
      }
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchCatalogAccessTree' });
    } finally {
      commit(types.SET_IS_FETCHING, false);
    }
  },
  collapseCatalogAccessTree({ state, commit }, { index, depth, nodeId }) {
    let numberOfChildNodes = 0;
    const rows = state.catalogs.rows;

    for (let i = index + 1; i < rows.length; i++) {
      const currentRow = rows[i];

      if (currentRow.depth > depth) {
        numberOfChildNodes++;
      } else {
        break;
      }

      if (state.openCatalogNodes.includes(currentRow.id)) {
        commit(types.REMOVE_OPENED_CATALOG_NODES, currentRow.id);
      }
    }

    commit(types.REMOVE_OPENED_CATALOG_NODES, nodeId);

    commit(types.REMOVE_CATALOGS, {
      index,
      count: numberOfChildNodes
    });
  },
  async changeCatalogAccessCatalog({ state }, { nodeId, status }) {
    return userApi.changeCatalogAccessTree({
      id: state.catalog_id,
      node: nodeId,
      status
    });
  },
  async createCatalogAccessTree({ commit }) {
    try {
      commit(types.SET_IS_FETCHING, true);

      const response = await userApi.createCatalogAccessTree();

      if (response?.data) {
        commit(types.SET_CATALOG_ID, response.data.payload?.id);
      }
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchCatalogs' });
    } finally {
      commit(types.SET_IS_FETCHING, false);
    }
  },
  async fetchCatalogs({ commit }) {
    try {
      commit(types.SET_IS_FETCHING, true);

      const response = await userApi.getCatalogs();

      if (response?.data) {
        commit(types.SET_PERMISSIONS, GenericTableResponseAdapter(response.data));
      }
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchCatalogs' });
    } finally {
      commit(types.SET_IS_FETCHING, false);
    }
  },
  async createCatalog({ dispatch }, payload) {
    dispatch('updateCatalog', {
      ...payload,
      isCreate: true
    });
  },
  async updateCatalog({ state, commit }, payload) {
    try {
      commit(types.SET_IS_FETCHING, true);

      const response = await userApi.updateCatalog({
        ...payload,
        id: state.catalog_id
      });

      if (response.status === statusCodes.OK && payload.isCreate) {
        Vue.notify({
          type: 'success',
          title: i18n.t('Web.Notification.CreatedSuccessfully', { 1: i18n.t('Web.Users.Catalog') })
        });
      }
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'updateCatalog' });
    } finally {
      commit(types.SET_IS_FETCHING, false);
    }
  },
  async deleteCatalog({ commit }, id) {
    try {
      commit(types.SET_IS_FETCHING, true);

      await userApi.deleteCatalog(id);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'deleteCatalog' });
    } finally {
      commit(types.SET_IS_FETCHING, false);
    }
  },
  editCatalog({ state, commit }, { id, type, item }) {
    const index = state.permissions.findIndex(p => p.id === id);

    commit(types.SET_EDITED_PERMISSIONS, {
      index,
      type,
      item
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
