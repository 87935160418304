import axios from '@/plugins/axios';

export default {
  getTree(params) {
    return axios.get('/tree', {
      params
    });
  },
  getTreeChild(params) {
    return axios.get('/tree/child', {
      params
    });
  },
  getTreeByLink(params) {
    return axios.get('/tree/link', {
      params
    });
  },
  getNodePath(params) {
    return axios.get('/tree/path', {
      params
    });
  },
  searchTreeNodes(params) {
    return axios.get('/tree/search', {
      params
    });
  },
  exportNodeToXlsx(params) {
    return axios.get('/tree/node/xlsx', {
      params,
      responseType: 'blob',
      headers: {
        'Accept': 'application/octet-stream'
      }
    });
  },
  postApprovePrediction(body) {
    return axios.post('/tree/approve_prediction', body);
  },
  postCheckChannel(params) {
    return axios.post('/tree/check_channel', null, { params });
  },
  postSettings(data, params) {
    return axios.post('/settings', data, { params });
  },
  getTreeCategories() {
    return axios.get('/tree/categories_headers');
  },
  getNote(params) {
    return axios.get('/tree/note', { params });
  },
  updateNote(params, body) {
    return axios.post('/tree/note', body, { params });
  },
  changeTreeSettings(body) {
    return axios.post('/tree/ui-settings', body);
  }
};
