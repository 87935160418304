import SpreadsheetAdapter from '@/adapters/response/integrations/configuration/Spreadsheet.adapter';
import DatabaseRelatedAdapter from '@/adapters/response/integrations/configuration/DatabaseRelated.adapter';
import SAPAdapter from '@/adapters/response/integrations/configuration/SAP.adapter';
import NetsuiteAdapter from '@/adapters/response/integrations/configuration/Netsuite.adapter';
import { integrationTypes } from '@/config/integrations';

export default function(settings, integration, connection) {
  const adapterByType = {
    [integrationTypes.SPREADSHEET]: SpreadsheetAdapter,
    [integrationTypes.DATABASE]: DatabaseRelatedAdapter,
    [integrationTypes.SAP]: SAPAdapter,
    [integrationTypes.NETSUITE]: NetsuiteAdapter
  };

  const adapter = adapterByType[integration.type];

  return adapter ? adapter(settings, integration, connection) : settings;
}
