export const getFilteredTable = (pinnedColumns, table, isPinned) => {
  if (!table?.body) {
    return {};
  }

  const pinnedColIndexes = pinnedColumns.map((pinnedHeader) => {
    const index = table.head.col.findIndex(
      header => header._class === pinnedHeader.class && header._index === pinnedHeader.index
    );

    return index;
  });

  const filterIterator = getFilterIterator(pinnedColIndexes, isPinned);

  const head = table.head.col.filter(filterIterator);
  const body = table.body.filter(filterIterator);

  if (isPinned) {
    // sort body based on head array order and pinnedColumns array order
    body.sort((a, b) => {
      const localAIndex = body.findIndex(col => col.id === a.id);
      const localBIndex = body.findIndex(col => col.id === b.id);

      const aIndex = pinnedColumns.findIndex(col => col.class === head[localAIndex]._class && col.index === head[localAIndex]._index);
      const bIndex = pinnedColumns.findIndex(col => col.class === head[localBIndex]._class && col.index === head[localBIndex]._index);

      return aIndex - bIndex;
    });

    head.sort((a, b) => {
      const aIndex = pinnedColumns.findIndex(col => col.class === a._class && col.index === a._index);
      const bIndex = pinnedColumns.findIndex(col => col.class === b._class && col.index === b._index);

      return aIndex - bIndex;
    });
  }

  return {
    ...table,
    body,
    head: { col: head }
  };
};

const getFilterIterator = (indexes, mustInclude) => (_, index) => {
  return mustInclude === indexes.includes(index);
};
