import { HEADER_KEY_SEPARATOR } from '@/config/report';

export function getResizedIndexesFromClasses(headers, columns) {
  return Object.keys(columns).reduce((acc, key) => {
    const [_class, _index] = key.split(HEADER_KEY_SEPARATOR);

    const header = headers.find(h => h._class === _class && h._index === _index);

    if (header) {
      acc[+header._columnPosition] = columns[key];
    }

    return acc;
  }, []);
}

export function getHeaderKeyWithIndex({ _index, _class }) {
  return _index ? _class + HEADER_KEY_SEPARATOR + _index : _class;
}
