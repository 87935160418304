import Vue from 'vue';
import constrainedPlanApi from '@/api/reports/inventoryReport/constrainedPlan';
import GenericTableAdapter from '@/adapters/response/GenericTable.adapter';
import { namespaceByRoute } from '@/config/report';
import { getTableConfigStructure, getTableConfigParams, sortingParamsAdapter } from '@/helpers/shared/tableConfig';
import { getRouteName } from '@/helpers/shared/router';
import { sumBy } from '@/config/report/inventoryReport/constrainedPlan.config';

const types = {
  SET_TABLE: 'SET_TABLE',
  UPDATE_TABLE_METADATA: 'UPDATE_TABLE_METADATA',
  SET_LAST_UPDATE: 'SET_LAST_UPDATE',
  SET_LAST_DURATION: 'SET_LAST_DURATION',
  SET_OP_ID: 'SET_OP_ID',
  SET_RUN_START: 'SET_RUN_START',
  RESET_STATE: 'RESET_STATE',
  UPDATE_TABLE_CONFIG: 'UPDATE_TABLE_CONFIG',
  SET_TABLE_CONFIG: 'SET_TABLE_CONFIG'
};

const initialState = () => ({
  table: null,
  runningOperationId: null,
  lastUpdated: null,
  lastRunDuration: null,
  runningOperationStartStamp: null,
  table_config: getTableConfigStructure()
});

const state = initialState();

const getters = {
  commonParams: (state, _, rootState) => {
    const routeName = getRouteName();

    return {
      type: state.table?.metadata?.type,
      sumBy: state.table?.metadata?.sumBy?.val || sumBy.AS_IS,
      id: rootState[namespaceByRoute[routeName]].active_filter_id
    };
  },
  requestParams: (state, getters) => ({
    ...getters.commonParams,
    ...getTableConfigParams(state.table_config)
  })
};

const mutations = {
  [types.SET_TABLE](state, value) {
    state.table = value;
  },
  [types.UPDATE_TABLE_METADATA](state, { key, value }) {
    Vue.set(state.table.metadata, key, value);
  },
  [types.SET_LAST_UPDATE](state, value) {
    state.lastUpdated = value;
  },
  [types.SET_LAST_DURATION](state, value) {
    state.lastRunDuration = value;
  },
  [types.SET_OP_ID](state, value) {
    state.runningOperationId = value;
  },
  [types.SET_RUN_START](state, value) {
    state.runningOperationStartStamp = value;
  },
  [types.UPDATE_TABLE_CONFIG](state, { key, value }) {
    Vue.set(state.table_config, key, value);
  },
  [types.SET_TABLE_CONFIG](state, payload) {
    Object.assign(state.table_config, payload);
  },
  [types.RESET_STATE](state) {
    const initial = initialState();

    Object.keys(initial).forEach(key => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  async fetchConstrainedPlan({ commit, getters }) {
    try {
      const response = await constrainedPlanApi.getConstrainedPlan(getters.requestParams);

      const data = response?.data;

      if (!data) {
        return;
      }

      const {
        table,
        runningOperationId,
        lastUpdated,
        lastRunDuration,
        runningOperationStartStamp
      } = data;

      commit(types.SET_TABLE, GenericTableAdapter(table));
      commit(types.SET_TABLE_CONFIG, sortingParamsAdapter(table));
      commit(types.SET_LAST_UPDATE, lastUpdated);
      commit(types.SET_LAST_DURATION, lastRunDuration);
      commit(types.SET_RUN_START, runningOperationStartStamp);
      commit(types.SET_OP_ID, runningOperationId);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'constrainedPlan/fetchConstrainedPlan' });
    }
  },
  updateTableConfig({ commit }, payload) {
    commit(types.UPDATE_TABLE_CONFIG, payload);
  },
  updateTableMetadata({ commit }, payload) {
    commit(types.UPDATE_TABLE_METADATA, payload);
  },
  exportToXlsx({ getters }, params) {
    return constrainedPlanApi.exportToXlsx({
      ...getters.commonParams,
      ...params
    });
  },
  runCalculation() {
    return constrainedPlanApi.runCalculation();
  },
  resetState({ commit }) {
    commit(types.RESET_STATE);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
