<template>
  <div class="connector-wrap connector-wrap--simple">
    <div
      v-if="!isNew"
      class="connector-content-block__row connector-content-block__row--divider"
    >
      <SlInfoItem
        :label="$t('Web.BaseConnectors.Form.combineLocs')"
        :value="combineLocsLabel"
      />
    </div>
    <div class="connector-content-block__row w-240">
      <SlSelect
        :key="importFrom"
        v-model="importFrom"
        :select-label="$t('Web.BaseConnectors.Form.importFrom')"
        :options="importFromSet"
      />
    </div>
    <SlCheckbox
      v-model="vendorsAsCategories"
      :label="$t('Web.BaseConnectors.Form.vendorsAsCategories')"
    />
    <SlCheckbox
      v-if="isNew"
      v-model="combineLocs"
      :label="$t('Web.BaseConnectors.Form.combineLocs')"
    />
  </div>
</template>

<script>
import { connectionTypes } from '@/config/connection';
import { connection } from '@/mixins/connection';
import { getLabelFromBoolean } from '@/helpers/locale/getLabel';
export default {
  name: 'ProntoOptionalTab',
  mixins: [connection],
  props: {
    isNew: Boolean
  },
  data() {
    return {
      type: connectionTypes.PRONTO_XI
    };
  },
  computed: {
    combineLocs: {
      get() {
        return Boolean(+this.connectionData.optional.combineLocs);
      },
      set(value) {
        this.setOptionalValue({
          key: 'combineLocs',
          value
        });
      }
    },
    vendorsAsCategories: {
      get() {
        return Boolean(+this.connectionData.optional.vendorsAsCategories);
      },
      set(value) {
        this.setOptionalValue({
          key: 'vendorsAsCategories',
          value
        });
      }
    },
    importFrom: {
      get() {
        return this.connectionData.optional.category || this.importFromSet[0];
      },
      set(value) {
        this.setOptionalValue({
          key: 'category',
          value
        });
      }
    },
    importFromSet() {
      return [
        'None',
        'Group',
        'Category',
        'Class',
        'Division',
        'Department'
      ];
    },
    combineLocsLabel() {
      return getLabelFromBoolean(this.combineLocs, this);
    }
  }
};
</script>
