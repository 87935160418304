<template>
  <component
    :is="component"
    v-close-popper="selectClose"
    :to="href"
    :href="href"
    :target="target"
    tabindex="0"
    class="sl-dropdown-option body-1 grey-80"
    :class="{
      'sl-dropdown-option--disabled': disabled,
      'sl-dropdown-option--selected': selected,
      'sl-dropdown-option--divider': divider
    }"
    @click="$emit('select')"
    @keydown.enter="$emit('select')"
  >
    <slot name="prepend" />

    <div class="sl-dropdown-option__label">
      <slot />
      <slot name="append-inner" />
    </div>

    <slot name="append" />
  </component>
</template>

<script>
export default {
  name: 'SlDropdownOption',
  props: {
    href: {
      type: [String, Object],
      required: false,
      default: null
    },
    target: {
      type: String,
      default: ''
    },
    selected: Boolean,
    selectClose: Boolean,
    disabled: Boolean,
    divider: Boolean
  },
  computed: {
    component() {
      if (!this.href) {
        return 'div';
      }

      return this.target ? 'a' : 'router-link';
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/utils/_color.scss";
@import "@/style/utils/_variable.scss";

.sl-dropdown-option {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  column-gap: 8px;
  padding: 5px 12px;

  background: $white;

  transition: background $default-transition;

  text-decoration: none;
  cursor: pointer;
  user-select: none;

  &:hover, &:focus {
    background: $grey-10;

    outline: none;
  }

  &:active {
    background: $grey-20;
  }

  &__label {
    flex-grow: 1;
    display: flex;
    align-items: center;
    column-gap: 8px;

    label {
      display: block;

      cursor: pointer;
    }
  }

  &--disabled {
    pointer-events: none;
    opacity: .5;
  }

  &--selected {
    background: $grey-10;
  }

  &--divider {
    border-top: 1px solid $grey-30;
  }
}
</style>
