import Vue from 'vue';
import importTransform from './import';
import exportTransform from './export';
import integrationsApi from '@/api/integrations';
import connectorApi from '@/api/integrations/connector';
import databaseRelatedApi from '@/api/integrations/databaseRelated';
import netsuiteApi from '@/api/integrations/netsuite';
import QueryPreviewRequestAdapter from '@/adapters/request/integrations/transform/shared/QueryPreview.adapter';
import TablePreviewResponseAdapter from '@/adapters/response/integrations/transform/shared/TablePreview.adapter';
import TransformSettingsResponseAdapter from '@/adapters/response/integrations/transform';
import TransformSettingsRequestAdapter from '@/adapters/request/integrations/transform';
import ConfigurationRequestAdapter from '@/adapters/request/integrations/configuration';
import { databaseRelatedIntegrations, integrationModuleTypes, integrationTypes } from '@/config/integrations';
import { toArray } from '@/helpers/utils/toArray';

const types = {
  SET_TABLES_BY_CONNECTOR: 'SET_TABLES_BY_CONNECTOR',
  SET_TEMPORARY_PREVIEW: 'SET_TEMPORARY_PREVIEW',
  SET_TEMPORARY_TABLE_NAME: 'SET_TEMPORARY_TABLE_NAME'
};

const initialState = () => ({
  tables: [],
  temporary_preview: {},
  temporary_table_name: ''
});

const state = initialState();

const getters = {};

const mutations = {
  [types.SET_TABLES_BY_CONNECTOR](state, tables) {
    state.tables = tables;
  },
  [types.SET_TEMPORARY_PREVIEW](state, preview) {
    state.temporary_preview = preview;
  },
  [types.SET_TEMPORARY_TABLE_NAME](state, tableName) {
    state.temporary_table_name = tableName;
  }
};

const actions = {
  // transformation settings
  async fetchTransformSettings({ rootState, dispatch }) {
    const activeIntegration = rootState.integrations.active_integration;
    const actionByModule = {
      [integrationModuleTypes.IMPORT]: 'fetchImportSettings',
      [integrationModuleTypes.EXPORT]: 'fetchExportSettings'
    };

    return dispatch(actionByModule[activeIntegration.module], activeIntegration);
  },
  async fetchImportSettings({ rootState, dispatch }) {
    try {
      const { id, type } = rootState.integrations.active_integration;

      const response = await connectorApi.getTransformSettings({ id });

      if (!response?.data) {
        return;
      }

      dispatch('setSettings', {
        type,
        ...response.data
      });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchImportSettings' });
    }
  },
  async fetchExportSettings({ dispatch }, { id, module, type }) {
    try {
      const response = await integrationsApi.getIntegrations({
        module,
        id
      });

      if (!response?.data) {
        return;
      }

      dispatch('setSettings', {
        type,
        ...response?.data
      });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchExportSettings' });
    }
  },
  setSettings({ rootState }, data) {
    const { module } = rootState.integrations.active_integration;
    const actionByModule = {
      [integrationModuleTypes.IMPORT]: 'integrations/transform/import/setSettings',
      [integrationModuleTypes.EXPORT]: 'integrations/transform/export/setSettings'
    };

    this.dispatch(actionByModule[module], TransformSettingsResponseAdapter(data, rootState.integrations.active_integration));
  },
  // tables list
  async fetchTables({ rootState, dispatch }) {
    try {
      const { id, type } = rootState.integrations.active_integration;
      const actionByType = {
        [integrationTypes.AGGREGATION]: 'fetchIntermediateDatabaseTables',
        [integrationTypes.AMAZON]: 'fetchIntermediateDatabaseTables',
        [integrationTypes.QB_DESKTOP]: 'fetchIntermediateDatabaseTables',
        [integrationTypes.CUSTOM_API]: 'fetchIntermediateDatabaseTables',
        [integrationTypes.BC365]: 'fetchIntermediateDatabaseTables',
        [integrationTypes.DATABASE]: 'fetchDatabaseRelatedTables',
        [integrationTypes.SAP]: 'fetchDatabaseRelatedTables',
        [integrationTypes.NETSUITE]: 'fetchNetsuiteTables'
      };

      await dispatch(actionByType[type], id);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchTables' });
    }
  },
  async fetchIntermediateDatabaseTables({ commit }, id) {
    try {
      const response = await connectorApi.getTables({ id });
      const tables = response?.data?.connectors;

      if (!tables) {
        return;
      }

      commit(types.SET_TABLES_BY_CONNECTOR, tables);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchIntermediateDatabaseTables' });
    }
  },
  async fetchDatabaseRelatedTables({ rootState, commit }, id) {
    try {
      const { operationData } = await this.dispatch('operations/subscribe', {
        subscriber: () => databaseRelatedApi.getDatabaseTables({
          id
        })
      });

      if (!operationData) {
        return;
      }

      commit(types.SET_TABLES_BY_CONNECTOR, [{
        connector: { ...rootState.integrations.active_integration },
        tables: toArray(operationData?.tables)
      }]);
    } catch (e) {
      this.dispatch('user/logout', {
        e,
        from: 'fetchIntermediateDatabaseTables'
      });
    }
  },
  async fetchNetsuiteTables({ rootState, commit }, id) {
    try {
      const { operationData } = await this.dispatch('operations/subscribe', {
        subscriber: () => netsuiteApi.getTables({ id })
      });

      if (!operationData) {
        return;
      }

      commit(types.SET_TABLES_BY_CONNECTOR, [{
        connector: { ...rootState.integrations.active_integration },
        tables: operationData?.tables
      }]);
    } catch (e) {
      this.dispatch('user/logout', {
        e,
        from: 'fetchNetsuiteTables'
      });
    }
  },
  tablePreviewSubscriber({ dispatch }, payload) {
    if (!payload.table) {
      return dispatch('tablePreviewMainSubscriber', payload);
    }

    return dispatch('tablePreviewTempSubscriber', payload);
  },
  tablePreviewMainSubscriber({ rootState }, payload) {
    const { id, type } = rootState.integrations.active_integration;
    const importState = rootState.integrations.transform.import;

    return connectorApi.getTablePreview(
      {
        id: databaseRelatedIntegrations.includes(type) ? id : null
      },
      QueryPreviewRequestAdapter(importState, payload)
    );
  },
  tablePreviewTempSubscriber({ rootState }, { table }) {
    const { id, type } = rootState.integrations.active_integration;

    return connectorApi.getTableTempPreview({
      id: databaseRelatedIntegrations.includes(type) ? id : null,
      table
    });
  },
  async fetchTablePreview({ commit, dispatch }, payload) {
    try {
      commit(types.SET_TEMPORARY_TABLE_NAME, payload.table);

      dispatch('setTablePreviewState', {
        ...payload,
        value: {
          isLoading: true
        }
      });

      const { operationData } = await this.dispatch('operations/subscribe', {
        subscriber: () => dispatch('tablePreviewSubscriber', payload)
      });

      dispatch('setTablePreview', {
        ...payload,
        response: operationData
      });
    } catch (e) {
      const errorMessage = e?.message;

      if (errorMessage) {
        Vue.notify({
          type: 'error',
          text: errorMessage,
          duration: 15000
        });
      }

      dispatch('setTablePreviewState', {
        ...payload,
        value: {
          isLoading: false,
          isLoaded: true
        }
      });
    }
  },
  setTablePreviewState({ commit }, { table, tab, value }) {
    if (table) {
      return commit(types.SET_TEMPORARY_PREVIEW, value);
    }

    this.dispatch('integrations/transform/import/setImportPreview', {
      tab,
      value
    });
  },
  setTablePreview({ commit }, { table, tab, response }) {
    const { matchedSlots, slotsIndexes, ...tableData } = TablePreviewResponseAdapter(response);
    const value = {
      ...tableData,
      isLoading: false,
      isLoaded: true
    };

    if (table) {
      return commit(types.SET_TEMPORARY_PREVIEW, value);
    }

    this.dispatch('integrations/transform/import/setImportPreview', {
      tab,
      value,
      matchedSlots,
      slotsIndexes
    });
  },
  resetTemporaryTablePreview({ commit, dispatch }) {
    commit(types.SET_TEMPORARY_TABLE_NAME, '');

    dispatch('setTablePreviewState', {
      table: true,
      value: {}
    });
  },
  // confirm transformation settings
  async confirmTransformation({ dispatch }) {
    try {
      await dispatch('saveSettings');
      await dispatch('importIntegration');

      return true;
    } catch {
      return false;
    }
  },
  async saveSettings({ rootState }) {
    try {
      const { id, module, type } = rootState.integrations.active_integration;
      const { import: importState, export: exportState } = rootState.integrations.transform;
      const settingsByModule = {
        [integrationModuleTypes.IMPORT]: importState,
        [integrationModuleTypes.EXPORT]: exportState
      };
      const requestByModule = {
        [integrationModuleTypes.IMPORT]: connectorApi.updateTransformSettings,
        [integrationModuleTypes.EXPORT]: connectorApi.updateIntegrationSettings
      };

      await requestByModule[module](
        {
          id,
          module
        },
        TransformSettingsRequestAdapter(settingsByModule[module], rootState.integrations.active_integration)
      );

      if (databaseRelatedIntegrations.includes(type) && module === integrationModuleTypes.IMPORT) {
        await connectorApi.updateIntegrationSettings(
          {
            id,
            module
          },
          ConfigurationRequestAdapter(rootState.integrations.settings.configuration, rootState.integrations.active_integration)
        );
      }
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'saveSettings' });

      const message = e?.message;

      if (message) {
        Vue.notify({
          type: 'error',
          text: message,
          duration: -1
        });
      }

      throw e;
    }
  },
  async importIntegration({ rootState }) {
    try {
      const { type, exportType } = rootState.integrations.active_integration;

      if (!databaseRelatedIntegrations.includes(type) || exportType) {
        return;
      }

      await this.dispatch('operations/subscribe', {
        subscriber: () => this.dispatch('integrations/importIntegration')
      });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'transform/importIntegration' });

      const message = e?.message;

      if (message) {
        Vue.notify({
          type: 'error',
          text: message,
          duration: -1
        });
      }

      throw e;
    }
  },
  resetState() {
    this.dispatch('integrations/transform/import/resetState');
    this.dispatch('integrations/transform/export/resetState');
  }
};

export default {
  namespaced: true,
  modules: {
    import: importTransform,
    export: exportTransform
  },
  state,
  getters,
  mutations,
  actions
};
