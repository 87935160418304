export const SUBPAGE_PAGE_SIZE = 20;

export const getTableConfigStructure = (queryKeys = []) => ({
  search: '',
  page: 1,
  sortOrder: '',
  colClass: '',
  perPage: SUBPAGE_PAGE_SIZE,
  queryKeys
});

export const getTableConfigParams = (config) => {
  if (!config) {
    return {
      top: SUBPAGE_PAGE_SIZE,
      skip: 0
    };
  }

  const {
    page,
    search,
    perPage
  } = config;

  return {
    query: search || undefined,
    top: perPage,
    skip: perPage * (page - 1)
  };
};

export const getTableSortingParams = (config) => {
  if (!config) {
    return null;
  }

  const {
    sortOrder,
    colClass
  } = config;

  return {
    sortOrder,
    class: colClass
  };
};

export const sortingParamsAdapter = (table) => {
  if (!table) {
    return {};
  }

  return  {
    colClass: table?.metadata?.sortedMeaning,
    sortOrder: table?.metadata?.sortOrder
  };
};