import chartApi from '@/api/chart';
import ChartResponseAdapter from '@/adapters/response/ChartResponse.adapter';
import {
  chartTabKeys,
  chartDatasetLegend,
  lineConfigByKey,
  curveKeys
} from '@/config/demand/chart/chart.config';
import { dateByLocaleKey, localeDateKeys } from '@/helpers/locale/localeDate';

const types = {
  SET_CHART_DATA: 'SET_CHART_DATA',
  SET_CHART_TITLE: 'SET_CHART_TITLE',
  SET_FORECAST_TITLE: 'SET_FORECAST_TITLE',
  SET_CHART_UNIT: 'SET_CHART_UNIT',
  SET_CURRENT_CHART_TYPE: 'SET_CURRENT_CHART_TYPE',
  SET_IS_LOADING: 'SET_IS_LOADING',
  SET_CURVES_VISIBILITY: 'SET_CURVES_VISIBILITY'
};

const state = () => ({
  chartData: {
    [chartTabKeys.QTY]: {
      datasets: [],
      dates: []
    },
    [chartTabKeys.REVENUE]: {
      datasets: [],
      dates: []
    }
  },
  curvesVisibility: null,
  chartTitle: '',
  forecastTitle: '',
  chartUnit: {
    unit: 'month',
    val: '1'
  },
  chartType: chartTabKeys.QTY,
  isLoading: false
});

const getters = {
  currentDatasets: (state) => state.chartData[state.chartType].datasets || [],
  currentDates: (state) => state.chartData[state.chartType].dates || [],
  forecastTitleCode: (state) => state.forecastTitle?.code || null,
  filledChartData: (state, getters, _, rootGetters) => {
    const chartLegend = chartDatasetLegend(getters.forecastTitleCode, rootGetters['isColorblindMode']);

    return {
      datasets: getters.currentDatasets?.reduce((reducedDatasets, dataset) => {
        if (dataset.data.every((point) => point.y === undefined)) {
          return reducedDatasets;
        }

        dataset = {
          ...dataset,
          ...lineConfigByKey(dataset.labelKey, chartLegend, state.chartType),
          hidden: getters.hiddenCurves.includes(dataset.labelKey) ? true : null
        };

        const isSS = dataset.labelKey === curveKeys.SAFETY_STOCK;

        dataset.fill = isSS ? { value: 0 } : false;

        reducedDatasets.push(dataset);

        return reducedDatasets;
      }, []),
      labels: getters.currentDates?.sort((a, b) => a - b) || []
    };
  },
  chartUnitLocale: (state) => state.chartUnit.unit === 'month'
    ? localeDateKeys.YM
    : localeDateKeys.YMD,
  localeChartDates: (state, getters) => {
    return getters.currentDates?.map(date => dateByLocaleKey(date, getters.chartUnitLocale));
  },
  chartType: (state) => state.chartType || chartTabKeys.QTY,
  hiddenCurves: state => {
    if (!state.curvesVisibility) {
      return [];
    }

    return Object.keys(state.curvesVisibility).reduce((acc, key) => {
      if (!state.curvesVisibility[key]) {
        acc.push(key);
      }

      return acc;
    }, []);
  }
};

const mutations = {
  [types.SET_CHART_DATA](state, { type, ...payload }) {
    Object.assign(state.chartData[type], payload);
  },
  [types.SET_CHART_TITLE](state, value) {
    state.chartTitle = value;
  },
  [types.SET_FORECAST_TITLE](state, value) {
    state.forecastTitle = value;
  },
  [types.SET_CHART_UNIT](state, value) {
    state.chartUnit = value;
  },
  [types.SET_CURRENT_CHART_TYPE](state, value) {
    state.chartType = value;
  },
  [types.SET_IS_LOADING](state, value) {
    state.isLoading = value;
  },
  [types.SET_CURVES_VISIBILITY](state, value) {
    state.curvesVisibility = value;
  }
};

const actions = {
  async fetchChart({ state, commit, dispatch, getters, rootGetters }) {
    try {
      if (!state.curvesVisibility) {
        await dispatch('fetchCurvesVisibility');
      }

      const chartType = getters.chartType;

      const response = await chartApi.getCharts({
        nodeId: rootGetters['demand/tree/activeNodeId'],
        chartType
      });
      const data = response?.data;

      if (!data) {
        return;
      }

      commit(
        types.SET_CHART_DATA,
        ChartResponseAdapter({
          data,
          tab: chartType,
          forecastTitle: getters.forecastTitleCode,
          isColorblind: rootGetters['isColorblindMode']
        })
      );
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchChart' });
    } finally {
      commit(types.SET_IS_LOADING, false);
    }
  },
  async fetchCurvesVisibility({ commit }) {
    try {
      const response = await chartApi.getCurvesVisibility();
      const data = response?.data;

      if (!data) {
        return;
      }

      commit(types.SET_CURVES_VISIBILITY, data);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchChartCurves' });
    }
  },
  async updateCurveVisibility(_, { curve, visibility }) {
    try {
      await chartApi.updateCurveVisibility({
        chartType: curve,
        visible: +visibility
      });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'updateCurveVisibility' });
    }
  },
  setChartTitle({ commit }, title) {
    commit(types.SET_CHART_TITLE, title);
  },
  setForecastTitle({ commit }, title) {
    commit(types.SET_FORECAST_TITLE, title);
  },
  setChartUnit({ commit }, { unit }) {
    commit(types.SET_CHART_UNIT, unit);
  },
  setChartType({ state, commit, dispatch, getters }, type) {
    const currentChartType = state.chartType;

    commit(types.SET_CURRENT_CHART_TYPE, type);

    if (currentChartType !== type && !getters.currentDatasets.length) {
      commit(types.SET_IS_LOADING, true);

      dispatch('fetchChart');
    }
  },
  resetHiddenChartData({ state, commit }) {
    const type = state.chartType === chartTabKeys.QTY
      ? chartTabKeys.REVENUE
      : chartTabKeys.QTY;

    commit(types.SET_CHART_DATA, { type, datasets: [], dates: [] });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
