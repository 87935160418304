import SpreadsheetAdapter from '@/adapters/response/integrations/connection/Spreadsheet.adapter';
import DatabaseRelatedAdapter from '@/adapters/response/integrations/connection/DatabaseRelated.adapter';
import SAPAdapter from '@/adapters/response/integrations/connection/SAP.adapter';
import { integrationTypes } from '@/config/integrations';

export default function(settings, integration) {
  const adapterByType = {
    [integrationTypes.SPREADSHEET]: SpreadsheetAdapter,
    [integrationTypes.DATABASE]: DatabaseRelatedAdapter,
    [integrationTypes.SAP]: SAPAdapter
  };

  const adapter = adapterByType[integration.type];

  return adapter ? adapter(settings, integration) : settings;
}
