import axios from '@/plugins/axios';

export default {
  getToolbar(params) {
    return axios.get('/forecast_report/toolbar', {
      params
    });
  },
  exportTableToXlsx(params) {
    return axios.get('/forecast_report/xlsx', {
      params,
      responseType: 'blob',
      headers: {
        'Accept': 'application/octet-stream'
      }
    });
  },
  importChanges(params, body) {
    return axios.post('/forecast_report/xlsx', body, {
      params,
      headers: {
        'Content-Type': 'application/octet-stream'
      }
    });
  },
  postForecastReport(body) {
    return axios.post('/forecast_report/toolbar', body);
  }
};