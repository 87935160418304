var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"connector-wrap connector-wrap--simple w-320"},[_c('div',{staticClass:"connector-content-block__row"},[_c('SlValidate',{attrs:{"vid":"host","mode":"eager","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":"Host","is-invalid":invalid},model:{value:(_vm.host),callback:function ($$v) {_vm.host=$$v},expression:"host"}})]}}])})],1),_c('div',{staticClass:"connector-content-block__row"},[_c('SlValidate',{attrs:{"vid":"web-resource","mode":"eager","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":"Web resource","is-invalid":invalid},model:{value:(_vm.webResource),callback:function ($$v) {_vm.webResource=$$v},expression:"webResource"}})]}}])})],1),_c('div',{staticClass:"connector-content-block__row"},[_c('SlValidate',{attrs:{"vid":"username","mode":"eager","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":"Username","is-invalid":invalid},model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}})]}}])})],1),_c('div',{staticClass:"connector-content-block__row"},[_c('SlValidate',{attrs:{"vid":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlPasswordInput',{attrs:{"label":"Password","is-invalid":invalid},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}])})],1),_c('Periods',{attrs:{"type":_vm.type,"is-new":_vm.isNew}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }