export default {
  MODE: process.env.VUE_APP_MODE,
  BASE_URL: process.env.VUE_APP_AXIOS_URL || window.location.origin,
  STATS_SERVER_URL: 'https://app-analytics.streamlineplan.com',
  FRONTEGG_CONFIG_BY_ENV: {
    production: {
      URL: 'https://auth.streamlineplan.com',
      ID: '2aeb4bb4-22c7-4e79-8618-2528523b6fb5',
      aliases: [
        {
          alias: 'rkfoodland',
          includesInHost: 'rkfoodland'
        }
      ]
    },
    development: {
      URL: 'https://auth-dev.streamlineplan.com',
      ID: '8245789f-e2b8-46f6-ae8e-7058778c9435',
      aliases: []
    }
  },
  APP_MODES: {
    DEV: 'development',
    PROD: 'production'
  }
};
