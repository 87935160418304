import monitoringApi from '@/api/dashboard/monitoring';
import router from '@/router';
import DashboardDataResponseAdapter from '@/adapters/response/DashboardDataResponse.adapter';
import { sortingOrders } from '@/config/report/inventoryReport';
import { colClassByTileKey, KPIsKey } from '@/config/dashboard/dashboard.config';
import { dateByLocaleKey, localeDateKeys } from '@/helpers/locale/localeDate';
import { routeNames } from '@/config/router/router.config';

const types = {
  SET_DASHBOARDS: 'SET_DASHBOARDS',
  SET_VISIBLE_TILE_NAMES: 'SET_VISIBLE_TILE_NAMES',
  RESET_STATE: 'RESET_STATE'
};

const initialState = () => ({
  tiles: [],
  selectDates: [],
  selectTileNames: [],
  visibleTileNames: null,
  periodsNumber: '',
  activeDate: '',
  barDates: [],
  isAvailable: []
});

const state = initialState();

const getters = {
  selectDates: state => state.selectDates.map(d => dateByLocaleKey(d, localeDateKeys.YMD)),
  barDates: state => state.barDates.map(d => dateByLocaleKey(d, localeDateKeys.YMD))
};

const mutations = {
  [types.SET_DASHBOARDS](state, value) {
    state.periodsNumber = value.periodsNumber;
    state.selectDates = value.selectDates;
    state.barDates = value.barDates;
    state.selectTileNames = value.selectTileNames;
    state.visibleTileNames = value.visibleTileNames;
    state.activeDate = value.activeDate;
    state.tiles = value.tiles;
    state.isAvailable = value.isAvailable;
  },
  [types.SET_VISIBLE_TILE_NAMES](state, value) {
    state.visibleTileNames = value;
  },
  [types.RESET_STATE](state) {
    const initial = initialState();

    Object.keys(initial).forEach(key => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  async fetchDashboard({ commit, rootGetters }) {
    try {
      const response = await monitoringApi.fetchDashboard();

      if (response?.data) {
        commit(
          types.SET_DASHBOARDS,
          DashboardDataResponseAdapter({
            data: response.data,
            currency: rootGetters['project/baseCurrency']
          })
        );
      }
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchDashboard' });
    }
  },
  async setPeriods(_, payload) {
    try {
      await monitoringApi.setPeriods(payload);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'setPeriods' });
    }
  },
  async postDashboardTiles(_, payload) {
    try {
      await monitoringApi.postDashboardTiles(payload);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'postDashboardTiles' });
    }
  },
  async postDashboardPeriods(_, payload) {
    try {
      await monitoringApi.postDashboardPeriods(payload);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'postDashboardPeriods' });
    }
  },
  async openTileLink(_, { type, lessIsBetter, index }) {
    await this.dispatch('forecastReport/setDataForLink', {
      request:  {
        ReportType: KPIsKey
      },
      sort: {
        sortOrder: lessIsBetter ? sortingOrders.DESCENDING : sortingOrders.ASCENDING,
        itemClass: colClassByTileKey[type],
        index
      }
    });

    return router.push({ name: routeNames.REPORTS });
  },
  async setVisibleTileNames({ commit }, payload) {
    if (payload) {
      commit(
        types.SET_VISIBLE_TILE_NAMES,
        payload
      );
    }
  },
  resetState({ commit }) {
    commit(types.RESET_STATE);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
