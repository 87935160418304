import Vue from 'vue';
import suppliersApi from '@/api/suppliers';
import GenericTableAdapter from '@/adapters/response/GenericTable.adapter';
import { getTableConfigStructure, getTableConfigParams, sortingParamsAdapter } from '@/helpers/shared/tableConfig';

const types = {
  SET_TABLE: 'SET_TABLE',
  SET_SUPPLIERS_SEARCH: 'SET_SUPPLIERS_SEARCH',
  SUBMIT_CELL_VALUE: 'SUBMIT_CELL_VALUE',
  SELECT_ROW: 'SELECT_ROW',
  RESET_STATE: 'RESET_STATE',
  UPDATE_TABLE_CONFIG: 'UPDATE_TABLE_CONFIG',
  SET_TABLE_CONFIG: 'SET_TABLE_CONFIG'
};

const initialState = () => ({
  table: null,
  tableConfig: getTableConfigStructure(),
  suppliers: []
});

const state = initialState();

const getters = {};

const mutations = {
  [types.SET_TABLE](state, value) {
    state.table = value;
  },
  [types.SET_SUPPLIERS_SEARCH](state, value) {
    state.suppliers = value;
  },
  [types.SUBMIT_CELL_VALUE](_, { cell, cellKey, value }) {
    Vue.set(cell, cellKey, value);
  },
  [types.SELECT_ROW](_, { cell, cellKey, value }) {
    Vue.set(cell, cellKey, { val: value });
  },
  [types.RESET_STATE](state) {
    const initial = initialState();

    Object.keys(initial).forEach(key => {
      state[key] = initial[key];
    });
  },
  [types.UPDATE_TABLE_CONFIG](state, { key, value }) {
    Vue.set(state.tableConfig, key, value);
  },
  [types.SET_TABLE_CONFIG](state, payload) {
    Object.assign(state.tableConfig, payload);
  }
};

const actions = {
  async fetchSuppliers({ commit }) {
    try {
      const response = await suppliersApi.getSuppliersTable({
        ...getTableConfigParams(state.tableConfig)
      });

      if (!response?.data) {
        return;
      }

      commit(types.SET_TABLE, GenericTableAdapter(response.data));
      commit(types.SET_TABLE_CONFIG, sortingParamsAdapter(response.data.table));
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'suppliers/fetchSuppliers' });
    }
  },
  async createSupplier(_, payload) {
    try {
      await suppliersApi.createSupplier(payload);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'suppliers/createSupplier' });
    }
  },
  async deleteRows() {
    try {
      await suppliersApi.deleteSuppliers();
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'suppliers/deleteRows' });
    }
  },
  async fetchSupplierNames(_, payload) {
    try {
      const response = await suppliersApi.getSupplierNames(payload);

      return response.data.suppliers;
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'suppliers/getSupplierNames' });
    }
  },
  async searchSuppliers({ commit }, payload) {
    try {
      const response = await suppliersApi.getSuppliersList({
        ...payload,
        top: 20,
        skip: 0
      });

      if (!response.data) {
        return;
      }

      commit(types.SET_SUPPLIERS_SEARCH, response.data.suppliers || []);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'suppliers/searchSuppliers' });
    }
  },
  updateTableConfig({ commit }, payload) {
    commit(types.UPDATE_TABLE_CONFIG, payload);
  },
  exportToXlsx() {
    return suppliersApi.exportToXlsx();
  },
  async updateOverrides(_, { id, ...value }) {
    try {
      await suppliersApi.overrideSupplier({
        global: !id,
        id
      }, value);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'suppliers/updateOverrides' });
    }
  },
  async saveOverrides() {
    try {
      await suppliersApi.applySupplierOverrides();
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'suppliers/saveOverrides' });
    }
  },
  async discardOverrides() {
    try {
      await suppliersApi.deleteSupplierOverrides();
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'suppliers/discardOverrides' });
    }
  },
  selectRow({ state, commit }, { rowId, ...payload }) {
    const rows = state.table.rows;
    const rowIndex = rows.findIndex(row => row.id === rowId);

    commit(types.SELECT_ROW, {
      cell: rows[rowIndex],
      ...payload
    });
  },
  submitCell({ state, commit }, { rowId, cellKey, value }) {
    const rows = state.table.rows;
    const rowIndex = rows.findIndex(row => row.id === rowId);

    if (rowIndex === -1) {
      return;
    }

    const oldCell = rows[rowIndex][cellKey];

    const newValue = {
      ...oldCell,
      val: value
    };

    commit(types.SUBMIT_CELL_VALUE, {
      cell: rows[rowIndex],
      cellKey,
      value: newValue
    });
  },
  resetState({ commit }) {
    commit(types.RESET_STATE);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
