import demandApi from '@/api/demand';

const types = {
  SET_COEFF_INFO: 'SET_COEFF_INFO',
  SET_TOPDOWN_COEFF: 'SET_TOPDOWN_COEFF'
};

const state = () => ({
  coeffInfo: null
});

const getters = {
  modelObj: state => {
    if (state.coeffInfo) {
      return state.coeffInfo;
    }
  },
  isModelAvailable(state) {
    if (!state.coeffInfo) {
      return false;
    }

    return state.coeffInfo?.modelCoeffs.length || state.coeffInfo?.topDownCoeffs.length;
  }
};

const mutations = {
  [types.SET_COEFF_INFO](state, value) {
    state.coeffInfo = value;
  },
  [types.SET_TOPDOWN_COEFF](state, value) {
    state.coeffInfo = value;
  }
};

const actions = {
  async fetchModelTab({ commit, rootGetters }) {
    try {
      const response = await demandApi.getModelCoeffs({
        nodeId: rootGetters['demand/tree/activeNodeId']
      });

      if (response?.data) {
        commit(types.SET_COEFF_INFO, response.data);
      }
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchModelTab' });
    }
  },
  async postModelTab({ dispatch, rootGetters }, { type, item, val }) {
    try {
      const value = val === '' ? undefined : val;

      await demandApi.postModelCoeffs(
        {
          nodeId: rootGetters['demand/tree/activeNodeId'],
          coeffType: type
        },
        {
          id: item?.id,
          ovValue: value
        }
      );
    } catch (e) {
      dispatch('fetchModelTab');
      this.dispatch('user/logout', { e, from: 'postModelTab' });
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
