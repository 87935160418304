export default function(data, isPortion = false) {
  if (!data) {
    return null;
  }

  if (!isPortion && !data.paths.length) {
    return [{
      // todo add to locale
      text: 'No data available...'
    }];
  }

  return data.paths;
}