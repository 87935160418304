<template>
  <div class="sl-node-path__wrapper">
    <div
      v-for="(pathItem, pathIndex) in visiblePaths"
      :key="pathItem"
      class="sl-node-path"
    >
      <div
        v-tooltip="getPathItemTooltip(pathIndex)"
        class="sl-node-path__text"
        :class="{
          'sl-node-path__text--highlighted': isTargetHighlighted(pathIndex)
        }"
      >
        {{ getPathItemLabel(pathIndex) }}
      </div>
      <icon
        v-if="pathIndex < path.length - 1"
        data="@icons/chevron_right.svg"
        class="size-12 fill-off color-grey-60"
      />
    </div>
  </div>
</template>

<script>
import { getTooltip } from '@/helpers/shared/tooltip';

export default {
  name: 'SlNodePath',
  props: {
    path: {
      type: Array,
      default: () => []
    },
    highlightTarget: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      lastVisiblePathsCount: 3,
      minTooltipLength: 40
    };
  },
  computed: {
    visiblePaths() {
      return this.path.filter((_, pathIndex) => !this.isHiddenPath(pathIndex));
    }
  },
  methods: {
    isHiddenPath(index) {
      // hidden more than first and less than last 3 items
      return index > 0 && index <= this.path.length - 1 - this.lastVisiblePathsCount;
    },
    isTargetHighlighted(index) {
      if (!this.highlightTarget) {
        return false;
      }

      return index === this.path.length - 1;
    },
    getPathItemTooltip(index) {
      if (this.isHiddenPath(index)) {
        const hiddenItems = this.path
          .filter((_, pathIndex) => this.isHiddenPath(pathIndex))
          .join(' > ');

        return getTooltip(hiddenItems);
      }

      const text = this.path[index];

      return getTooltip({
        content: text,
        disabled: text.length < this.minTooltipLength
      });
    },
    getPathItemLabel(index) {
      return this.isHiddenPath(index)
        ? '...'
        : this.path[index];
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/ui-kit/sl-node-path";
</style>