// See https://github.com/shentao/vue-multiselect/issues/723#issuecomment-596988587
// and https://gist.github.com/sigma207/b9300fe12a996c07b2389ee03c1464ed

export const SelectOverflow = {
  inserted: (el, binding, vnode) => {
    const { appendToBody, optionSelector, openDirection } = binding?.value ?? {};

    if (!appendToBody) {
      return;
    }

    let isSelectOpen;
    let originalPosition;
    let originalZIndex;
    let originalTop;
    let originalBottom;
    let originalLeft;
    let originalMinWidth;
    let originalTransition;

    const instance = vnode.componentInstance;
    const optionsNode = el.querySelector('.multiselect__content-wrapper');
    const selectScrollSelectors = ['multiselect__content-wrapper', 'multiselect__tags'];

    if (!optionsNode) {
      return;
    }

    const listener = (e) => {
      const isNoSelect = !selectScrollSelectors.some(selector => e.target.classList.contains(selector));

      if (isSelectOpen && isNoSelect) {
        instance.deactivate();
      }
    };

    const setOptionsNodePosition = (direction = 'auto', rect, optionsNode) => {
      const setPosition = (value, prop = 'top', offset = 4) => {
        optionsNode.style[prop] = `${Math.round(value) + offset}px`;

        if (prop === 'bottom') {
          optionsNode.style.top = 'auto';
        }
      };
      const { x, y, height } = rect;

      setPosition(x, 'left', 0);

      if (direction === 'auto') {
        if (y + height + optionsNode.clientHeight > window.innerHeight) {
          return setPosition(window.innerHeight - y, 'bottom');
        }

        return setPosition(y + height);
      }

      if (direction === 'top') {
        return setPosition(window.innerHeight - y, 'bottom');
      }

      setPosition(y + height);
    };

    instance.$watch('isOpen', (isOpen) => {
      isSelectOpen = isOpen;

      if (isOpen) {
        if (appendToBody) {
          optionSelector && optionsNode.classList.add(optionSelector);
          // insert to #app to avoid vuetify modal scroll issue
          // prepend to remove root(first) node on unbind
          document.querySelector('#app').prepend(optionsNode);
        }

        const rect = el.getBoundingClientRect();

        originalPosition = optionsNode.style.position;
        originalTop = optionsNode.style.top;
        originalBottom = optionsNode.style.bottom;
        originalLeft = optionsNode.style.left;
        originalZIndex = optionsNode.style.zIndex;
        originalMinWidth = optionsNode.style.minWidth;
        originalTransition = optionsNode.style.transition;

        optionsNode.style.position = 'fixed';

        setOptionsNodePosition(openDirection, rect, optionsNode);

        optionsNode.style.zIndex = '9999'; // v-popper has 10k
        optionsNode.style.minWidth = `${rect.width}px`;
        optionsNode.style.width = 'fit-content';
        optionsNode.style.transition = 'top 0s';

        window.addEventListener('scroll', listener, true);
      } else {
        window.removeEventListener('scroll', listener, true);

        setTimeout(() => {
          optionSelector && optionsNode.classList.remove(optionSelector);
          el.append(optionsNode);

          optionsNode.style.position = originalPosition;
          optionsNode.style.top = originalTop;
          optionsNode.style.bottom = originalBottom;
          optionsNode.style.left = originalLeft;
          optionsNode.style.zIndex = originalZIndex;
          optionsNode.style.minWidth = originalMinWidth;
          optionsNode.style.transition = originalTransition;
        }, 100);
      }
    });
  },
  unbind: (el, binding) => {
    const { appendToBody } = binding?.value ?? {};

    if (!appendToBody) {
      return;
    }

    const optionsNode = document.querySelector('#app > .multiselect__content-wrapper');

    optionsNode && optionsNode.remove();
  }
};
