import axios from '@/plugins/axios';

export default {
  getInventoryTabParams(params) {
    return axios.get('/inv_param', {
      params
    });
  },
  getTreeNodeKpi(params) {
    return axios.get('/tree_node/kpi', {
      params
    });
  },
  getModelCoeffs(params) {
    return axios.get('/models_coeffs', {
      params
    });
  },
  postModelCoeffs(params, body) {
    return axios.post('/models_coeffs', body, { params });
  },
  getTable(params) {
    return axios.get('/table', { params });
  },
  getForecastSettings(params) {
    return axios.get('/table/forecast_settings', { params });
  },
  editForecastVersions(body) {
    return axios.post('/table/custom_rows', body);
  },
  fetchForecastVersions(params) {
    return axios.get('/table/custom_rows', { params });
  },
  postTable(params, body) {
    return axios.post('/table', body, { params });
  },
  postOverridePrediction(params, body) {
    return axios.post('/table/override/custom_row', body, { params });
  },
  postVisibleRows(params) {
    return axios.post('/table/rows', null, { params });
  },
  postApplyCell(params, body) {
    return axios.post('/table/override/apply', body, { params });
  },
  clearNodeOverrides(params) {
    return axios.post('/table/clear_overrides', null, { params });
  },
  clearNodeSettings(params) {
    return axios.post('/table/mass_clear', null, { params });
  },
  updateCellNote(body) {
    return axios.post('/table/note', body);
  },
  copyFromStatisticalForecast(params, body) {
    return axios.post('/table/override/copy_from_statistical', body, { params });
  }
};
