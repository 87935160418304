import { accessStateKeys, featureAccessKeys } from '@/config/shared/access.config';
import { mainRoutes } from '@/config/router/router.config';
import { aggregateFeatureAccess, aggregateRouteAccess } from '@/helpers/access';

const types = {
  SET_ROUTE_ACCESS: 'SET_ROUTE_ACCESS',
  SET_FEATURE_ACCESS: 'SET_FEATURE_ACCESS',
  RESET_STATE: 'RESET_STATE'
};

const defaultState = () => ({
  route_access: {},
  feature_access: {}
});

const state = defaultState();

const getters = {
  availableProjectTabs: (state) => {
    return mainRoutes.reduce((tabsList, tab) => {
      if (state.route_access[tab] && state.route_access[tab] !== accessStateKeys.NONE) {
        tabsList.push(tab);
      }

      return tabsList;
    }, []);
  },
  featureAccess: (state, _, rootState, rootGetters) => ({
    // server reserved
    ...state.feature_access,
    // extended from other modules
    [featureAccessKeys.demandSettings]: rootState.demand.settings.settings?.editable,
    [featureAccessKeys.demandNode]: rootGetters['demand/table/editableNode'],
    // in case of key absence grant access to pages
    [featureAccessKeys.planningItems]: rootState.user.user_stats?.hasAccessToAnyPlanningItem ?? true
  }),
  isRouteAvailable: (state) => (tab) => !state.route_access[tab] || state.route_access[tab] !== accessStateKeys.NONE,
  isRouteEditable: (state) => (tab) => state.route_access[tab] === accessStateKeys.EDITABLE
};

const mutations = {
  [types.SET_ROUTE_ACCESS](state, value) {
    state.route_access = value;
  },
  [types.SET_FEATURE_ACCESS](state, value) {
    state.feature_access = value;
  },
  [types.RESET_STATE](state) {
    const initialState = defaultState();

    Object.keys(state).forEach(key => {
      state[key] = initialState[key];
    });
  }
};

const actions = {
  setUserAccess({ commit }, responses) {
    try {
      if (responses.some((response) => !response.value)) {
        throw 'Cannot set user access.';
      }

      commit(types.SET_ROUTE_ACCESS, aggregateRouteAccess(responses));
      commit(types.SET_FEATURE_ACCESS, aggregateFeatureAccess(responses));
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'setUserAccess' });
      throw e;
    }
  },
  resetState({ commit }) {
    commit(types.RESET_STATE);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
