import Vue from 'vue';
import i18n from '@/plugins/vue-i18n';
import subscription from '@/api/subscription';

//const types = {};

const state = () => {
};

const getters = {};

const mutations = {};

const actions = {
  async setUserLimit(_, value) {
    try {
      await subscription.setUserLimit({
        val: value
      });

      Vue.notify({
        type: 'success',
        title: i18n.t('Web.LicenseDetails.IncreaseSuccessfuly'),
        text: i18n.t('Web.LicenseDetails.IncreaseWarning'),
        duration: 7000
      });
    } catch (e) {
      const text =  e?.message || '';

      if (text) {
        Vue.notify({
          type: 'error',
          text,
          duration: 3000
        });
      }

      this.dispatch('user/logout', { e, from: 'subscription/setUserLimit' });
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
