// required attrs & listeners:
//     <div
//       ref="scrollContainer"
//       @scroll.passive="updateScroll"
//     >
export const scroll = {
  data() {
    return {
      scrollObserving: false,
      containerWidth: null,
      containerHeight: null,
      hasHorizontalScrollbar: false,
      hasVerticalScrollbar: false,
      scrolledToTop: false,
      scrolledToRight: false,
      scrolledToBottom: false,
      scrolledToLeft: false
    };
  },
  mounted() {
    this.initObserver();
  },
  methods: {
    getScrollContainer() {
      return this.$refs.scrollContainer?.$el ?? this.$refs.scrollContainer;
    },
    initObserver() {
      const scrollContainer = this.getScrollContainer();

      if (!scrollContainer) {
        return;
      }

      const scrollContainerObserver = new ResizeObserver(this.updateScroll);

      if (scrollContainerObserver) {
        scrollContainerObserver.observe(scrollContainer);
        this.$once('hook:destroyed', () => scrollContainerObserver.disconnect());
      }

      const wrapObserver = new ResizeObserver(this.calcDimensions);

      if (wrapObserver) {
        wrapObserver.observe(this.$el);
        this.$once('hook:destroyed', () => wrapObserver.disconnect());
      }

      this.scrollObserving = true;
    },
    async calcDimensions() {
      this.containerWidth = null;
      this.containerHeight = null;

      await this.$nextTick();

      this.containerWidth = `${this.$el.clientWidth}px`;
      this.containerHeight = `${this.$el.clientHeight}px`;
    },
    updateScroll() {
      const scrollContainer = this.getScrollContainer();

      if (!scrollContainer) {
        return;
      }

      const {
        clientWidth,
        clientHeight,
        scrollWidth,
        scrollHeight,
        offsetWidth,
        offsetHeight,
        scrollTop,
        scrollLeft
      } = scrollContainer;
      const scrolledFromLeft = offsetWidth + scrollLeft;
      const scrolledFromTop = offsetHeight + scrollTop;

      this.hasHorizontalScrollbar = clientWidth < scrollWidth;
      this.hasVerticalScrollbar = clientHeight < scrollHeight;
      this.scrolledToRight = scrolledFromLeft >= scrollWidth;
      this.scrolledToBottom = (scrollHeight - scrolledFromTop) < 1;
      this.scrolledToTop = scrollTop === 0;
      this.scrolledToLeft = scrollLeft === 0;
    }
  }
};
