import axios from 'axios';
import Vue from 'vue';
import { HttpError } from './HttpError';
import { removeIdempotencyKey } from './idempotentRequests';
import { updateResponse } from './updateResponse';
import { globalHandlers } from './globalHandlers';

function getErrorBody(error) {
  return {
    ...(error?.response?.data || error || {}),
    isAxios: true
  };
}

class ErrorHandlerRegistry {
  handlers = new Map();
  parent = null;

  constructor(parent, input) {
    if (parent !== null) {
      this.parent = parent;
    }

    if (typeof input !== 'undefined') {
      this.registerMany(input);
    }
  }

  register(key, handler) {
    this.handlers.set(key, handler);

    return this;
  }

  unregister(key) {
    this.handlers.delete(key);

    return this;
  }

  find(seek) {
    const handler = this.handlers.get(seek);

    return handler || this.parent?.find(seek);
  }

  registerMany(input) {
    for (const [key, value] of Object.entries(input)) {
      this.register(key, value);
    }

    return this;
  }

  handleError(seek, error) {
    if (Array.isArray(seek)) {
      return this.handleArrayError(seek, error);
    }

    const handler = this.find(String(seek));

    return this.handleIndividualError(handler, error);
  }

  handleArrayError(seekArray, error) {
    return seekArray.some((seek) => {
      if (seek !== undefined) {
        return this.handleError(String(seek), error);
      }
    });
  }

  handleIndividualError(handler, error) {
    if (!handler) {
      return false;
    } else if (typeof handler === 'string' || this.isErrorHandlerObject(handler)) {
      this.handleErrorObject(error, handler);

      return true;
    }

    return false;
  }

  isErrorHandlerObject(value) {
    if (typeof value === 'object' && value) {
      return ['text', 'title', 'after', 'before', 'notify'].some((k) => k in value);
    }

    return false;
  }

  handleErrorObject(error, options = {}) {
    const errorBody = getErrorBody(error);

    options.before?.(error, options);

    if (!options.silent) {
      Vue.notify({
        title: options.title || '',
        text: options.text(errorBody) ?? 'Unknown Error',
        type: 'error'
      });
    }
  }

  async responseErrorHandler(error, direct) {
    this.validateError(error);

    await this.processAxiosError(error, direct);
  }

  validateError(error) {
    if (error === null) {
      throw new HttpError('Error is null!');
    }

    if (axios.isCancel(error)) {
      throw new HttpError('Cancelled request');
    }
  }

  async processAxiosError(error, direct) {
    await updateResponse(error?.response, error?.config?.method);

    removeIdempotencyKey(error?.config);

    if (axios.isAxiosError(error)) {
      await this.handleAxiosError(error, direct);
    } else if (error.code) { // for dealWith flow
      const seekers = this.prepareSeekers(error.response?.data, error, error?.response);

      this.handleError(seekers, error);
    } else {
      throw error;
    }
  }

  async handleAxiosError(error, direct) {
    const { response, config } = error || {};
    const data = response?.data;

    if (!direct && config?.raw) {
      throw getErrorBody(error);
    }

    const seekers = this.prepareSeekers(data, error, response);
    const result = this.handleError(seekers, error);

    if (!result) {
      throw getErrorBody(error);
    }
  }

  prepareSeekers(data, error, response) {
    return [
      data?.data?.code,          // for common server error
      error?.toJSON?.().message, // for web-server down
      data?.code,
      error?.code,
      error?.name,
      String(data?.status),
      String(response?.status),
      '*'                        // any server error
    ];
  }
}

export const errorRegistry = new ErrorHandlerRegistry(null, globalHandlers);

export function registerError(key, handler) {
  errorRegistry.register(key, handler);
}

export function dealWith(solutions, ignoreGlobal) {
  let global = null;

  if (ignoreGlobal === false) {
    global = errorRegistry;
  }

  const localHandlers = new ErrorHandlerRegistry(global, solutions);

  return (error) => localHandlers.responseErrorHandler(error, true);
}

export function createMultipleHandlers(seekers, getOptions) {
  return seekers.reduce((acc, seeker) => {
    acc[seeker] = getOptions(seeker);

    return acc;
  }, {});
}
