import { routeNames } from '@/config/router/router.config';

export const namespaceByRoute = {
  [routeNames.INVENTORY]: 'sharedInventoryReport',
  [routeNames.INVENTORY_PLANNED_ORDERS]: 'sharedInventoryReport',
  [routeNames.INVENTORY_IN_TRANSITION]: 'sharedInventoryReport',
  [routeNames.COLLECTIONS]: 'sharedInventoryReport',
  [routeNames.CONSTRAINED_PLAN]: 'sharedInventoryReport',
  [routeNames.INTERSTORE]: 'sharedInterstoreReport',
  [routeNames.INTERSTORE_PLANNED_ORDERS]: 'sharedInterstoreReport',
  [routeNames.INTERSTORE_IN_TRANSITION]: 'sharedInterstoreReport',
  [routeNames.REPORTS]: 'sharedForecastReport'
};

export const PAGE_SIZE = 100;
export const DEFAULT_INVENTORY_CELL_INDEX = '18446744073709551615';
export const MAX_PREDICATES_AMOUNT = 12;
export const FILTER_BY_ABC_CLASS = 'ABC_CLASS';
export const MAX_PINNED_COLUMNS = 10000;
export const HEADER_KEY_SEPARATOR = '~';
export const DISABLED_STYLE = 'disabled';

export const colTypes = {
  ENUM: 'Enum',
  STRING: 'String',
  NUMBER: 'Number',
  DATE: 'Date'
};

export const progressStates = {
  FINISHED: 'Finished',
  PENDING: 'Pending',
  IN_PROGRESS: 'InProgress'
};

export const exportTableKeys = {
  XLSX: 'xlsx-export',
  DB: 'db-export',
  XLSX_IN_TRANS_FORMAT: 'xlsx-export-in-transactions-format'
};

export const editFilterKeys = {
  EDIT: 'edit',
  DUP: 'duplicate'
};

export const filterStates = {
  PRIVATE: 'private',
  PUBLIC: 'public'
};

export const filterStateLabels = (ctx) => ({
  [filterStates.PRIVATE]: ctx.$t('SelectFilter.Private'),
  [filterStates.PUBLIC]: ctx.$t('SelectFilter.Public')
});

export const filterByKeys = {
  COLUMN: 'Column',
  RELATED_BOMs: 'RelatedBoms'
};
