var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"connector-wrap connector-wrap--simple"},[_c('div',{staticClass:"spire-connector__heading"},[_c('div',{staticClass:"spire-connector__heading-col"},[_c('h4',{staticClass:"heading-4-sb"},[_vm._v(" "+_vm._s(_vm.$t('Web.BaseConnectors.Form.DbToImport'))+" ")]),_c('p',{staticClass:"body-1 grey-70"},[_vm._v(" "+_vm._s(_vm.$t('Web.BaseConnectors.Form.DbToImportDescr'))+" ")])]),_c('SlButton',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.getTooltip(_vm.$t('Web.Table.Refresh'))),expression:"getTooltip($t('Web.Table.Refresh'))"}],attrs:{"variant":"secondary","color":"grey","size":"sm","icon":""},on:{"click":_vm.handleDatabasesRefresh}},[_c('icon',{staticClass:"fill-off size-16 color-grey-80",class:{
          'spin-reverse': _vm.isSpinning
        },attrs:{"data":require("@icons/refresh.svg")}})],1)],1),_vm._l((_vm.databases),function(database){return _c('SlAccordion',{key:database._name,attrs:{"disabled":!_vm.databases.length},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
        var isOpen = ref.isOpen;
        var toggle = ref.toggle;
return [_c('div',{staticClass:"checkbox-item__trigger"},[_c('SlCheckbox',{attrs:{"value":_vm.isAllSelected(database),"indeterminate":_vm.isIndeterminate(database)},on:{"input":function (checked) { return _vm.selectAll({ database: database, checked: checked }); }}}),_c('div',{staticClass:"checkbox-item__content",on:{"click":toggle}},[_c('div',{staticClass:"body-1 grey-90"},[_vm._v(" "+_vm._s(database._name)+" ")]),_c('icon',{staticClass:"fill-off size-20",class:{
              'rotate-180': isOpen
            },attrs:{"data":require("@icons/chevron_down.svg")}})],1)],1)]}}],null,true)},_vm._l((database.location),function(location){return _c('div',{key:location.name,staticClass:"checkbox-item"},[_c('SlCheckbox',{attrs:{"value":location.checked,"label":location.name},on:{"input":function (checked) { return _vm.selectItem({
          location: location,
          checked: checked
        }); }}})],1)}),0)}),_c('Periods',{attrs:{"type":_vm.type,"is-new":_vm.isNew}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }