<template>
  <div
    class="subpage-wrapper"
    :class="{
      'subpage-wrapper--left-col': hasLeftCol,
      'subpage-wrapper--right-col': hasRightCol,
      'subpage-wrapper--both-cols': hasBothCols,
      'subpage-wrapper--relative': relative,
      'subpage-wrapper--breadcrumbs': $scopedSlots.breadcrumbs,
      'subpage-wrapper--actions': $scopedSlots.actions
    }"
  >
    <slot name="loader" />
    <div
      v-if="hasLeftCol"
      class="subpage-wrapper__left-col"
    >
      <slot name="left-col" />
    </div>
    <div
      class="subpage-wrapper__main"
      :class="`subpage-wrapper__main--${width}`"
    >
      <div
        v-if="hasHeader"
        class="subpage-wrapper__main-header"
      >
        <div
          v-if="$scopedSlots.breadcrumbs"
          class="subpage__breadcrumbs"
        >
          <slot name="breadcrumbs" />
          <div
            v-if="$scopedSlots.filter"
            class="subpage__filter"
          >
            <slot name="filter" />
          </div>
        </div>
        <div
          v-if="$scopedSlots.actions"
          class="subpage__actions"
        >
          <slot name="actions" />
        </div>
      </div>

      <slot />
    </div>
    <div
      v-if="hasRightCol"
      class="subpage-wrapper__right-col"
    >
      <slot name="right-col" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SubPageWrapper',
  props: {
    width: {
      type: String,
      default: 'xl',
      validator: (s) => ['xl', '2xl', '3xl', 'full'].includes(s)
    },
    emptyCols: Boolean,
    relative: Boolean
  },
  computed: {
    hasLeftCol() {
      return this.$scopedSlots['left-col'] || this.emptyCols;
    },
    hasRightCol() {
      return this.$scopedSlots['right-col'] || this.emptyCols;
    },
    hasBothCols() {
      return this.hasLeftCol && this.hasRightCol;
    },
    hasHeader() {
      return this.$scopedSlots.breadcrumbs || this.$scopedSlots.actions;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/components/shared/page/sub-page-wrapper.scss';
</style>
