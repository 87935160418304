<template>
  <div
    class="sl-table-input-wrapper"
    :class="{
      'sl-table-input-wrapper--disabled': disabled || !editableBg,
      'sl-table-input-wrapper--editable-bg': editableBg,
      'sl-table-input-wrapper--number': !isText,
      [`color--${foreground}`]: foreground
    }"
    tabindex="-1"
    @focus="onInputFocus"
  >
    <input
      v-if="isInputVisible"
      ref="inputRef"
      v-model="vModel"
      type="text"
      class="sl-table-input"
      :maxLength="maxLength"
      :disabled="disabled"
      @focus="onInputFocus"
      @keydown.enter.stop="$refs.inputRef.blur()"
      @blur.stop="onSubmit"
    >
    <div
      v-else
      class="sl-table-input-text"
      :class="{
        'sl-table-input-text--labeled': prependLabel
      }"
    >
      <span
        v-if="prependLabel"
        class="sl-table-input-text__label"
      >
        {{ prependLabel }}
      </span>
      {{ inputTextValue }}
    </div>
  </div>
</template>

<script>
import regExp from '@/helpers/utils/regExp';
import { formatNumber } from '@/helpers/format/formatNumber';
import { EMPTY_CACHE_OVERRIDE } from '@/config/shared/magicValues.config';

export default {
  name: 'SlTableInput',
  props: {
    value: {
      type: [Number, String],
      required: false,
      default: ''
    },
    // text will show with focusVisible prop
    textValue: {
      type: [Number, String],
      required: false,
      default: ''
    },
    prependLabel: {
      type: String,
      required: false,
      default: ''
    },
    // used in handlers only
    type: {
      type: String,
      required: false,
      default: 'number',
      validator: (v) => v && ['text', 'number'].includes(v)
    },
    maxLength: {
      type: [Number, String],
      required: false,
      default: 10
    },
    foreground: {
      type: String,
      required: false,
      default: ''
    },
    inputRegexp: {
      type: RegExp,
      required: false,
      default: () => regExp.intInput
    },
    submitRegexp: {
      type: RegExp,
      required: false,
      default: () => regExp.intSubmit
    },
    disabled: Boolean,
    focusVisible: {
      type: Boolean,
      default: true
    },
    editableBg: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isChanged: false,
      isFocused: false,
      prevValue: this.value
    };
  },
  computed: {
    vModel: {
      get() {
        return this.value;
      },
      set(val) {
        if (!this.isChanged) {
          this.isChanged = true;
        }

        if (this.isText) {
          return this.$emit('input', val.trim());
        }

        if (val && !this.inputRegexp.test(val)) {
          this.$refs.inputRef.value = this.prevValue;

          return;
        }

        const parsedValue = this.getParsedValue(val);

        this.$emit('input', parsedValue);
        this.prevValue = parsedValue;
      }
    },
    isInputVisible() {
      return !this.focusVisible || this.isFocused;
    },
    isValid() {
      if (this.isText) {
        return this.isChanged;
      }

      return this.isChanged && this.submitRegexp.test(this.$refs.inputRef.value);
    },
    isText() {
      return this.type === 'text';
    },
    inputTextValue() {
      if (this.isText) {
        return this.textValue || this.vModel;
      }

      return this.textValue || formatNumber(this.vModel);
    }
  },
  methods: {
    getParsedValue(value) {
      if (this.isText) {
        return value.trim() || EMPTY_CACHE_OVERRIDE;
      }

      return value ? +value : EMPTY_CACHE_OVERRIDE;
    },
    onInputFocus() {
      if (this.disabled) {
        return;
      }

      this.isFocused = true;

      this.$nextTick(() => this.$refs.inputRef.select());
    },
    onSubmit() {
      this.isFocused = false;

      if (!this.isValid) {
        return;
      }

      const value = this.$refs.inputRef.value.trim();

      this.$emit('submit', this.getParsedValue(value));
      this.isChanged = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/ui-kit/sl-table/inputs/sl-table-input.scss";
</style>
