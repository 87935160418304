<template>
  <label
    tabindex="0"
    class="sl-checkbox-wrapper"
    :class="{
      'sl-checkbox-wrapper--checked': isChecked,
      'sl-checkbox-wrapper--disabled': disabled,
      'sl-checkbox-wrapper--toggle': toggle,
      'sl-checkbox-wrapper--inactive': inactive
    }"
  >
    <input
      v-show="false"
      :id="id"
      :data-test-id="dataTestId"
      class="sl-checkbox"
      type="checkbox"
      :name="name"
      :checked="checkedValue"
      :disabled="disabled"
      @change="onChange"
    >
    <icon
      :data="icon"
      class="sl-checkbox__check fill-off size-16 color-white-stroke"
    />
    <span
      v-if="label"
      class="sl-checkbox__label body-1-md grey-80"
    >
      {{ label }}
    </span>
    <slot />
  </label>
</template>

<script>

export default {
  name: 'SlCheckbox',
  props: {
    id: {
      type: [String, undefined],
      default: undefined
    },
    value: {
      type: [Boolean, String, Number],
      default: false
    },
    name: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    selected: {
      type: [Boolean, String, Number],
      default: true
    },
    notSelected: {
      type: [Boolean, String, Number],
      default: false
    },
    dataTestId: {
      type: [String, null],
      default: null
    },
    disabled: Boolean,
    indeterminate: Boolean,
    toggle: Boolean,
    inactive: Boolean
  },
  computed: {
    vModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
        this.$emit('change', value);
      }
    },
    isChecked() {
      return this.vModel === this.selected;
    },
    checkedValue() {
      return this.isChecked ? 'checked' : '';
    },
    icon() {
      return require(`@icons/${this.indeterminate ? 'minus' : 'check'}.svg`);
    }
  },
  methods: {
    onChange(event) {
      this.vModel = event.target.checked ? this.selected : this.notSelected;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/components/ui-kit/sl-checkbox';
</style>
