var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"connector-wrap connector-wrap--simple"},[_c('div',{staticClass:"connector-content-block__row"},[_c('SlValidate',{attrs:{"vid":"username","mode":"eager","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('DbInsideSourceWidget.Labels.lbUsername'),"is-invalid":invalid},model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}})]}}])})],1),_c('div',{staticClass:"connector-content-block__row"},[_c('SlValidate',{key:"password",attrs:{"vid":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlPasswordInput',{attrs:{"label":_vm.$t('DbImportDialog.Ui.lbPassword'),"is-invalid":invalid},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}])})],1),_c('ConnectorContentBlock',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('Web.BaseConnectors.Form.Endpoints'))+" ")]},proxy:true}])},[[_c('div',{staticClass:"connector-content-block__row"},[_c('SlValidate',{attrs:{"vid":"transactional","mode":"eager","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('DbImportDialog.Ui.tabMainQuery:at'),"is-invalid":invalid},model:{value:(_vm.transactional),callback:function ($$v) {_vm.transactional=$$v},expression:"transactional"}})]}}])})],1),_c('div',{staticClass:"connector-content-block__row"},[_c('SlValidate',{attrs:{"vid":"itemInfo","mode":"eager","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('DbImportDialog.Ui.tabItemQry:at'),"is-invalid":invalid},model:{value:(_vm.itemInfo),callback:function ($$v) {_vm.itemInfo=$$v},expression:"itemInfo"}})]}}])})],1),_c('div',{staticClass:"connector-content-block__row"},[_c('SlValidate',{attrs:{"vid":"toShip","mode":"eager","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('File.Backorders'),"is-invalid":invalid},model:{value:(_vm.toShip),callback:function ($$v) {_vm.toShip=$$v},expression:"toShip"}})]}}])})],1),_c('div',{staticClass:"connector-content-block__row"},[_c('SlValidate',{attrs:{"vid":"toReceive","mode":"eager","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('File.OnOrder'),"is-invalid":invalid},model:{value:(_vm.toReceive),callback:function ($$v) {_vm.toReceive=$$v},expression:"toReceive"}})]}}])})],1),_c('div',{staticClass:"connector-content-block__row"},[_c('SlValidate',{attrs:{"vid":"bom","mode":"eager","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('EntireBom.Ui.:Caption'),"is-invalid":invalid},model:{value:(_vm.bom),callback:function ($$v) {_vm.bom=$$v},expression:"bom"}})]}}])})],1),_c('div',{staticClass:"connector-content-block__row"},[_c('SlValidate',{attrs:{"vid":"bom","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('Web.BaseConnectors.Form.ExportPO'),"is-invalid":invalid},model:{value:(_vm.exportPO),callback:function ($$v) {_vm.exportPO=$$v},expression:"exportPO"}})]}}])})],1),_c('div',{staticClass:"connector-content-block__row"},[_c('SlValidate',{attrs:{"vid":"bom","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('Web.BaseConnectors.Form.ExportTO'),"is-invalid":invalid},model:{value:(_vm.exportTO),callback:function ($$v) {_vm.exportTO=$$v},expression:"exportTO"}})]}}])})],1),_c('div',{staticClass:"connector-content-block__row"},[_c('SlValidate',{attrs:{"vid":"bom","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('Web.BaseConnectors.Form.ExportMO'),"is-invalid":invalid},model:{value:(_vm.exportMO),callback:function ($$v) {_vm.exportMO=$$v},expression:"exportMO"}})]}}])})],1),_c('div',{staticClass:"connector-content-block__row"},[_c('SlValidate',{attrs:{"vid":"pageLimit","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('Web.BaseConnectors.Form.MaxPageSize'),"is-invalid":invalid},model:{value:(_vm.pageLimit),callback:function ($$v) {_vm.pageLimit=$$v},expression:"pageLimit"}})]}}])})],1)]],2),_c('Periods',{attrs:{"type":_vm.type,"is-new":_vm.isNew}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }