import i18n from '@/plugins/vue-i18n';

export const headerTitlesMap = () => ({
  [colKeys.actual.headerClass]: {
    requestKey: 'ActualSales'
  },
  [colKeys.lowerEchelonDemand.headerClass]: {
    requestKey: 'LowerEchelonDemand'
  },
  [colKeys.materialConsumption.headerClass]: {
    requestKey: 'MaterialConsumption'
  },
  [colKeys.statisticalForecast.headerClass]: {
    requestKey: 'Forecast'
  },
  [colKeys.customRowOverrides.headerClass]: {
    locale: i18n.tc('Main.Cols.IvAdjust'),
    requestKey: 'Overrides'
  },
  [colKeys.AvgPrice.headerClass]: {
    requestKey: 'AvgPrice'
  },
  [colKeys.Revenue.headerClass]: {
    requestKey: 'Revenue'
  },
  [colKeys.OnHand.headerClass]: {
    requestKey: 'OnHand'
  },
  [colKeys.LostDays.headerClass]: {
    requestKey: 'LostDays'
  },
  [colKeys.ServiceLevel.headerClass]: {
    requestKey: 'ServiceLevel'
  },
  [colKeys.remainder.headerClass]: {
    requestKey: 'ProjectedInventory'
  },
  [colKeys.projectedDaysOfSupply.headerClass]: {
    requestKey: 'DaysOfSupply'
  },
  [colKeys.toOrder.headerClass]: {
    requestKey: 'OrderPlan'
  },
  [colKeys.backorder.headerClass]: {
    requestKey: 'Backorder'
  },
  [colKeys.customOutlier.headerClass]: {
    requestKey: 'Outlier'
  },
  [colKeys.UpperConfLimit.headerClass]: {
    requestKey: 'UpperConfLimit'
  },
  [colKeys.LowerConfLimit.headerClass]: {
    requestKey: 'LowerConfLimit'
  },
  [colKeys.allowOrder.headerClass]: {
    requestKey: 'AllowOrder'
  },
  [colKeys.onorder.headerClass]: {
    requestKey: 'OnOrder'
  },
  [colKeys.profit.headerClass]: {
    requestKey: 'Profit'
  }
});

export const colKeys = {
  actual: {
    xmlKey: 'actual',
    requestKey: 'ActualSales',
    headerClass: 'ActualSales'
  },
  lowerEchelonDemand: {
    xmlKey: 'lowerEchelonDemand',
    requestKey: 'lowerEchelonDemand',
    headerClass: 'LowerEchelonDemand'
  },
  materialConsumption: {
    xmlKey: 'materialConsumption',
    requestKey: 'materialConsumption',
    headerClass: 'MaterialConsumption'
  },
  statisticalForecast: {
    xmlKey: 'statisticalForecast',
    requestKey: '',
    headerClass: 'Forecast'
  },
  customRowOverrides: {
    xmlKey: 'customRowOverrides',
    requestKey: 'Overrides',
    headerClass: 'Overrides'
  },
  nonzeroSales: {
    xmlKey: 'nonzeroSales',
    requestKey: 'Overrides',
    headerClass: 'Overrides'
  },
  UpperConfLimit: {
    xmlKey: 'upperConfLimit',
    requestKey: 'upperConfLimit',
    headerClass: 'UpperConfLimit'
  },
  LowerConfLimit: {
    xmlKey: 'lowerConfLimit',
    requestKey: 'lowerConfLimit',
    headerClass: 'LowerConfLimit'
  },
  AvgPrice: {
    xmlKey: 'AvgPrice',
    requestKey: 'AvgPrice',
    headerClass: 'AvgPrice'
  },
  Revenue: {
    xmlKey: 'Revenue',
    requestKey: 'Revenue',
    headerClass: 'Revenue'
  },
  predictedRevenue: {
    xmlKey: 'predictedRevenue',
    requestKey: 'Revenue',
    headerClass: ''
  },
  OnHand: {
    xmlKey: 'OnHand',
    requestKey: 'OnHand',
    headerClass: 'OnHand'
  },
  LostDays: {
    xmlKey: 'LostDays',
    requestKey: 'LostDays',
    headerClass: 'LostDays'
  },
  ServiceLevel: {
    xmlKey: 'ServiceLevel',
    requestKey: 'ServiceLevel',
    headerClass: 'ServiceLevel'
  },
  remainder: {
    xmlKey: 'remainder',
    requestKey: 'remainder',
    headerClass: 'ProjectedInventory'
  },
  projectedDaysOfSupply: {
    xmlKey: 'projectedDaysOfSupply',
    requestKey: 'DaysOfSupply',
    headerClass: 'DaysOfSupply'
  },
  toOrder: {
    xmlKey: 'toOrder',
    requestKey: 'toOrder',
    headerClass: 'OrderPlan'
  },
  allowOrder: {
    xmlKey: 'allowOrder',
    requestKey: 'AllowOrder',
    headerClass: 'AllowOrder'
  },
  onorder: {
    xmlKey: 'onorder',
    requestKey: '',
    headerClass: 'OnOrder'
  },
  backorder: {
    xmlKey: 'backorder',
    requestKey: '',
    headerClass: 'Backorder'
  },
  customOutlier: {
    xmlKey: 'customOutlier',
    requestKey: 'Outlier',
    headerClass: 'Outlier'
  },
  profit: {
    xmlKey: 'profit',
    requestKey: '',
    headerClass: 'Profit'
  }
};

export const inputPatterns = {
  FORMULA: 'formula',
  NUMBER: 'number'
};

export const pointTypes = {
  NONE: 'NONE',
  KNOWN: 'KNOWN',
  PREDICT: 'PREDICT'
};

export const forecastVersionAccessTypes = {
  ALL: 'everyone',
  NONE: 'none',
  SOME: 'specificUsers'
};

export const forecastVersionsMagicIds = {
  STATISTICAL: 'statistical',
  FINAL: 'final',
  CUSTOM: 'custom'
};

export const forecastVersionTypes = {
  FORECAST_VERSION: 'forecastVersion',
  CUSTOM_FIELD: 'customField'
};

export const forecastVersionTypeOptions = [
  {
    label: i18n.tc('Web.CustomFields.ForecastVersion'),
    value: forecastVersionTypes.FORECAST_VERSION
  },
  {
    label: i18n.tc('Web.CustomFields.CustomField'),
    value: forecastVersionTypes.CUSTOM_FIELD
  }
];
