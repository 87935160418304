import moment from 'moment';
import { stringToBoolean, stringToNumber } from '@/helpers/format/workWithNums';
import { millisecondsToTime, dateFormats, is24h } from '@/helpers/date/date';
import { autoUpdateTypes } from '@/config/shared/settings.config';

const parseAfterXml = (data, ignoreKeys) => {
  if (!data) {
    return null;
  }

  return Object.keys(data).reduce((acc, key) => {
    if (!ignoreKeys.includes(key)) {
      acc[key] = stringToNumber(data[key]._val);
    } else {
      acc[key] = data[key]._val;
    }

    return acc;
  }, {});
};

const convertUTCTimeToLocal = (hour, minute) => {
  return moment.utc({
    hour,
    minute
  }).local().format(is24h() ? dateFormats.DEFAULT_TIME : dateFormats.SHORT_TIME);
};

const convertAutomation = (data) => {
  const [hour, minute] = data?.time?.time.split(':');

  return {
    enable: stringToBoolean(data?.enable?._val),
    type: data?.type?._val,
    dailyModel: data?.type?._val === autoUpdateTypes.DAILY
      ? convertUTCTimeToLocal(hour, minute)
      : is24h() ? '06:00' : '06:00 AM',
    everyModel: data?.type?._val === autoUpdateTypes.BY_HOURS
      ? millisecondsToTime(+data?.period?._val * 1000)
      : '01:00'
  };
};

export default function(response) {
  const data = response.settings;
  const inventory = parseAfterXml(data?.inventory, [
    'demandView',
    'orderStrategy',
    'enableFutureSales'
  ]);
  const distrib = parseAfterXml(data?.distrib, ['orderStrategy']);

  return {
    ...data,
    calculationBase: data.calculationBase?._val,
    timezone: stringToNumber(data.timezone?._val),
    allowNegativeSlope: stringToBoolean(data.allowNegativeSlope?._val),
    wantShallowUpdate: stringToBoolean(data.wantShallowUpdate?._val),
    wantKeepCoeffs: stringToBoolean(data.wantKeepCoeffs?._val),
    wantKeepApprovals: stringToBoolean(data.wantKeepApprovals?._val),
    containerGrouping: stringToBoolean(data.containerGrouping?._val),
    containerWantVolume: stringToBoolean(data.containerWantVolume?._val),
    containerWantWeight: stringToBoolean(data.containerWantWeight?._val),
    containerWantQty: stringToBoolean(data.containerWantQty?._val),
    containerWantCost: stringToBoolean(data.containerWantCost?._val),
    containerVolume: stringToNumber(data.containerVolume?._val),
    containerWeight: stringToNumber(data.containerWeight?._val),
    containerQty: stringToNumber(data.containerQty?._val),
    containerCost: stringToNumber(data.containerCost?._val),
    horizon: stringToNumber(data.horizon?._val),
    seasonalTreshold: stringToNumber(data.seasonalTreshold?._val),
    elasticityThreshold: stringToNumber(data.elasticityThreshold?._val),
    massTopDown: data.massTopDown?._val,
    massTopDownItems: response['top-down'].name,
    upperConfLevel: (stringToNumber(data.upperConfLevel?._val) * 100).toFixed(1), // %
    lowerConfLevel: (stringToNumber(data.lowerConfLevel?._val) * 100).toFixed(1), // %
    enableInvOptim: stringToBoolean(data.enableInvOptim?._val),
    transferLeadtime: stringToNumber(data.transferLeadtime?._val),
    wantAllowance: stringToBoolean(data.wantAllowance?._val),
    useInTransition: stringToBoolean(data.useInTransition?._val),
    nActual: stringToNumber(data.nActual?._val),
    orderCycleUnit: data.orderCycleUnit?._val,
    preventOvertakePeriod: stringToNumber(data.preventOvertakePeriod?._val),
    avgSalesPricePeriod: stringToNumber(data.avgSalesPricePeriod?._val),
    costOfCapital: stringToNumber(data.costOfCapital?._val),
    supplyUnit: data.supplyUnit?._val,
    accuracyMeasure: data.accuracyMeasure?._val,
    aggregationStart: stringToNumber(data.aggregationStart?._val),
    safetyShelfType: data.safetyShelfType?._val,
    stockoutDaysAggregation: data.stockoutDaysAggregation?._val,
    abc: parseAfterXml(data?.abc, ['func', 'type']),
    xyz: parseAfterXml(data?.xyz, ['func', 'type']),
    currency: data.currency?._val,
    autoUpdate: convertAutomation(data?.autoUpdate),
    autoUpdateConstrainedOrderingPlan: convertAutomation(data?.autoUpdateConstrainedOrderingPlan),
    autoExport: {
      ...convertAutomation(data?.autoExport),
      fgs: +data.autoExport?.fgs?._val
    },
    inventory: {
      ...inventory,
      enableServiceLevel: stringToBoolean(inventory.enableServiceLevel),
      enableFutureSales: stringToBoolean(inventory.enableFutureSales),
      serviceLevel: inventory.serviceLevel / 10,
      futureBomNPeriodsEnabled: stringToBoolean(inventory.futureBomNPeriodsEnabled),
      futureDcNPeriodsEnabled: stringToBoolean(inventory.futureDcNPeriodsEnabled),
      futureSalesNPeriodsEnabled: stringToBoolean(inventory.futureSalesNPeriodsEnabled)
    },
    demandView: stringToBoolean(data.demandView?._val),
    wantNonstoringDc: stringToBoolean(data.wantNonstoringDc?._val),
    constrainDcTransfers : data.constrainDcTransfers ?._val,
    distrib: {
      ...distrib,
      enableServiceLevel: stringToBoolean(distrib.enableServiceLevel),
      enableFutureSales: stringToBoolean(distrib.enableFutureSales),
      serviceLevel: distrib.serviceLevel / 10,
      futureBomNPeriodsEnabled: stringToBoolean(distrib.futureBomNPeriodsEnabled),
      futureDcNPeriodsEnabled: stringToBoolean(distrib.futureDcNPeriodsEnabled),
      futureSalesNPeriodsEnabled: stringToBoolean(distrib.futureSalesNPeriodsEnabled)
    },
    movableDemand: stringToBoolean(data.movableDemand?._val),
    constrainBom: stringToBoolean(data.constrainBom?._val),
    avgSalesPriceIncompleteExcluded: stringToBoolean(data.avgSalesPriceIncompleteExcluded?._val)
  };
}
