import i18n from '@/plugins/vue-i18n';
import { numberToKMGT } from '@/helpers/utils/numberToKMGT';

export const curveKeys = {
  ACTUAL_SALES: 'actual',
  LOWER_ECHELON_DEMAND: 'lowerEchelonDemand',
  MATERIAL_CONSUMPTION: 'materialConsumption',
  FORECAST_LEARN: 'learn',
  FORECAST_PREDICTED: 'predicted',
  SAFETY_STOCK: 'safestock',
  PROJECTED_INVENTORY: 'remainder',
  CONF_LIMIT: 'conflimit',
  PROMOTIONS: 'promo'
};

export const chartTabKeys = {
  QTY: 'quantity',
  REVENUE: 'revenue'
};

export const chartTabs = [
  chartTabKeys.QTY,
  chartTabKeys.REVENUE
];

export const defaultLineConfig = {
  borderJoinStyle: 'bevel'
};

export const backgroundColorByKey = (color, key) => ({
  [chartTabKeys.QTY]: (args) => args.raw?.isOutlier ? 'black' : color,
  [chartTabKeys.REVENUE]: () => key === curveKeys.SAFETY_STOCK ? color : 'white'
});

export const lineConfigByKey = (key, chartLegend, tab) => {
  const isQty = tab === chartTabKeys.QTY;
  const groupColor = chartLegend[key]?.color;

  const datasetConfig = {
    type: 'line',
    legendIndex: chartLegend[key].legendIndex,
    layoutIndex: chartLegend[key].layoutIndex,
    label: chartLegend?.[key]?.title?.[tab] || chartLegend?.[key]?.title || '',
    labelKey: key,
    color: groupColor,
    borderColor: groupColor,
    pointBorderWidth: isQty ? 0.75 : 1,
    pointBorderColor: isQty ? 'white' : groupColor,
    borderWidth: 2,
    pointRadius: 2,
    pointHoverRadius: 2
  };

  datasetConfig.backgroundColor = backgroundColorByKey(groupColor, key)[tab];

  if ([curveKeys.CONF_LIMIT, curveKeys.SAFETY_STOCK].includes(key)) {
    datasetConfig.borderWidth = 1;
    datasetConfig.pointRadius = 1;
    datasetConfig.pointHoverRadius = 0;
    datasetConfig.pointBorderColor = 'transparent';
  } else if (key === curveKeys.PROMOTIONS) {
    datasetConfig.type = 'bubble';
    datasetConfig.pointStyle = 'rectRot';
    datasetConfig.borderWidth = 4;
    datasetConfig.pointRadius = 4;
    datasetConfig.pointHoverRadius = 0;
    datasetConfig.backgroundColor = 'white';
    datasetConfig.pointBorderColor = groupColor;
    datasetConfig.pointBorderWidth = 1;
  }

  return datasetConfig;
};

export const chartDatasetLegend = (nameCode, colorblindMode = false) => ({
  [curveKeys.ACTUAL_SALES]: {
    title: {
      [chartTabKeys.QTY]: i18n.tc('Main.Chart.LegActual'),
      [chartTabKeys.REVENUE]: i18n.tc('Main.Chart.LegRevenue')
    },
    color: colorblindMode ? 'rgb(240, 228, 66)' : '#c0c0c0',
    legendIndex: 1,
    layoutIndex: 3
  },
  [curveKeys.SAFETY_STOCK]: {
    title: i18n.tc('Main.Chart.LegSafeStock'),
    color: colorblindMode ? 'rgb(204, 121, 167)' : '#E8504620',
    legendIndex: 2,
    layoutIndex: 9
  },
  [curveKeys.PROJECTED_INVENTORY]: {
    title: i18n.tc('Main.Chart.LegInventory'),
    color: colorblindMode ? 'rgb(0, 158, 115)' : '#6f9654',
    legendIndex: 3,
    layoutIndex: 5
  },
  [curveKeys.LOWER_ECHELON_DEMAND]: {
    title: i18n.tc('Main.Chart.LegLowerEchelonDemand'),
    color: colorblindMode ? '#0066CC' : '#FFD700',
    legendIndex: 4,
    layoutIndex: 6
  },
  [curveKeys.MATERIAL_CONSUMPTION]: {
    title: i18n.tc('Main.Chart.LegMaterialConsumption'),
    color: colorblindMode ? '#000000' : 'rgb(170, 51, 106)',
    legendIndex: 5,
    layoutIndex: 7
  },
  [curveKeys.CONF_LIMIT]: {
    title: i18n.tc('Main.Chart.LegConfLimits'),
    color: colorblindMode ? 'rgb(86, 114, 179)' : '#6767aa',
    legendIndex: 6,
    layoutIndex: 8
  },
  [curveKeys.PROMOTIONS]: {
    title: i18n.tc('Main.Chart.Promotions'),
    color: '#f7b529',
    legendIndex: 7,
    layoutIndex: 1
  },
  [curveKeys.FORECAST_LEARN]: {
    title: i18n.tc('Main.Chart.LegTraining'),
    color: colorblindMode ? 'rgb( 86, 180, 233)' : '#006EB7',
    legendIndex: 8,
    layoutIndex: 2
  },
  [curveKeys.FORECAST_PREDICTED]: {
    title: nameCode
      ? i18n.t('Main.Chart.LegPredict2', { 1: i18n.tc(nameCode) })
      : i18n.tc('Main.Chart.LegPredict'),
    color: colorblindMode ? '#000000' : '#dc3912',
    legendIndex: 9,
    layoutIndex: 4
  }
});

const chartTooltipsConfig = {
  [curveKeys.ACTUAL_SALES]: {
    label: (val, tab) => tab === chartTabKeys.QTY
      ? i18n.tc('Main.Chart.Actual', null, { 1: val })
      : i18n.tc('Main.Chart.Revenue', null, { 1: val })
  },
  [curveKeys.LOWER_ECHELON_DEMAND]: {
    label: () => ''
  },
  [curveKeys.MATERIAL_CONSUMPTION]: {
    label: () => ''
  },
  [curveKeys.FORECAST_LEARN]: {
    label: (val) => i18n.tc('Main.Chart.Training', null, { 1: val })
  },
  [curveKeys.FORECAST_PREDICTED]: {
    label: (val) => i18n.tc('Main.Chart.Predicted', null, { 1: val })
  },
  [curveKeys.SAFETY_STOCK]: {
    label: (val) => i18n.tc('Main.Chart.SafeStock', null, { 1: val })
  },
  [curveKeys.PROJECTED_INVENTORY]: {
    label: (val) => i18n.tc('Main.Chart.Inventory', null, { 1: val })
  },
  upperConfLimit: {
    label: (val) => i18n.tc('Main.Chart.UpperConfLimit', null, { 1: val })
  },
  lowerConfLimit: {
    label: (val) => i18n.tc('Main.Chart.LowerConfLimit', null, { 1: val })
  },
  [curveKeys.PROMOTIONS]: {
    label: () => ''
  }
};

export const chartOptions = ({ ctx, legendClickCallback, xLabels, tab }) => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    devicePixelRatio: 3,
    interaction: {
      mode: 'x',
      intersect: false,
      axis: 'x'
    },
    elements: {
      line: {
        borderWidth: 1.5
      },
      point: {
        radius: 1.5
      }
    },
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          boxWidth: 8,
          boxHeight: 8,
          sort(a, b, data) {
            const aOrder = data.datasets[a.datasetIndex].legendIndex;
            const bOrder = data.datasets[b.datasetIndex].legendIndex;

            return aOrder - bOrder;
          }
        },
        onClick: legendClickCallback
      },
      tooltip: {
        intersect: true,
        position: 'nearest',
        callbacks: {
          title(context) {
            const xPoint = context && context[0].raw.x;

            return xLabels[xPoint] || '';
          },
          label(context) {
            const rangeSeparator = ' → ';
            const key = context.raw?.tooltipKey;
            let value = '';

            if (context.dataset.y !== null) {
              value = new Intl.NumberFormat('en-US').format(context.parsed.y);
            }

            if (Array.isArray(context.formattedValue)) {
              value = context.formattedValue
                .map(num => new Intl.NumberFormat('en-US').format(+num))
                .join(rangeSeparator);
            }

            if (context.raw?.y_from) {
              value = new Intl.NumberFormat('en-US').format(context.parsed.y - context.raw.y_from);
            }

            return chartTooltipsConfig[key].label(value, tab);
          }
        },
        filter(data, index, items) {
          if (![curveKeys.PROJECTED_INVENTORY, curveKeys.PROMOTIONS].includes(data.raw.tooltipKey)) {
            return true;
          }

          const key = data.raw.tooltipKey;

          const firstIndex = items.findIndex(item => item.raw.tooltipKey === key);

          if (firstIndex !== index) {
            return false;
          }

          const YPoints = items.reduce((acc, item) => {
            if (item.raw.tooltipKey === key) {
              acc.push(item.parsed.y);
            }

            return acc;
          }, []);

          if (YPoints.length > 1) {
            data.formattedValue = [
              YPoints.at(0),
              YPoints.at(-1)
            ];
          }

          return true;
        }
      },
      mouseLine: {
        enabled: true,
        color: 'rgb(200, 204, 207)',
        lineWidth: 1
      },
      zoom: {
        pan: {
          enabled: true
        },
        zoom: {
          mode: 'xy',
          wheel: {
            enabled: true
          }
        },
        limits: {
          y: {
            min: 0,
            max: 10000000000
          },
          y2: {
            min: 0,
            max: 10000000000
          }
        }
      },
      contextMenu: {
        context: ctx,
        ref: 'chart-context-menu'
      }
    },
    scales: {
      y: {
        ticks: {
          beginAtZero: true,
          callback(val) {
            return numberToKMGT(val, 1);
          }
        }
      },
      x: {
        display: true,
        type: 'linear',
        ticks: {
          autoSkipPadding: 20,
          maxRotation: 0,
          minRotation: 0,
          callback(val) {
            return xLabels[val] || '';
          }
        },
        grid: {
          drawOnChartArea: false
        }
      }
    }
  };
};
