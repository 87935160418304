export const colors = {
  GREEN: {
    name: 'green',
    color: 'accent-green-90',
    background: 'accent-green-10'
  },
  YELLOW: {
    name: 'yellow',
    color: 'accent-yellow-90',
    background: 'accent-yellow-10'
  },
  RED: {
    name: 'red',
    color: 'accent-red-90',
    background: 'accent-red-10'
  },
  BLUE: {
    name: 'blue',
    color: 'primary-90',
    background: 'primary-10'
  },
  PURPLE: {
    name: 'purple',
    color: 'accent-purple-90',
    background: 'accent-purple-10'
  },
  GREY: {
    name: 'grey',
    color: 'grey-80',
    background: 'grey-10'
  }
};
