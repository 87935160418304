import demandApi from '@/api/demand';

const types = {
  SET_SETTINGS: 'SET_SETTINGS'
};

const state = () => ({
  settings: null
});

const getters = {};

const mutations = {
  [types.SET_SETTINGS](state, settings) {
    state.settings = settings;
  }
};

const actions = {
  async fetchForecastSettings({ commit, rootGetters }) {
    try {
      const response = await demandApi.getForecastSettings({
        treeId: rootGetters['demand/tree/treeId'],
        nodeId: rootGetters['demand/tree/activeNodeId']
      });

      if (response?.data) {
        commit(types.SET_SETTINGS, response.data);
      }
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchForecastSettings' });
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
