export const GUIDE_URL =                              'http://docs.streamlineplan.com/';
export const GUIDE_DATABASE_CONNECTION_URL =          'https://gmdhsoftware.com/documentation-sl/database-connection';
export const GUIDE_NETSUITE_CONNECTION_URL =          'https://gmdhsoftware.com/documentation-sl/netsuite-connection-guide';
export const GUIDE_DYNAMICS_NAV_CONNECTION_URL =      'https://gmdhsoftware.com/documentation-sl/nav-connection-guide';
export const GUIDE_SAP_CONNECTION_URL =               'https://gmdhsoftware.com/documentation-sl/sap-b1-connection-guide';
export const GUIDE_SAP_EXPORT_URL =                   'https://docs.streamlineplan.com/en/connecting-data/data-connections/3-rd-party-connections/sap-b1';
export const GUIDE_FISHBOWL_CONNECTION_URL =          'https://gmdhsoftware.com/documentation-sl/fishbowl-connection-guide';
export const GUIDE_DYNAMICS_GP_CONNECTION_URL =       'https://gmdhsoftware.com/documentation-sl/dynamics-gp-connection-guide';
export const GUIDE_ODOO_CONNECTION_URL =              'https://gmdhsoftware.com/documentation-sl/odoo-connection-guide';
export const GUIDE_BC365_CONNECTION_URL =             'https://gmdhsoftware.com/documentation-sl/bc-connection-guide';
export const SPREADSHEET_TRANSACTIONAL_TUTORIAL_URL = 'https://youtu.be/jKyL6wYIvVw';
export const SPREADSHEET_AGGREGATED_TUTORIAL_URL =    'https://youtu.be/JZvnhOe8fMk';
export const SPREADSHEET_TRANSACTIONAL_TEMPLATE_URL = 'https://gmdhsoftware.com/Basic%20Transactional%20Data.xlsx';
export const SPREADSHEET_AGGREGATED_TEMPLATE_URL =    'https://gmdhsoftware.com/Basic%20Aggregated%20Data.xlsx';
export const HISTORICAL_TRANSACTIONS_TEMPLATE_URL =   'https://gmdhsoftware.com/Historical%20transactions.csv';
export const SAP_PROXY_DOWNLOAD_URL =                 'https://gmdhsoftware.com/download-sap-proxy';
export const SALES_DEMO_URL =                         'https://gmdhsoftware.com/demo/';
