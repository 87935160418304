<template>
  <SlModal
    :id="id"
    :title="$t('Web.Integrations.Modals.TitleConnection')"
    :loading="isLoading"
    persistent
    @created="onCreated"
    @on-enter="handleCreate"
    @show="onModalShow"
    @hide="onModalHide"
  >
    <ValidationObserver
      ref="observer"
      tag="div"
      class="modal-content create-integration"
    >
      <SlValidate
        v-slot="{ invalid }"
        vid="name"
        :rules="nameRules"
      >
        <SlInput
          v-model="name"
          :label="$t('Web.Integrations.Modals.LabelName')"
          :is-invalid="invalid"
          required
        />
      </SlValidate>

      <SlValidate
        v-if="isImport"
        v-slot="{ invalid }"
        vid="import"
        rules="required"
      >
        <!-- https://github.com/shentao/vue-multiselect/issues/1081 -->
        <SlErrorBoundary passive>
          <SlSelect
            v-model="datasource"
            :select-label="$t('Web.Integrations.Modals.LabelDatasource')"
            :options="options"
            track-by="type"
            label="name"
            :is-invalid="invalid"
            required
          >
            <template #selectOption="{ option }">
              <div
                v-tooltip="getTooltip({
                  content: $t('Web.FreeVersion.AvailableIn'),
                  disabled: option.availableForCurrentPlan === true
                })"
                class="datasource-select__option"
                :class="{ 'datasource-select__option--disabled': option.availableForCurrentPlan === false }"
              >
                {{ option.name }}
                <icon
                  v-if="option.availableForCurrentPlan === false"
                  data="@icons/upgrade-plan.svg"
                  class="fill-off size-16"
                />
              </div>
            </template>
          </SlSelect>
        </SlErrorBoundary>
      </SlValidate>
      <SlValidate
        v-else
        v-slot="{ invalid }"
        vid="export"
        rules="required"
      >
        <SlSelect
          v-model="datasource"
          :select-label="$t('Web.Integrations.Modals.LabelDatasource')"
          :options="options"
          group-values="integrations"
          group-label="group"
          track-by="id"
          label="name"
          :is-invalid="invalid"
          return-object
          required
        >
          <template #selectOption="{ option, selected }">
            <div class="datasource-select__option">
              <template v-if="option.$groupLabel">
                {{ option.$groupLabel }}
              </template>
              <template v-else-if="option.name">
                <div
                  v-tooltip="getTooltip({
                    content: $t('Web.FreeVersion.AvailableIn'),
                    disabled: option.availableForCurrentPlan === true
                  })"
                  class="datasource-select__option"
                  :class="{ 'datasource-select__option--disabled': option.availableForCurrentPlan === false }"
                >
                  {{ option.name }}
                  <icon
                    v-if="option.availableForCurrentPlan === false"
                    data="@icons/upgrade-plan.svg"
                    class="fill-off size-16"
                  />
                </div>
              </template>
              <icon
                v-if="selected"
                data="@icons/check.svg"
                class="fill-off size-16 color-primary-80 check-icon"
              />
            </div>
          </template>
        </SlSelect>
      </SlValidate>
    </ValidationObserver>

    <template #footer>
      <SlModalFooter>
        <SlButton
          variant="secondary"
          color="grey"
          @click="handleCancel"
        >
          {{ $t('Common.Cancel') }}
        </SlButton>
        <SlButton @click="handleCreate">
          {{ $t('Web.Integrations.Modals.BtnCreate') }}
        </SlButton>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { modal } from '@/mixins/modal';
import modalsId from '@/config/shared/modalsId.config';
import { integrationModuleTypes } from '@/config/integrations';
import { getTooltip } from '@/helpers/shared/tooltip';

export default {
  name: 'CreateIntegrationModal',
  mixins: [modal],
  data() {
    return {
      id: modalsId.CREATE_INTEGRATION,
      module: null,
      exportType: null,
      name: '',
      datasource: '',
      existingNames: [],
      createCallback: null,
      isLoading: false
    };
  },
  computed: {
    ...mapState({
      availableIntegrations: state => state.integrations.available_integrations
    }),
    options() {
      return this.availableIntegrations[this.module]?.map(option => ({
        ...option,
        $isDisabled: option.availableForCurrentPlan === false,
        ...(
          option.integrations && {
            integrations: option.integrations?.map(i => ({
              ...i,
              $isDisabled: i.availableForCurrentPlan === false
            }))
          }
        )
      }));
    },
    nameRules() {
      return {
        required: true,
        sl_not_includes: {
          values: this.existingNames,
          message: this.$t('Web.Error.AlreadyExist', {
            1: this.$t('Web.Error.Names.Connection')
          })
        }
      };
    },
    isImport() {
      return this.module === integrationModuleTypes.IMPORT;
    }
  },
  methods: {
    ...mapActions({
      fetchAvailableIntegrations: 'integrations/fetchAvailableIntegrations'
    }),
    getTooltip,
    async onModalShow() {
      try {
        this.isLoading = true;

        await this.fetchAvailableIntegrations({
          module: this.module,
          exportType: this.exportType
        });
      } finally {
        this.isLoading = false;
      }
    },
    onModalHide() {
      this.module = null;
      this.exportType = null;
      this.name = '';
      this.datasource = '';
      this.existingNames = [];
      this.createCallback = null;
      this.isLoading = false;
    },
    getPayload() {
      const payload = {
        name: this.name,
        module: this.module
      };

      if (this.isImport) {
        payload.type = this.datasource;

        return payload;
      }

      const { id, type } = this.datasource;

      payload.id = id;
      payload.type = type;
      payload.exportType = this.exportType;

      return payload;
    },
    async handleCreate() {
      const valid = await this.$refs.observer.validate();

      if (!valid) {
        return;
      }

      try {
        this.isLoading = true;

        await this.createCallback(this.getPayload());

        this.hideModal(this.id);
      } finally {
        this.isLoading = false;
      }
    },
    handleCancel() {
      this.hideModal(this.id);
    }
  }
};
</script>
<style lang="scss" scoped>
.modal-content.create-integration {
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-height: fit-content;
}
</style>
