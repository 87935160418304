export default function(filters) {
  const filterKeys = [
    'want_zero_orders',
    'hide_future_orders',
    'accelerate_purchase_orders'
  ];

  const activeFilters = filterKeys.filter((filter, index) => filters[index]);

  return {
    fgs: {
      _n: activeFilters.length,
      name: activeFilters
    }
  };
}