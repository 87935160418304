import settingsApi from '@/api/settings';
import SettingsDataResponseAdapter from '@/adapters/response/SettingsDataResponse.adapter';
import SettingsDataRequestAdapter from '@/adapters/request/SettingsDataRequest.adapter';
import statusCodes from '@/config/utils/statusCodes.config';
import cloneObject from '@/helpers/utils/cloneObject';
import { diff } from '@/helpers/utils/diff';
import { setLanguageHeader } from '@/helpers/api/axios';
import clientStorage from '@/api/clientStorage';
import clientStorageKeysConfig from '@/config/api/clientStorageKeys.config';
import { availableLocales } from '@/config/plugins/vue-i18n.config';
import { themesKeys } from '@/config/shared/themes.config';
import { treeFilterKeys } from '@/config/demand/tree/tree.config';
import { validateLocales } from '@/config/plugins/vee-validate.config';
import { loadLocale } from '@/plugins/vee-validate.plugin';
import { checkEqualObject } from '@/helpers/utils/checkEqualObject';

const types = {
  SET_SETTINGS: 'SET_SETTINGS',
  SET_SETTINGS_CACHE: 'SET_SETTINGS_CACHE',
  SET_SETTINGS_BY_KEY: 'SET_SETTINGS_BY_KEY',
  SET_IS_FETCHING: 'SET_IS_FETCHING',
  SET_SETTINGS_LANGUAGE: 'SET_SETTINGS_LANGUAGE',
  SET_COLORBLIND_MODE: 'SET_COLORBLIND_MODE'
};

const state = () => ({
  settings: null,
  settings_cache: null,
  is_fetching: false,
  language: clientStorage.getDataByKey(clientStorageKeysConfig.LANGUAGE) || availableLocales[0],
  colorblind_mode: !!clientStorage.getDataByKey(clientStorageKeysConfig.COLORBLIND_MODE)
});

const getters = {
  supplyUnit: state => state.settings?.supplyUnit,
  currency: state => state.settings?.currency,
  containerWeight: state => state.settings?.containerWantWeight
    ? state.settings.containerWeight
    : '',
  containerVolume: state => state.settings?.containerWantVolume
    ? state.settings.containerVolume
    : '',
  containerQty: state => state.settings?.containerWantQty
    ? state.settings.containerQty
    : '',
  containerCost: state => state.settings?.containerWantCost
    ? state.settings.containerCost
    : ''
};

const mutations = {
  [types.SET_SETTINGS](state, value) {
    state.settings = value;
  },
  [types.SET_SETTINGS_CACHE](state, value) {
    state.settings_cache = value;
  },
  [types.SET_SETTINGS_BY_KEY](state, { key, subKey, value }) {
    if (subKey) {
      state.settings[key][subKey] = value;
    } else {
      state.settings[key] = value;
    }
  },
  [types.SET_IS_FETCHING](state, value) {
    state.is_fetching = value;
  },
  [types.SET_SETTINGS_LANGUAGE](state, value) {
    state.language = value;
  },
  [types.SET_COLORBLIND_MODE](state, value) {
    state.colorblind_mode = value;
  }
};

const actions = {
  async fetchSettings({ commit }) {
    try {
      commit(types.SET_IS_FETCHING, true);

      const response = await settingsApi.getSettings();

      if (response?.data) {
        commit(types.SET_SETTINGS, SettingsDataResponseAdapter(response.data?.timestamped));
      }
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchSettings' });
    } finally {
      commit(types.SET_IS_FETCHING, false);
    }
  },
  setSettingsCache({ commit }, data) {
    if (data) {
      commit(types.SET_SETTINGS_CACHE, cloneObject(data));
    } else {
      commit(types.SET_SETTINGS_CACHE, null);
    }
  },
  setSettingsByKey({ commit }, payload) {
    commit(types.SET_SETTINGS_BY_KEY, payload);
  },
  cancelSettingsEdit({commit, dispatch, state}) {
    commit(types.SET_SETTINGS, state.settings_cache);
    commit(types.SET_COLORBLIND_MODE, clientStorage.getDataByKey(clientStorageKeysConfig.COLORBLIND_MODE));
    dispatch('setSettingsCache', null);
  },
  async saveSettings({ state, commit, dispatch }, { i18n }) {
    try {
      commit(types.SET_IS_FETCHING, true);

      dispatch('saveColorblindMode');

      if (!state.settings_cache) {
        return;
      }

      if (state.settings.abc.func === 'None') {
        this.dispatch('demand/tree/setFilterByKey', { key: treeFilterKeys.ABC_ANALYSIS, value: false });
      }

      const shouldUpdate = !checkEqualObject(state.settings_cache, state.settings);

      if (!shouldUpdate) {
        return;
      }

      const updatedOnlySettings = diff(state.settings_cache, state.settings);

      Object.keys(updatedOnlySettings).forEach(key => {
        if (['autoUpdate', 'autoExport', 'autoUpdateConstrainedOrderingPlan'].includes(key)) {
          updatedOnlySettings[key] = state.settings[key];
        }
      });

      const response = await settingsApi.saveSettings(SettingsDataRequestAdapter(updatedOnlySettings));

      if (response.status === statusCodes.OK) {
        dispatch('setSettingsCache', null);
      }

      return response;
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'saveSettings' });

      throw e;
    } finally {
      commit(types.SET_IS_FETCHING, false);
      dispatch('saveLanguage', i18n);
    }
  },
  setLanguageByCode({ commit }, code) {
    commit(types.SET_SETTINGS_LANGUAGE, code);
    setLanguageHeader(code);
  },
  saveLanguage({ state }, i18n) {
    const currentLanguage = i18n.locale;

    clientStorage.setDataByKey(clientStorageKeysConfig.CURRENT_LOCALE_KEY, state.language);

    if (state.language !== currentLanguage) {
      // load & set validation locale
      loadLocale(validateLocales[state.language], state.language);

      window.location.reload();
    }
  },
  setColorblindMode({ commit }, value) {
    commit(types.SET_COLORBLIND_MODE, value);
  },
  saveColorblindMode({ state }) {
    this.dispatch('setTheme', state.colorblind_mode ? themesKeys.COLORBLIND : themesKeys.LIGHT);
    clientStorage.setDataByKey(clientStorageKeysConfig.COLORBLIND_MODE, state.colorblind_mode);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
