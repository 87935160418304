import axios from '@/plugins/axios';
import defaultAxios from 'axios';
import globalConfig from '@/config/global.config';

export default {
  getProjectStatistics() {
    return axios.get('/project/statistics');
  },
  getProjectData() {
    return axios.get('/project');
  },
  getItemCodes(params) {
    return axios.get('/project/item-codes', { params });
  },
  getLocations(params) {
    return axios.get('/project/locations', { params });
  },
  getChannels(params) {
    return axios.get('/project/channels', { params });
  },
  postReimport(body) {
    return axios.post('/project/re-import', body);
  },
  getReimportMetaInfo(id) {
    return axios.get('/project/import/metainfo', {
      params: {
        id
      }
    });
  },
  getProjectTabs() {
    return axios.get('/project/tabs');
  },
  saveProject() {
    return axios.put('/project/save_project');
  },
  getTutorials() {
    return axios.get('/project/tutorials', {
      headers: {
        'X-pid-independent': true
      }
    });
  },
  getSystemVersion() {
    return axios.get('/project/system_version', {
      headers: {
        'X-pid-independent': true
      }
    });
  },
  downloadTruncatedProject(params) {
    return axios.get('/project/download_truncated', {
      responseType: 'blob',
      headers: {
        Accept: 'application/octet-stream'
      },
      params
    });
  },
  getHolidays() {
    return axios.get('/holidays');
  },
  getRamStats() {
    return axios.get('/project/ram_stats', {
      headers: {
        'X-pid-independent': true
      }
    });
  },
  exportBomIssues(params) {
    return axios.get('/project/bom_issues/xlsx', {
      params,
      responseType: 'blob',
      headers: {
        Accept: 'application/octet-stream'
      }
    });
  },
  postServerVersion(body) {
    defaultAxios.post(
      `${globalConfig.STATS_SERVER_URL}/statistics/domain-version`,
      body,
      {
        headers: {
          'X-Stat-Server': 'streamline'
        }
      }
    ).catch(e => e);
  },
  getLogsList() {
    return axios.get('/project/logs_list', {
      headers: {
        'X-pid-independent': true
      }
    });
  },
  downloadLogs(params) {
    return axios.get('/project/download_logs', {
      params,
      responseType: 'blob',
      headers: {
        'X-pid-independent': true,
        'Accept': 'application/octet-stream'
      }
    });
  },
  deleteLogs(params) {
    return axios.delete('/project/delete_logs', {
      params,
      headers: {
        'X-pid-independent': true
      }
    });
  },
  getFreezeSettings() {
    return axios.get('/project/freeze');
  },
  putFreezeSettings(body) {
    return axios.put('/project/freeze', body);
  }
};
