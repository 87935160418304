var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VDropdown',_vm._g(_vm._b({ref:"slDropdown",attrs:{"shown":_vm.isShown,"triggers":_vm.triggers,"placement":_vm.placement,"overflow-padding":_vm.overflowPadding,"container":_vm.containerSelector,"auto-hide":_vm.autoHide,"disabled":_vm.disabled,"auto-size":_vm.autoSize,"flip":_vm.flip,"no-auto-focus":_vm.noAutoFocus,"handle-resize":_vm.handleResize,"prevent-overflow":"","auto-boundary-max-size":"","popper-class":['sl-dropdown', _vm.contentClass],"theme":_vm.theme},on:{"update:shown":function($event){_vm.isShown=$event}},scopedSlots:_vm._u([{key:"popper",fn:function(){return [_vm._t("content",null,null,{
        close: _vm.close
      })]},proxy:true}],null,true)},'VDropdown',_vm.$attrs,false),_vm.$listeners),[_vm._t("default"),(_vm.$scopedSlots.target)?_vm._t("target",null,null,{
      isShown: _vm.isShown,
      open: _vm.open
    }):_c('SlButton',{attrs:{"active":_vm.isShown,"variant":"secondary","color":"grey","disabled":_vm.disabled},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}},scopedSlots:_vm._u([(_vm.$scopedSlots['prepend-inner'])?{key:"prepend",fn:function(){return [_vm._t("prepend-inner")]},proxy:true}:null,{key:"append",fn:function(){return [_c('icon',{staticClass:"fill-off size-16",class:{
          'rotate-180': _vm.isShown
        },attrs:{"data":require("@icons/chevron_down.svg")}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.targetLabel)+" ")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }