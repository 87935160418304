<template>
  <VDropdown
    ref="slDropdown"
    :shown.sync="isShown"
    :triggers="triggers"
    :placement="placement"
    :overflow-padding="overflowPadding"
    :container="containerSelector"
    :auto-hide="autoHide"
    :disabled="disabled"
    :auto-size="autoSize"
    :flip="flip"
    :no-auto-focus="noAutoFocus"
    :handle-resize="handleResize"
    prevent-overflow
    auto-boundary-max-size
    :popper-class="['sl-dropdown', contentClass]"
    :theme="theme"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
    <slot
      v-if="$scopedSlots.target"
      name="target"
      v-bind="{
        isShown,
        open
      }"
    />
    <SlButton
      v-else
      :active="isShown"
      variant="secondary"
      color="grey"
      :disabled="disabled"
      @click.stop.prevent
    >
      <template
        v-if="$scopedSlots['prepend-inner']"
        #prepend
      >
        <slot name="prepend-inner" />
      </template>

      {{ targetLabel }}
      <template #append>
        <icon
          data="@icons/chevron_down.svg"
          class="fill-off size-16"
          :class="{
            'rotate-180': isShown
          }"
        />
      </template>
    </SlButton>

    <template #popper>
      <slot
        name="content"
        v-bind="{
          close
        }"
      />
    </template>
  </VDropdown>
</template>

<script>
export default {
  name: 'SlDropdown',
  props: {
    theme: {
      type: String,
      default: 'sl-dropdown'
    },
    targetLabel: {
      type: [String, Number],
      default: ''
    },
    placement: {
      type: String,
      default: 'bottom'
    },
    overflowPadding: {
      type: Number,
      default: 0
    },
    triggers: {
      type: Array,
      default: () => (['click'])
    },
    containerSelector: {
      type: String,
      default: '#app'
    },
    autoSize: {
      type: [String, Boolean],
      default: false
    },
    autoHide: {
      type: Boolean,
      default: true
    },
    flip: {
      type: Boolean,
      default: true
    },
    contentClass: {
      type: String,
      default: ''
    },
    disabled: Boolean,
    mountShow: Boolean,
    noAutoFocus: Boolean,
    handleResize: Boolean
  },
  data() {
    return {
      isShown: false
    };
  },
  watch: {
    isShown(val) {
      this.$emit('toggle', val);
    }
  },
  beforeMount() {
    this.isShown = this.mountShow;
  },
  methods: {
    open() {
      this.$refs.slDropdown.$refs.popper.show();
    },
    close() {
      this.$refs.slDropdown.$refs.popper.hide();
    }
  }
};
</script>
