<template>
  <div
    class="sl-table-wrapper"
    :class="{
      'sl-table-wrapper--no-data': isNoData,
      'sl-table-wrapper--inner-no-data': isNoData && innerNoData,
      'sl-table-wrapper--inner-no-data-editable': innerNoDataEditable,
      'sl-table-wrapper--before-outer': $scopedSlots['before-outer']
    }"
    :style="{
      height: tableHeight,
      maxHeight: `${maxHeight}px`
    }"
  >
    <SlTableClipboardMenu
      :id="`${id}-context-menu`"
      ref="clipboardMenu"
    />

    <slot
      v-if="$scopedSlots.loader"
      name="loader"
    />
    <SlTableLoader
      v-else
      :show="loading"
    />
    <div
      v-if="showTable"
      :id="tableId"
      class="sl-table"
      :class="{
        'sl-table--pagination': showPagination,
        'sl-table--list': list
      }"
    >
      <RecycleScroller
        id="sl-table-scroller"
        ref="scrollContainer"
        class="sl-table__scroller"
        :class="{
          'sl-table--off-col-resize': !colResize,
          'sl-table--num-col': numCol
        }"
        :style="{
          maxHeight: `${maxHeight}px`
        }"
        :items="tableRows"
        :key-field="uniqueKey"
        :item-size="rowHeight"
        :buffer="rowBuffer"
        @scroll.native.passive="updateScroll"
      >
        <template #before>
          <SlTableHeader :scrolled="hasVerticalScrollbar && !scrolledToTop">
            <SlTableRow>
              <SlTableSideCol
                :scrolled-right="hasHorizontalScrollbar && !scrolledToRight"
                :scrolled-left="hasHorizontalScrollbar && !scrolledToLeft"
                :height="sideColHeight"
                :shadow="numCol || selectable"
              >
                <SlTableCellHeader
                  v-if="numCol"
                  class="sl-table__cell--side-col"
                >
                  {{ '№' }}
                </SlTableCellHeader>
                <SlTableCellHeader
                  v-if="selectable"
                  class="sl-table__cell--side-col"
                >
                  <slot
                    v-if="$scopedSlots['header-checkbox']"
                    name="header-checkbox"
                  />
                  <SlCheckbox
                    v-else
                    :value="headerCheckboxValue"
                    :indeterminate="isIndeterminate"
                    @change="toggleCheckboxAll"
                  />
                </SlTableCellHeader>
              </SlTableSideCol>
              <template v-for="(header, headerIndex) of headers">
                <SlTableCellHeader
                  v-if="isHeaderCellVisible(header)"
                  :key="header[headerUniqueKey]"
                  v-tooltip="getTooltip(header[headerNameKey])"
                  :sortable="apiSortable"
                  :style="getColStyle(headerIndex)"
                  :data-resize="headerIndex"
                  :data-test-id="`${id}-tbl-header-${header[headerUniqueKey].toLowerCase()}`"
                  :resizable="colResize"
                  @click.native="getColumnSortCallback($event, header[headerUniqueKey])"
                  @click.right.native="handleHeaderContextMenu(
                    $event,
                    {
                      header,
                      headerIndex
                    })"
                >
                  <div class="sl-table__cell-text">
                    <slot
                      v-if="$scopedSlots[`header-${header[headerUniqueKey]}`]"
                      :name="`header-${header[headerUniqueKey]}`"
                      v-bind="{
                        header,
                        headerIndex
                      }"
                    />
                    <template v-else>
                      <span>
                        {{ header[headerNameKey] }}
                      </span>
                      <slot
                        v-if="$scopedSlots[`header-${header[headerUniqueKey]}-info`]"
                        :name="`header-${header[headerUniqueKey]}-info`"
                        v-bind="{
                          header,
                          headerIndex
                        }"
                      />
                    </template>
                    <icon
                      v-if="apiSortable && sortBy === header[headerUniqueKey]"
                      data="@icons/arrow-up.svg"
                      class="size-12 sl-table__cell-sort-icon"
                      :class="{
                        'sl-table__cell-sort-icon--desc': sortOrder === sortingOrders.DESCENDING
                      }"
                    />
                  </div>
                </SlTableCellHeader>
                <SlTableSideCol
                  v-else-if="$scopedSlots[`header-${header[headerUniqueKey]}-last`]"
                  :key="`header-${header[headerUniqueKey]}-last`"
                  :scrolled-right="hasHorizontalScrollbar && !scrolledToRight"
                  :scrolled-left="hasHorizontalScrollbar && !scrolledToLeft"
                  :height="sideColHeight"
                  shadow
                  last
                >
                  <SlTableCellHeader class="sl-table__cell--side-col" />
                </SlTableSideCol>
              </template>
            </SlTableRow>
            <SlTableRow v-if="lowerHeaders">
              <SlTableSideCol
                :scrolled-right="hasHorizontalScrollbar && !scrolledToRight"
                :scrolled-left="hasHorizontalScrollbar && !scrolledToLeft"
              >
                <SlTableCellHeader
                  v-if="numCol"
                  class="sl-table__cell--side-col"
                />
                <SlTableCellHeader
                  v-if="selectable"
                  class="sl-table__cell--side-col"
                />
              </SlTableSideCol>
              <template v-for="(header, headerIndex) of lowerHeaders">
                <SlTableCellHeader
                  v-if="isHeaderCellVisible(header)"
                  :key="header[headerUniqueKey]"
                  :data-resize="headerIndex"
                  :style="getColStyle(headerIndex)"
                >
                  <template v-if="$scopedSlots[`lower-header-${header[headerUniqueKey]}`]">
                    <slot
                      :name="`lower-header-${header[headerUniqueKey]}`"
                      v-bind="{
                        header,
                        headerIndex
                      }"
                    />
                  </template>
                  <div
                    v-else
                    class="sl-table__cell-text"
                  >
                    {{ header[headerNameKey] }}
                  </div>
                </SlTableCellHeader>
              </template>
            </SlTableRow>
          </SlTableHeader>
        </template>
        <template v-slot="{ item: row, index: rowIndex }">
          <div
            v-if="isRowExist(row)"
            :key="`${rowsIds[rowIndex]}_${rowIndex}`"
            class="sl-table__row-wrap"
            :class="{
              'sl-table__row-wrap--even': isEven(rowIndex),
              'sl-table__row-wrap--last': isLast(rowIndex),
              'sl-table__row-wrap--highlighted': row[uniqueKey] === highlightedRowId
            }"
            :data-test-id="`${id}-tbl-row-${rowIndex}`"
          >
            <SlTableRow
              :is-even="isEven(rowIndex)"
              :highlight="highlightRow"
            >
              <SlTableSideCol>
                <SlTableCell
                  v-if="numCol"
                  class="sl-table__cell--side-col"
                >
                  <slot
                    v-if="$scopedSlots['row-num']"
                    name="row-num"
                    v-bind="{
                      row: rows[originalIndexes[row[uniqueKey]]],
                      rowId: row[uniqueKey],
                      rowIndex: originalIndexes[row[uniqueKey]],
                    }"
                  />
                  <template v-else>
                    {{ getRowNum(rowIndex) }}
                  </template>
                </SlTableCell>
                <SlTableCell
                  v-if="selectable"
                  class="sl-table__cell--side-col"
                >
                  <slot
                    v-if="$scopedSlots['row-checkbox']"
                    name="row-checkbox"
                    v-bind="{
                      row: rows[originalIndexes[row[uniqueKey]]],
                      rowId: row[uniqueKey],
                      rowIndex: originalIndexes[row[uniqueKey]],
                    }"
                  />
                  <SlCheckbox
                    v-else
                    :value="isSelectedRow(row[uniqueKey])"
                    @change="(val) => toggleCheckbox(val, row[uniqueKey])"
                  />
                </SlTableCell>
              </SlTableSideCol>
              <template v-for="(cellValue, cellKey, cellIndex) in row">
                <SlTableCell
                  v-if="isBodyCellVisible(cellKey)"
                  :key="cellKey"
                  :style="getColStyle(cellIndex - 1)"
                  @click.right.native="handleBodyContextMenu(
                    $event,
                    {
                      row: rows[originalIndexes[row[uniqueKey]]],
                      rowId: row[uniqueKey],
                      rowIndex: originalIndexes[row[uniqueKey]],
                      cellValue,
                      cellKey,
                      cellIndex,
                      text: getCellValueText(cellValue)
                    })"
                >
                  <slot
                    v-if="$scopedSlots[cellKey]"
                    :name="cellKey"
                    v-bind="{
                      row: rows[originalIndexes[row[uniqueKey]]],
                      rowId: row[uniqueKey],
                      rowIndex: originalIndexes[row[uniqueKey]],
                      cellValue,
                      cellKey,
                      cellIndex,
                      text: getCellValueText(cellValue)
                    }"
                  />
                  <div
                    v-else
                    :title="getCellValueText(cellValue)"
                    class="sl-table__cell-text"
                  >
                    <!-- default title optimize virtual table rendering -->
                    {{ getCellValueText(cellValue) }}
                  </div>
                </SlTableCell>
                <SlTableSideCol
                  v-else-if="$scopedSlots[`${cellKey}-last`]"
                  :key="`${cellKey}-last`"
                  last
                >
                  <SlTableCell class="sl-table__cell--side-col">
                    <slot
                      :name="`${cellKey}-last`"
                      v-bind="{
                        row: rows[originalIndexes[row[uniqueKey]]],
                        rowId: row[uniqueKey],
                        rowIndex: originalIndexes[row[uniqueKey]],
                        cellValue,
                        cellKey,
                        cellIndex,
                        text: getCellValueText(cellValue)
                      }"
                    />
                  </SlTableCell>
                </SlTableSideCol>
              </template>
            </SlTableRow>
          </div>
        </template>
      </RecycleScroller>
    </div>
    <SlTablePagination
      v-if="showPagination"
      :value="currentPage"
      :total-count="totalRowsCount"
      :selected="totalSelected"
      :per-page="perPage"
      :disabled="loading"
      @input="handlePageChange"
      @per-page="handlePerPageChange"
    />
    <div
      v-if="isNoData"
      class="sl-table__no-data"
    >
      <slot
        v-if="$slots['no-data']"
        name="no-data"
      />
      <SlNoData v-else />
    </div>
    <slot name="before-outer" />
  </div>
</template>

<script>
import SlTableCell from '@/components/UIKit/SlTable/SlTableCell';
import SlTableRow from '@/components/UIKit/SlTable/SlTableRow';
import SlTableHeader from '@/components/UIKit/SlTable/SlTableHeader';
import SlTableCellHeader from '@/components/UIKit/SlTable/SlTableCellHeader';
import SlTableSideCol from '@/components/UIKit/SlTable/SlTableSideCol';
import SlTableLoader from '@/components/UIKit/SlTable/SlTableLoader';
import SlTableClipboardMenu from '@/components/UIKit/SlTable/SlTableClipboardMenu.vue';
import { colResize } from '@/mixins/colResize';
import { scroll } from '@/mixins/scroll';
import SlTablePagination from '@/components/UIKit/SlTable/SlTablePagination';
import { sortingOrders } from '@/config/report/inventoryReport';
import { toArray } from '@/helpers/utils/toArray';
import { getTooltip } from '@/helpers/shared/tooltip';
import { SUBPAGE_PAGE_SIZE } from '@/helpers/shared/tableConfig';

export default {
  name: 'SlTable',
  components: {
    SlTableClipboardMenu,
    SlTablePagination,
    SlTableLoader,
    SlTableSideCol,
    SlTableCellHeader,
    SlTableHeader,
    SlTableRow,
    SlTableCell
  },
  mixins: [colResize, scroll],
  props: {
    id: {
      type: String,
      required: true
    },
    headers: {
      type: Array,
      required: true
    },
    lowerHeaders: {
      type: Array,
      default: null
    },
    headerUniqueKey: {
      type: String,
      default: '_key'
    },
    headerNameKey: {
      type: String,
      default: '_name'
    },
    // used for sorting
    headerTypeKey: {
      type: String,
      default: '_type'
    },
    rows: {
      type: [Array, Object],
      required: true
    },
    uniqueKey: {
      type: [Number, String],
      required: true
    },
    sortBy: {
      type: [String, null],
      required: false,
      default: null
    },
    sortOrder: {
      type: [String, null],
      required: false,
      default: null
    },
    maxHeight: {
      type: Number,
      default: 300
    },
    hiddenColumnsKeys: {
      type: Array,
      required: false,
      default: () => []
    },
    searchBy: {
      type: [Array, String, null],
      required: false,
      default: () => []
    },
    initialColSizes: {
      type: Array,
      default: () => []
    },
    colResize: {
      type: Boolean,
      default: true
    },
    // EXPERIMENTAL
    colFill: Boolean,
    colInitialWidth: {
      type: Number,
      default: 130
    },
    colWidthDimension: {
      type: String,
      default: 'px'
    },
    rowHeight: {
      type: [Number, String],
      default: 32
    },
    // return value which will be displayed without slot & used in sort function
    valueParser: {
      type: Function,
      default: null
    },
    numColKey: {
      type: String,
      default: ''
    },
    numCol: Boolean,
    selectable: Boolean,
    apiSortable: Boolean,
    expandable: Boolean,
    loading: Boolean,
    pagination: Boolean,
    currentPage: {
      type: Number,
      default: 1
    },
    totalRowsCount: {
      type: Number,
      default: 0
    },
    totalSelected: {
      type: Number,
      default: 0
    },
    perPage: {
      type: Number,
      default: 20
    },
    list: Boolean,
    innerNoData: Boolean,
    innerNoDataEditable: Boolean,
    // work on rows hover
    highlightRow: Boolean,
    highlightedRowId: {
      type: [Number, String],
      default: null
    }
  },
  data() {
    return {
      sortingOrders,
      selected: [],
      foundRowsId: [],
      searchQuery: '',
      rowBuffer: 200,
      colMinWidth: 40,
      headerRowHeight: 32,
      resizerInitDelay: 300,
      emptyValues: [
        '----',
        '0000-00-00',
        'nan',
        'NaN',
        ''
      ],
      numberSortTypes: [
        'overrideablePosInt',
        'number'
      ],
      originalIndexes: {},
      resizerClasses: ['resizer', 'resizer--header'],
      getTooltip
    };
  },
  computed: {
    tableId() {
      return `sl-table-${this.id}`;
    },
    preparedRows() {
      return toArray(this.rows).map(row => {
        return this.headers.reduce((acc, header) => {
          const key = header[this.headerUniqueKey];

          acc[key] = row[key] !== undefined ? row[key] : '';

          return acc;
        }, { [this.uniqueKey]: row[this.uniqueKey] });
      });
    },
    tableRows() {
      if (this.searchQuery) {
        return this.localSearch(this.preparedRows);
      }

      return this.preparedRows;
    },
    rowsCount() {
      return this.tableRows.length;
    },
    rowsIds() {
      return this.tableRows.map(row => row[this.uniqueKey]);
    },
    isNoData() {
      return !this.loading && !this.rowsCount;
    },
    showTable() {
      return this.innerNoData || this.rowsCount;
    },
    showPagination() {
      return this.pagination && this.totalRowsCount > SUBPAGE_PAGE_SIZE && !this.isNoData;
    },
    isIndeterminate() {
      return !!this.selected.length
        && this.selected.length !== this.rowsCount;
    },
    headerCheckboxValue() {
      return this.selected.length && this.selected.length <= this.rowsCount;
    },
    headersCount() {
      return this.lowerHeaders ? 2 : 1;
    },
    tableHeight() {
      if (this.isNoData) {
        return '100%';
      }

      return this.loading && !this.rowsCount ? `${this.maxHeight}px` : 'auto';
    },
    sideColHeight() {
      const totalHeight = this.headerRowHeight * this.headersCount + this.rowHeight * this.rowsCount;

      return totalHeight > this.maxHeight
        ? this.maxHeight
        : totalHeight;
    },
    hiddenKeys() {
      return this.hiddenColumnsKeys.length ? this.hiddenColumnsKeys : [this.uniqueKey];
    },
    localInitialColSizes() {
      if (this.colFill) {
        const length = this.headers.length;

        return Array.from({ length }, () => `${(100 / length).toFixed(3)}%`);
      }

      return this.initialColSizes;
    }
  },
  watch: {
    headers() {
      this.resizerInit(true);
    },
    rows() {
      this.resizerInit(true);
      this.updateOriginalIndexes();

      if (!this.scrollObserving) {
        this.$nextTick(this.initObserver);
      }
    },
    rowsCount() {
      !this.expandable && this.resetScroll();
    },
    selectable() {
      this.colSizes = this.localInitialColSizes;
    }
  },
  mounted() {
    // set initial col sizes before resizer init
    this.colSizes = this.localInitialColSizes;

    this.resizerInit();
    this.updateOriginalIndexes();
  },
  methods: {
    updateOriginalIndexes() {
      this.originalIndexes = this.preparedRows.reduce((acc, row, idx) => {
        acc[row[this.uniqueKey]] = idx;

        return acc;
      }, {});
    },
    resizerInit(isUpdate = false) {
      if (!this.colResize) {
        return;
      }

      setTimeout(() => {
        this.initResize({
          tableId: this.tableId,
          colSelector: '.sl-table__cell--resizable',
          minWidth: this.colMinWidth,
          resizerHeight: '100%',
          initialColSizes: !isUpdate && this.localInitialColSizes,
          lazyResize: true
        });
      }, this.resizerInitDelay);
    },
    resetScroll() {
      if (!this.$refs.scrollContainer) {
        return;
      }

      this.$refs.scrollContainer.$el.scrollTop = 0;
      this.$refs.scrollContainer.$el.scrollLeft = 0;
    },
    scrollToEnd() {
      if (!this.$refs.scrollContainer) {
        return;
      }

      const { scrollToItem, $_endIndex } = this.$refs.scrollContainer;

      scrollToItem($_endIndex);
    },
    scrollToIndex(index) {
      const { scrollToItem } = this.$refs.scrollContainer;

      scrollToItem(index);
    },
    //pagination
    handlePageChange(page) {
      this.$emit('page-change', page);

      this.resetScroll();
    },
    handlePerPageChange(pageSize) {
      this.$emit('per-page-change', pageSize);
    },
    // search
    submitLocalSearch(query) {
      if (!query || !this.preparedRows?.length) {
        this.foundRowsId = [];
        this.searchQuery = '';

        return;
      }

      this.foundRowsId = this.preparedRows.reduce((acc, row) => {
        const hasQuery = toArray(this.searchBy)?.some(searchKey => {
          return row[searchKey].toLowerCase().includes(query.toLowerCase());
        });

        if (hasQuery) {
          acc.push(row[this.uniqueKey]);
        }

        return acc;
      }, []);

      this.searchQuery = query;
    },
    localSearch(rows) {
      if (!this.foundRowsId.length) {
        return [];
      }

      return rows.filter(row => this.foundRowsId.includes(row[this.uniqueKey]));
    },
    // sort
    updateLocalSort(key) {
      return {
        key,
        order: this.getNewLocalSortStatus()
      };
    },
    getNewLocalSortStatus() {
      return this.sortOrder === this.sortingOrders.ASCENDING
        ? this.sortingOrders.DESCENDING
        : this.sortingOrders.ASCENDING;
    },
    getColumnSortCallback(event, key) {
      const classList = event.target.classList;
      const isResizerClass = this.resizerClasses.some(classItem => classList.contains(classItem));

      if (isResizerClass) {
        return null;
      }

      if (this.apiSortable) {
        return this.$emit('sort', {
          callback: () => this.updateLocalSort(key)
        });
      }

      return null;
    },
    // table configuration
    isRowExist(row) {
      return this.originalIndexes[row[this.uniqueKey]] !== undefined;
    },
    isEven(index) {
      return !!(index % 2);
    },
    isLast(index) {
      return index + 1 === this.rowsCount;
    },
    isHeaderCellVisible(header) {
      return !this.hiddenKeys.includes(header[this.headerUniqueKey]);
    },
    isBodyCellVisible(cellKey) {
      return !this.hiddenKeys.includes(cellKey);
    },
    getCellValueText(cellValue) {
      if (this.valueParser) {
        return this.valueParser(cellValue);
      }

      const value = cellValue?.val ?? cellValue?._val ?? cellValue ?? '';

      if (this.emptyValues.includes(value)) {
        return '';
      }

      return value;
    },
    getColStyle(index) {
      if (!this.colResize && this.colSizes.length === 0) {
        return {
          maxWidth: `${this.colInitialWidth}${this.colWidthDimension}`,
          minWidth: `${this.colInitialWidth}${this.colWidthDimension}`
        };
      }

      const isWidthInPx = typeof this.colSizes[index] === 'number';

      if (isWidthInPx) {
        return {
          width: `${this.colSizes[index] || this.colInitialWidth}${this.colWidthDimension}`
        };
      }

      return {
        width: `${this.colSizes[index] || (this.colInitialWidth + this.colWidthDimension)}`,
        // min-width required in pixels
        minWidth: `${this.colResize ? this.colMinWidth : this.colInitialWidth}px`
      };
    },
    getRowNum(rowIndex) {
      if (this.numColKey) {
        return this.valueParser(this.tableRows[rowIndex][this.numColKey]);
      }

      if (this.totalRowsCount) {
        return ((this.currentPage - 1) * this.perPage) + rowIndex + 1;
      }

      return rowIndex + 1;
    },
    // row selection
    isSelectedRow(id) {
      return this.selected.indexOf(id) >= 0;
    },
    selectId(id) {
      if (!this.isSelectedRow(id)) {
        this.selected.push(id);
      }
    },
    unselectId(id) {
      this.selected = this.selected.filter(item => {
        return item !== id;
      });
    },
    toggleCheckboxAll(checked) {
      if (checked) {
        this.selected = [...this.rowsIds];
      } else {
        this.resetSelected();
      }

      this.$emit('checkbox-toggle-all', { checked, ids: [...this.selected] });
    },
    toggleCheckbox(checked, id) {
      if (checked) {
        this.selectId(id);
      } else {
        this.unselectId(id);
      }

      this.$emit('checkbox-toggle', { checked, id });
    },
    resetSelected() {
      this.selected = [];
    },
    // context menu
    handleHeaderContextMenu(event, context) {
      this.$emit('contextmenu:header', { event, context });
    },
    handleBodyContextMenu(event, context) {
      this.$emit('contextmenu:body', { event, context });

      // the clipboard context menu will not trigger unless cb is called
      this.$emit('contextmenu:clipboard', (params) => {
        this.$refs.clipboardMenu.show({ event, context, ...params });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/components/ui-kit/sl-table/index.scss';
</style>
