var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sl-table-input-wrapper",class:( _obj = {
    'sl-table-input-wrapper--disabled': _vm.disabled || !_vm.editableBg,
    'sl-table-input-wrapper--editable-bg': _vm.editableBg,
    'sl-table-input-wrapper--number': !_vm.isText
  }, _obj[("color--" + _vm.foreground)] = _vm.foreground, _obj ),attrs:{"tabindex":"-1"},on:{"focus":_vm.onInputFocus}},[(_vm.isInputVisible)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.vModel),expression:"vModel"}],ref:"inputRef",staticClass:"sl-table-input",attrs:{"type":"text","maxLength":_vm.maxLength,"disabled":_vm.disabled},domProps:{"value":(_vm.vModel)},on:{"focus":_vm.onInputFocus,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();return _vm.$refs.inputRef.blur()},"blur":function($event){$event.stopPropagation();return _vm.onSubmit($event)},"input":function($event){if($event.target.composing){ return; }_vm.vModel=$event.target.value}}}):_c('div',{staticClass:"sl-table-input-text",class:{
      'sl-table-input-text--labeled': _vm.prependLabel
    }},[(_vm.prependLabel)?_c('span',{staticClass:"sl-table-input-text__label"},[_vm._v(" "+_vm._s(_vm.prependLabel)+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.inputTextValue)+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }