var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SlModal',{attrs:{"id":_vm.id,"title":_vm.modalTitle,"loading":_vm.isUploading,"persistent":_vm.isUploading},on:{"on-enter":function($event){return _vm.$emit('enter', _vm.handleUpload)},"hidden":_vm.onModalHide},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('SlModalFooter',{attrs:{"between":""}},[_c('div',{staticClass:"sl-modal__footer-group"},[(_vm.$scopedSlots.footer)?[_vm._t("footer",null,null,{
              handleCancel: _vm.handleCancel,
              handleUpload: _vm.handleUpload,
              newFile: _vm.newFile
            })]:[_c('SlButton',{attrs:{"variant":"secondary","color":"grey"},on:{"click":_vm.handleCancel}},[_vm._v(" "+_vm._s(_vm.$t('Common.Cancel'))+" ")]),_c('SlButton',{on:{"click":_vm.handleUpload}},[_vm._v(" "+_vm._s(_vm.$t('Web.Modals.BtnSave'))+" ")])]],2)])]},proxy:true}],null,true)},[_c('div',{staticClass:"modal-content"},[_c('ValidationObserver',{ref:"reUploadObserver"},[_c('SlValidate',{attrs:{"vid":"file","rules":_vm.uploadConfigByFormat[_vm.fileFormat].validate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var invalid = ref.invalid;
            var validate = ref.validate;
return [_c('SlDropzone',{ref:"dropzone",attrs:{"accepted":_vm.uploadConfigByFormat[_vm.fileFormat].accepted,"validator":validate,"is-invalid":invalid},on:{"files-updated":_vm.handleFilesUpdated},scopedSlots:_vm._u([{key:"accepted",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t(_vm.uploadConfigByFormat[_vm.fileFormat].label))+" ")]},proxy:true}],null,true)})]}}])})],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.errorData)?_c('SlAlert',{attrs:{"type":"error","text":decodeURI(_vm.errorData.message)},scopedSlots:_vm._u([{key:"link",fn:function(){return [(_vm.errorData.link)?_c('SlLink',{on:{"click":_vm.handleOpenChat}},[_vm._v(" "+_vm._s(_vm.$t('Web.Modals.Error.ContactSupport'))+" ")]):_vm._e()]},proxy:true}],null,false,1218091234)}):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }