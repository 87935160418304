import demandApi from '@/api/demand';
import KPIDataResponseAdapter from '@/adapters/response/KPIDataResponse.adapter';

const types = {
  SET_KPI: 'SET_KPI'
};

const state = () => ({
  kpi: null
});

const getters = {};

const mutations = {
  [types.SET_KPI](state, value) {
    state.kpi = value;
  }
};

const actions = {
  async fetchKPI({ commit, rootGetters }) {
    try {
      const response = await demandApi.getTreeNodeKpi({
        nodeId: rootGetters['demand/tree/activeNodeId']
      });

      if (response?.data) {
        commit(
          types.SET_KPI,
          KPIDataResponseAdapter({
            data: response.data,
            supplyUnit: rootGetters['settings/supplyUnit']
          })
        );
      }
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchKPI' });
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
