import Vue from 'vue';
import i18n from '@/plugins/vue-i18n';
import treeApi from '@/api/tree';
import demandApi from '@/api/demand';
import { getNoteKey } from '@/helpers/shared/note';
import regExp from '@/helpers/utils/regExp';
import statusCodes from '@/config/utils/statusCodes.config';

const types = {
  SET_CURRENT_NOTE: 'SET_CURRENT_NOTE',
  SET_NODE_NOTES: 'SET_NODE_NOTES',
  SET_CURRENT_NODE_THING: 'SET_CURRENT_NODE_THING',
  SET_CURRENT_CELL_INFO: 'SET_CURRENT_CELL_INFO',
  SET_CAN_EDIT_NOTE: 'SET_CAN_EDIT_NOTE',
  SET_NOTE_TYPE: 'SET_NOTE_TYPE',
  RESET_NODE_NOTES: 'RESET_NODE_NOTES'
};

const initialState = () => ({
  currentNote: null,
  nodeNotes: {},
  nodeThing: null,
  cellInfo: null,
  canEditNote: false,
  noteType: null
});

const state = initialState();

const getters = {
  hasNotes: state => Object.keys(state.nodeNotes).length
};

const mutations = {
  [types.SET_CURRENT_NOTE](state, value) {
    state.currentNote = value;
  },
  [types.SET_NODE_NOTES](state, { key, value }) {
    Vue.set(state.nodeNotes, key, value);
  },
  [types.SET_CURRENT_NODE_THING](state, value) {
    state.nodeThing = value;
  },
  [types.SET_CAN_EDIT_NOTE](state, value) {
    state.canEditNote = value;
  },
  [types.SET_NOTE_TYPE](state, value) {
    state.noteType = value;
  },
  [types.SET_CURRENT_CELL_INFO](state, value) {
    state.cellInfo = value;
  },
  [types.RESET_NODE_NOTES](state) {
    state.nodeNotes = {};
  }
};

const actions = {
  async fetchNote({ commit }, { update =  false, ...payload }) {
    try {
      const key = getNoteKey(payload);

      if (state.nodeNotes[key] && !update) {
        return;
      }

      const response = await treeApi.getNote(payload);
      const value = response.data;

      commit(types.SET_NODE_NOTES, { key, value });

      return value;
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchNote' });
    }
  },
  async updateNote({ state, dispatch }, newNote) {
    try {
      newNote = newNote.replace(regExp.spacesOrNewlines, '') ? newNote : '';

      const params = {
        type: state.nodeThing.type,
        item: state.nodeThing.item,
        loc: state.nodeThing.loc || '',
        chan: state.nodeThing.chan || ''
      };
      const body = {
        text: newNote
      };

      const response = await treeApi.updateNote(params, body);

      if (response.status === statusCodes.OK) {
        const mainMessage = newNote ? 'Web.Notification.SavedSuccessfully' : 'Web.Notification.ClearedSuccessfully';

        Vue.notify({
          type: 'success',
          title: i18n.t(mainMessage, { 1: i18n.t('Main.Cols.Note') })
        });
      }

      const note = await dispatch('fetchNote', { ...state.nodeThing, update: true });

      return dispatch('setCurrentNote', {
        note,
        canEdit: state.canEditNote,
        type: state.noteType
      });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'updateNote' });
    }
  },
  async updateCellNote({ state }, newNote) {
    try {
      newNote = newNote.replace(regExp.spacesOrNewlines, '') ? newNote : '';

      const payload = {
        item: state.nodeThing.item,
        location: state.nodeThing.loc,
        channel: state.nodeThing.chan,
        col: state.cellInfo.position,
        text: newNote,
        row: {
          name: state.cellInfo.name,
          ...(state.cellInfo.index !== undefined && { index: +state.cellInfo.index })
        }
      };

      const response = await demandApi.updateCellNote(payload);

      if (response.status === statusCodes.OK) {
        const mainMessage = newNote ? 'Web.Notification.SavedSuccessfully' : 'Web.Notification.ClearedSuccessfully';

        Vue.notify({
          type: 'success',
          title: i18n.t(mainMessage, { 1: i18n.t('Main.Cols.Note') })
        });
      }
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'updateCellNote' });
    }
  },
  setCurrentNote({ commit }, { note, canEdit, type }) {
    commit(types.SET_CURRENT_NOTE, note?.body ? note : null);
    commit(types.SET_CAN_EDIT_NOTE, canEdit);
    commit(types.SET_NOTE_TYPE, type);
  },
  setCurrentNodeThing({ commit }, value) {
    commit(types.SET_CURRENT_NODE_THING,  value);
  },
  setCurrentCellInfo({ commit }, value) {
    commit(types.SET_CURRENT_CELL_INFO,  value);
  },
  resetNodeNotes({ commit }) {
    commit(types.RESET_NODE_NOTES);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
