<template>
  <div
    class="sl-select-wrapper"
    :class="{
      'sl-select--inline': inline,
      'sl-select--loading': loading,
      'sl-select--clearable': isClearable
    }"
  >
    <div
      v-if="selectLabel"
      class="sl-select__label"
    >
      <div
        :class="[{
          'sl-required': required
        }, 'sl-select__label-title body-1-md grey-80',
        ]"
      >
        {{ selectLabel }}
        <slot
          v-if="$scopedSlots.icon"
          name="icon"
        />
      </div>
      <div class="sl-select__label-description body-3 grey-60">
        {{ selectLabelDescr }}
      </div>
    </div>
    <Multiselect
      ref="multiselect"
      v-select-overflow="{
        appendToBody,
        optionSelector: appendToBodyOptionSelector,
        openDirection
      }"
      :value="selectValue"
      :options="options"
      :track-by="localTrackBy"
      :label="localLabel"
      placeholder=""
      :disabled="localDisabled"
      :multiple="multiple"
      :taggable="taggable"
      :searchable="searchable"
      :group-values="groupValues"
      :group-label="groupLabel"
      :group-select="groupSelect"
      :close-on-select="closeOnSelect"
      :clear-on-select="clearOnSelect"
      :max-height="maxHeight"
      :allow-empty="allowEmpty"
      :show-no-options="showNoOptions"
      :internal-search="internalSearch"
      :preserve-search="preserveSearch"
      :show-labels="false"
      :show-pointer="false"
      class="sl-select"
      :class="{
        'sl-select--invalid': isInvalid,
        'sl-select--disabled': localDisabled,
        'sl-select--multiple': multiple,
        'sl-select--tagged': multiple && selectValue.length,
      }"
      @open="$emit('open')"
      @close="$emit('close')"
      @remove="(option, id) => $emit('remove', option, id)"
      @input="setSelected"
      @search-change="searchChange"
    >
      <template
        v-for="(_, slot) in $scopedSlots"
        #[slot]="scope"
      >
        <slot
          :name="slot"
          v-bind="scope || {}"
        />
      </template>

      <template #tag="{ option, remove, search }">
        <slot
          v-if="$scopedSlots.tag"
          name="tag"
          v-bind="{
            option,
            remove,
            search
          }"
        />
        <SlTag
          v-else
          clearable
          @clear="remove(option)"
        >
          {{ option[localLabel] || option }}
        </SlTag>
      </template>

      <template #placeholder>
        {{ localPlaceholder }}
      </template>

      <template #caret="{ toggle }">
        <div class="sl-select__caret-wrapper">
          <SlLoader
            v-if="updating"
            size="3xs"
            :text="false"
            inline
          />
          <icon
            data="@icons/chevron_down.svg"
            class="sl-select__caret fill-off size-16"
            @mousedown.prevent.stop="toggle"
          />
        </div>
      </template>

      <template #clear>
        <SlButton
          v-if="isClearable"
          size="xs"
          variant="tertiary"
          color="grey"
          class="sl-select__clear"
          icon
          @click.prevent.stop="clearAll"
        >
          <icon
            data="@icons/close.svg"
            class="size-16"
          />
        </SlButton>
      </template>

      <template
        #option="{ option }"
        tabindex="-1"
      >
        <slot
          v-if="$scopedSlots.selectOption"
          name="selectOption"
          v-bind="{
            option,
            selected: isOptionSelected(option)
          }"
        />
        <template v-else>
          {{ option[localLabel] || option }}

          <icon
            v-if="isOptionSelected(option)"
            data="@icons/check.svg"
            class="fill-off size-16 color-primary-80 check-icon"
          />
        </template>
      </template>

      <template #afterList>
        <transition name="fade">
          <div
            v-if="loading"
            class="sl-select__loader"
          >
            <SlLoader
              size="xs"
              :text="$t('Web.Loading.LoadingData')"
              inline
            />
          </div>
        </transition>
      </template>

      <template #noOptions>
        <div class="sl-select__additional-info">
          <slot
            v-if="$scopedSlots.noOptions"
            name="noOptions"
          />
          <template v-else>
            {{ $t('Web.Select.NoOptions') }}
          </template>
        </div>
      </template>

      <template #noResult>
        <div class="sl-select__additional-info">
          <slot
            v-if="$scopedSlots.noResult"
            name="noResult"
          />
          <template v-else-if="!loading">
            {{ $t('Web.Select.NoSearchResults') }}
          </template>
        </div>
      </template>
    </Multiselect>
  </div>
</template>

<script>
import { toArray } from '@/helpers/utils/toArray';

export default {
  name: 'SlSelect',
  props: {
    value: {
      type: [String, Number, Object, Array, null],
      default: null
    },
    options: {
      type: Array,
      default: () => ([])
    },
    selectLabel: {
      type: String,
      default: ''
    },
    selectLabelDescr: {
      type: String,
      default: ''
    },
    trackBy: {
      type: String,
      default: 'value'
    },
    label: {
      type: String,
      default: 'label'
    },
    placeholder: {
      type: String,
      default: ''
    },
    closeOnSelect: {
      type: Boolean,
      default: true
    },
    clearOnSelect: {
      type: Boolean,
      default: true
    },
    internalSearch: {
      type: Boolean,
      default: true
    },
    showNoOptions: {
      type: Boolean,
      default: true
    },
    maxHeight: {
      type: Number,
      default: 300
    },
    openDirection: {
      type: String,
      default: 'auto',
      validator: (d) => ['auto', 'top', 'bottom'].includes(d)
    },
    groupValues: {
      type: [String, undefined],
      default: undefined
    },
    groupLabel: {
      type: [String, undefined],
      default: undefined
    },
    groupSelect: Boolean,
    taggable: Boolean,
    isInvalid: Boolean,
    disabled: Boolean,
    multiple: Boolean,
    searchable: Boolean,
    allowEmpty: Boolean,
    required: Boolean,
    inline: Boolean,
    // work for single select now
    returnObject: Boolean,
    loading: Boolean,
    appendToBody: {
      type: Boolean,
      default: true
    },
    appendToBodyOptionSelector: {
      type: String,
      default: ''
    },
    clearable: Boolean,
    updating: Boolean,
    preserveSearch: Boolean
  },
  computed: {
    isOptionObject() {
      if (this.options && this.options.length) {
        return this.options.some(option => typeof option === 'object');
      }

      return false;
    },
    isValueObject() {
      if (!this.value) {
        return false;
      }

      return typeof this.value === 'object';
    },
    localLabel() {
      return this.isOptionObject ? this.label : undefined;
    },
    localTrackBy() {
      return this.isOptionObject ? this.trackBy : undefined;
    },
    selectValue() {
      if (this.multiple) {
        return toArray(this.value);
      }

      return this.getSelectedOptions(this.value);
    },
    localPlaceholder() {
      if (this.disabled) {
        return '';
      }

      if (this.searchable) {
        return this.placeholder || this.$t('Web.Select.TypeToSearch');
      }

      return this.placeholder;
    },
    localDisabled() {
      return this.disabled || this.updating;
    },
    groupOptions() {
      return this.options.reduce((acc, group) => {
        acc.push(...group[this.groupValues]);

        return acc;
      }, []);
    },
    isClearable() {
      const value = Array.isArray(this.selectValue) ? this.selectValue.length : this.selectValue;

      return this.clearable && value;
    }
  },
  methods: {
    getSelectedOptions(value) {
      const options = this.groupValues ? this.groupOptions : this.options;

      return options.find(option => {
        const optionValue = option[this.localTrackBy] ?? option;
        const modelValue = value?.[this.trackBy] ?? value;

        return optionValue === modelValue;
      });
    },
    isOptionSelected(option) {
      const optionValue = option[this.localTrackBy] ?? option;

      if (this.multiple) {
        return this.value?.some(item => item?.[this.localTrackBy] === optionValue || item === optionValue);
      }

      return this.returnObject && this.isValueObject
        ? this.value?.[this.localTrackBy] === optionValue
        : this.value === optionValue;
    },
    setSelected(value) {
      if (this.multiple) {
        return this.$emit('input', value);
      }

      const newValue = this.returnObject
        ? value
        : value?.[this.localTrackBy] !== undefined
          ? value?.[this.localTrackBy]
          : value;

      this.$emit('input', newValue);
    },
    searchChange(value) {
      this.$emit('search-change', value);
    },
    clearAll() {
      const value = this.multiple ? [] : '';

      this.$emit('input', value);
      this.$emit('clear', value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/ui-kit/sl-select/index.scss";
</style>
