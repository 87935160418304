import moment from 'moment';
import { autoUpdateTypes } from '@/config/shared/settings.config';

const prepareToXml = (data) => {
  return Object.keys(data).reduce((acc, key) => {
    if (typeof data[key] === 'boolean') {
      acc[key] = { _val: +data[key] };
    } else {
      acc[key] = { _val: data[key] };
    }

    return acc;
  }, {});
};

const prepareAutomation = (data) => {
  let res = {
    enable: {
      _val: +data.enable
    },
    type: {
      _val: data.type
    }
  };

  if (data.type === autoUpdateTypes.DAILY) {
    const value = moment(data.dailyModel, ['h:mm A']).format('HH:mm');
    const [hour, minute] = value.split(':');
    const time = moment({ hour: +hour, minute: +minute })
      .utc()
      .format('HH:mm:SS');

    res = {
      ...res,
      time: {
        time
      },
      period: {
        _val: 86400 // 1 day in sec
      }
    };
  }

  if (data.type === autoUpdateTypes.BY_HOURS) {
    const [hour, minute] = data.everyModel.split(':');
    const time = moment({ hour: +hour, minute: +minute })
      .utc()
      .format('HH:mm:SS');

    const seconds = (+hour * 3600) + (+minute * 60);

    res = {
      ...res,
      time: {
        time
      },
      period: {
        _val: seconds
      }
    };
  }

  if (data.fgs !== undefined) {
    res = {
      ...res,
      fgs: {
        _val: data.fgs
      }
    };
  }

  return res;
};

export default function(data) {
  const keys = Object.keys(data);

  const preparedToXml = keys.reduce((obj, key) => {
    if (typeof data[key] !== 'object') {
      if (typeof data[key] === 'boolean') {
        obj[key] = { _val: +data[key] };
      } else {
        obj[key] = { _val: data[key] };
      }

      return obj;
    }

    if (key.includes('abc') || key.includes('xyz') || ['inventory', 'distrib'].includes(key)) {
      obj[key] = prepareToXml(data[key]);

      return obj;
    }

    if (['autoUpdate', 'autoExport', 'autoUpdateConstrainedOrderingPlan'].includes(key)) {
      obj[key] = prepareAutomation(data[key]);

      return obj;
    }

    obj[key] = data[key];

    return obj;
  }, {});

  if (preparedToXml.upperConfLevel) {
    preparedToXml.upperConfLevel._val = preparedToXml.upperConfLevel._val / 100;
  }

  if (preparedToXml.lowerConfLevel) {
    preparedToXml.lowerConfLevel._val = preparedToXml.lowerConfLevel._val / 100;
  }

  if (preparedToXml.inventory?.serviceLevel) {
    preparedToXml.inventory.serviceLevel._val = preparedToXml.inventory.serviceLevel._val * 10;
  }

  if (preparedToXml.distrib?.serviceLevel) {
    preparedToXml.distrib.serviceLevel._val = preparedToXml.distrib.serviceLevel._val * 10;
  }

  delete preparedToXml?.dashboardSettings;
  delete preparedToXml?.curvesVisibility;
  delete preparedToXml?.massTopDownItems;

  return { settings: preparedToXml };
}
