<template>
  <div class="connector-wrap connector-wrap--simple">
    <div class="connector-content-block__row">
      <SlControl
        v-model="authType"
        :options="authTypeOptions"
        :label="$t('Web.BaseConnectors.Form.authType')"
      />
    </div>
    <div class="connector-content-block__row w-320">
      <SlValidate
        v-slot="{ invalid }"
        vid="shop-name"
        mode="eager"
        rules="required"
      >
        <SlInput
          v-model="shopName"
          :label="$t('Web.BaseConnectors.Form.shopName')"
          :is-invalid="invalid"
        />
      </SlValidate>
    </div>
    <div
      v-if="isCustomApp"
      class="connector-content-block__row w-512"
    >
      <SlValidate
        v-slot="{ invalid }"
        vid="api-key"
        mode="eager"
        rules="required"
      >
        <SlInput
          v-model="apiKey"
          :label="$t('Web.BaseConnectors.Form.APIkey')"
          :is-invalid="invalid"
        />
      </SlValidate>
    </div>
    <div
      v-if="isCustomApp"
      class="connector-content-block__row w-512"
    >
      <SlValidate
        v-slot="{ invalid }"
        vid="api-access-token"
        mode="eager"
        rules="required"
      >
        <SlPasswordInput
          v-model="apiAccessToken"
          :label="$t('Web.BaseConnectors.Form.APIaccessToken')"
          :is-invalid="invalid"
        />
      </SlValidate>
    </div>
    <Periods
      v-if="!noPeriods"
      :type="type"
      :is-new="isNew"
    />
  </div>
</template>

<script>
import Periods from '@/components/Connections/Common/Periods.vue';
import { connection } from '@/mixins/connection';
import { connectionTypes, shopifyAuthTypes } from '@/config/connection';

export default {
  name: 'ShopifyRequiredTab',
  components: {
    Periods
  },
  mixins: [connection],
  props: {
    isNew: Boolean,
    noPeriods: Boolean,
    connectorType: {
      type: String,
      default: ''
    }
  },
  computed: {
    type() {
      return this.connectorType || connectionTypes.SHOPIFY;
    },
    connectionFiled() {
      return this.type === connectionTypes.SHOPIFY ? 'required' : 'optional';
    },
    isCustomApp() {
      return this.authType === shopifyAuthTypes.CUSTOM;
    },
    authTypeOptions() {
      return [
        {
          label: this.$t('Web.BaseConnectors.Form.customApp'),
          value: shopifyAuthTypes.CUSTOM
        },
        {
          label: this.$t('Web.BaseConnectors.Form.oauth'),
          value: shopifyAuthTypes.OAUTH
        }
      ];
    },
    authType: {
      get() {
        return this.connectionData[this.connectionFiled].authType;
      },
      set(value) {
        this.setConnectorValueByKey({
          type: this.type,
          field: this.connectionFiled,
          key: 'authType',
          value
        });
      }
    },
    authTypeLabel() {
      return this.authTypeOptions.find(option => option.value === this.authType).label;
    },
    shopName: {
      get() {
        return this.connectionData[this.connectionFiled].shopName;
      },
      set(value) {
        this.setConnectorValueByKey({
          type: this.type,
          field: this.connectionFiled,
          key: 'shopName',
          value
        });
      }
    },
    apiKey: {
      get() {
        return this.connectionData[this.connectionFiled].apiKey;
      },
      set(value) {
        this.setConnectorValueByKey({
          type: this.type,
          field: this.connectionFiled,
          key: 'apiKey',
          value
        });
      }
    },
    apiAccessToken: {
      get() {
        return this.connectionData[this.connectionFiled].apiAccessToken;
      },
      set(value) {
        this.setConnectorValueByKey({
          type: this.type,
          field: this.connectionFiled,
          key: 'apiAccessToken',
          value
        });
      }
    }
  }
};
</script>
