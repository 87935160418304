import axios from 'axios';
import axiosRetry from 'axios-retry';
import https from 'https';
import {
  requestSuccess,
  requestError,
  responseSuccess,
  responseError
} from '@/api/helpers/interceptors';
import config from '@/config/global.config';
import statusCodes from '@/config/utils/statusCodes.config';
import { thirdPartyServiceErrorCodes } from '@/config/api/slErrorCodes.config';
import { removeIdempotencyKey } from '@/api/helpers/idempotentRequests';

const $axios = axios.create({
  baseURL: config.BASE_URL + '/api',
  httpsAgent: new https.Agent({
    rejectUnauthorized: false
  }),
  withCredentials: true
});

axiosRetry($axios, {
  retries: 5,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: (error) => {
    const isThirdPartyServiceError = thirdPartyServiceErrorCodes.includes(error?.response?.data?.code);

    if (isThirdPartyServiceError) {
      return true;
    }

    const isRetryRoute = !['/operations', '/manage_projects/list'].some(route => error?.config?.url?.includes?.(route));
    const isRetryCode = [statusCodes.CF_TIMEOUT, statusCodes.BAD_GATEWAY].includes(error?.response?.status);

    return isRetryRoute && isRetryCode;
  },
  onRetry(_, __, config) {
    removeIdempotencyKey(config);
  }
});

$axios.defaults.headers.common['Accept-Language'] = 'en';
$axios.defaults.headers.common['X-SL-Lang'] = 'en';
$axios.defaults.headers.common['Cache-Control'] = 'no-cache';
$axios.interceptors.response.use(responseSuccess, responseError);
$axios.interceptors.request.use(requestSuccess, requestError);

export default $axios;
