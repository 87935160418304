import { treeItemStates as itemStates } from '@/config/demand/tree/tree.config';

// To get itemState param for tree/toggleAttentionItemState action in case of 'Needs Attention' button click
export const getAttentionToggleStatus = (itemState) => {
  return [
    itemStates.WARN_ABANDONED,
    itemStates.WARNING,
    itemStates.WARN_MIXED
  ].includes(itemState)
    ? itemStates.NORMAL
    : itemStates.WARNING;
};

// To get itemState param for tree/toggleCheckedItemState action in case of 'Checked' button click
export const getCheckedToggleStatus = (itemState) => {
  return itemState === itemStates.CHECKED
    ? itemStates.NORMAL
    : itemStates.CHECKED;
};

export const getOpenNodes = (nodes) => {
  return nodes.reduce((acc, node, nodeIndex) => {
    if (node.hasChilds && (node.depth < nodes[nodeIndex + 1]?.depth)) {
      acc.push(node.nodeId);
    }

    return acc;
  }, []);
};
