import demandApi from '@/api/demand';

const types = {
  SET_INV: 'SET_INV'
};

const state = () => ({
  invParamObj: null
});

const getters = {
};

const mutations = {
  [types.SET_INV](state, value) {
    state.invParamObj = value;
  }
};

const actions = {
  async fetchInv({ commit, rootGetters }) {
    const item = rootGetters['demand/tree/itemName'];
    const location = rootGetters['demand/tree/location'] || '';

    if (item) {
      try {
        const response = await demandApi.getInventoryTabParams({
          item,
          location
        });

        if (response?.data) {
          commit(
            types.SET_INV,
            response.data.invParams
          );
        }
      } catch (e) {
        this.dispatch('user/logout', { e, from: 'fetchInv' });
      }
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
