export default function({ data, supplyUnit }) {
  const kpis = data.kpis;

  if (supplyUnit === 'month') {
    const index = kpis?.findIndex(item => item.class === 'DaysOfSupply');

    if (index !== -1) {
      kpis[index].code = 'Main.Cols.MonthsOfSupply';
    }
  }

  return kpis?.map(el => {
    const name = el.name.code || el.name.entries?.map(i => i.val);
    const data = el.val === 'nan' ? '' : el.val;

    return {
      name,
      data
    };
  });
}
