<template>
  <div
    id="app"
    data-app
    :class="[
      'v-application--is-ltr',
      theme
    ]"
  >
    <transition name="fade">
      <SlButton
        v-if="hasPendingTasks"
        variant="tertiary"
        class="task-loading-button"
      >
        <template #prepend>
          <SlLoader
            size="3xs"
            :text="false"
            inline
          />
        </template>
        {{ $t('Web.ApplyingOverrides') }}
      </SlButton>
    </transition>
    <SlOverlay :show="initializing">
      <SlLoader :text="false" />
    </SlOverlay>
    <!--  should be hidden until the initialization is complete  -->
    <transition name="fade">
      <router-view v-if="!initializing" />
    </transition>
    <notifications
      position="bottom right"
      classes="sl-notification"
    >
      <template #body="{ item, close }">
        <SlNotification
          :item="item"
          :close="close"
        />
      </template>
    </notifications>
    <SlConfirmModal />
    <SlRenameModal />
    <SlLoadingModal />
    <SlErrorModal />
    <SlImportFileModal />
    <CreateProjectModal />
    <MetaInfoIssuesModal />
    <UpdateWithLogsModal />
    <FreezeSimulationModal />
    <UpgradeToEnterpriseModal />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import SlNotification from '@/components/UIKit/SlNotification';
import CreateProjectModal from '@/components/Modals/CreateProjectModal.vue';
import MetaInfoIssuesModal from '@/components/Modals/Warnings/MetaInfoIssuesModal.vue';
import UpdateWithLogsModal from '@/components/Modals/UpdateWithLogsModal.vue';
import FreezeSimulationModal from '@/components/Modals/FreezeSimulationModal.vue';
import UpgradeToEnterpriseModal from '@/components/Modals/UpgradeToEnterprise.vue';
import { modal } from '@/mixins/modal';
import { access } from '@/mixins/access';
import { shortcut } from '@/mixins/shortcut';
import { metaInfo } from '@/mixins/metaInfo';
import { initialization } from '@/mixins/initialization';
import { fileSaver } from '@/mixins/webAPI';
import { APP_NAME } from '@/config/app.config.js';
import { updateData, logLevel } from '@/config/project/updateData.config';
import { operationInfoTypes, updateFgsFlags } from '@/config/api/operations.config';
import modalsId from '@/config/shared/modalsId.config';
import { registerReportModules } from '@/helpers/report';
import { openLink } from '@/helpers/shared/webAPI';
import { preventDefault } from '@/helpers/shared/listeners';
import { $amp_logEvent } from '@/plugins/product/amplitude';
import { $cwd_embedScript, $cwd_isInTranslationMode } from '@/plugins/localization/crowdin';
import { namespaceByRoute } from '@/config/report';
import { slErrorCodes } from '@/config/api/slErrorCodes.config';

export default {
  name: 'App',
  components: {
    MetaInfoIssuesModal,
    SlNotification,
    CreateProjectModal,
    UpdateWithLogsModal,
    FreezeSimulationModal,
    UpgradeToEnterpriseModal
  },
  mixins: [modal, access, shortcut, metaInfo, initialization, fileSaver],
  data() {
    return {
      modalsId
    };
  },
  provide() {
    return {
      handleReImport: this.reimportCallback
    };
  },
  computed: {
    ...mapState({
      theme: (state) => state.theme,
      initializing: (state) => state.initialization.initializing,
      isDemandTableUpdating: (state) => state.demand.table.is_updating
    }),
    ...mapGetters({
      projectLabel: 'project/projectLabel',
      isMultipleConnections: 'project/isMultipleConnections',
      isSpreadsheetConnection: 'spreadsheet/isSpreadsheetConnection',
      isSourceFile: 'spreadsheet/isSourceFile'
    }),
    isReportTableUpdating() {
      return this.$store.state[namespaceByRoute[this.$sl_routeName]]?.is_updating;
    },
    hasPendingTasks() {
      return this.isDemandTableUpdating || this.isReportTableUpdating;
    }
  },
  watch: {
    projectLabel(label) {
      if (!label) {
        return document.title = APP_NAME;
      }

      document.title = `${label} - ${APP_NAME}`;
    },
    'authState.user.accessToken'(newToken, oldToken) {
      if (newToken && newToken !== oldToken) {
        this.setToken(newToken);

        if (!this.initializing) {
          this.appInit();
        }
      }
    },
    'authState.isLoading'(isLoading, wasLoading) {
      if (!isLoading && wasLoading) {
        this.loginIfNeeded();
      }
    },
    $sl_teamMembersLength(newLength, oldLength) {
      if (newLength < oldLength) {
        this.$sl_loadTeamMembers();
      }
    }
  },
  beforeCreate() {
    registerReportModules();
  },
  mounted() {
    // prevent default browser validation messages
    document.addEventListener('invalid', preventDefault, true);

    this.setupCrowdin();
  },
  beforeDestroy() {
    document.removeEventListener('invalid', preventDefault);
  },
  methods: {
    ...mapActions({
      reImport: 'project/reImport',
      setIsProjectUpdating: 'project/setIsProjectUpdating',
      fetchSpreadsheetInfo: 'spreadsheet/fetchSpreadsheetInfo',
      getConnectionInfo: 'connection/getConnectionInfo',
      reImportMultiple: 'integrations/reimportProject',
      clearTreeState: 'demand/tree/resetState'
    }),
    async handleReImport({ isMultiple, ...params }) {
      try {
        this.setIsProjectUpdating(true);

        const subscriber = isMultiple ? this.reImportMultiple : this.reImport;

        await this.$store.dispatch('operations/subscribe', {
          subscriber: () => subscriber(params),
          paused: ({ operationData, type }) => {
            if (operationData.url && type === operationInfoTypes.NEED_OAUTH) {
              openLink(operationData.url, true);
            }
          },
          ui_flags: async({ operationId, flags }) => {
            if (flags.includes(updateFgsFlags.IMPORT_META_INFO)) {
              const metaInfo = await this.fetchMetaInfo(operationId);

              this.handleImportMetaInfo(metaInfo, operationId);
            }
          },
          finished: this.clearTreeState
        });

        this.$notify({
          type: 'success',
          title: this.$t('Web.UpdateData.Success')
        });

        if (this.isSpreadsheetConnection) {
          await this.getConnectionInfo();
          await this.fetchSpreadsheetInfo();
        }
      } catch (e) {
        const error = e.error || e;
        const isWarning = error.code === slErrorCodes.IMPORT_NO_DATA;

        this.$notify({
          type: isWarning ? 'warn' : 'error',
          text: this.isMultipleConnections
            ? `${error?.message}\n${this.$t('Web.Integrations.Errors.FixError')}`
            : isWarning
              ? error?.message
              : this.$t('ProjectChanges.Other.UpdateFailed', { 1: error?.message }),
          duration: -1
        });
      } finally {
        this.setIsProjectUpdating(false);

        $amp_logEvent('Project reimport');
      }
    },
    async reimportCallback(type = updateData.FULL, logLevelParam = logLevel.NORMAL, storeResult = false) {
      if (!this.$sl_hasAccess(this.$sl_features.updatePrj)) {
        return;
      }

      const params = { type, logLevel: logLevelParam, storeResult };

      if (this.isMultipleConnections) {
        return this.handleReImport({
          ...params,
          isMultiple: true
        });
      }

      if (this.isSpreadsheetConnection) {
        await this.fetchSpreadsheetInfo();

        if (this.isSourceFile) {
          return this.showModal(modalsId.SPREADSHEET_UPDATE_DATA, {
            reimportCallback: (reimportData) => this.handleReImport({ ...params, reimportData }),
            reimportType: type
          });
        }
      }

      this.handleReImport(params);
    },
    setupCrowdin() {
      if ($cwd_isInTranslationMode()) {
        $cwd_embedScript();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/utils/color";

#app {
  max-height: 100vh;
  height: 100vh;

  .task-loading-button {
    position: absolute;
    bottom: 20px;
    right: 20px;
    // vue-notification-group has 5000 z-index
    z-index: 4000;

    background: $white;
    border-radius: 20px;
    box-shadow: 0 2px 4px -2px #1219260F, 0 5px 12px -3px #1219261F, 0 0 6px 0 #1219261A;
  }
}
</style>
