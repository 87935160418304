export const LICENSE_EXPIRE_START_DAYS = 14;
export const UNLIM_USERS_COUNT = 999999;

export const licenseStatuses = {
  OK: 'ok',
  EXPIRE_SOON: 'willExpireSoon',
  GRACE_PERIOD: 'gracePeriod',
  EXPIRED: 'expired'
};

export const accountPlans = {
  FREE: 'free',
  ENTERPRISE: 'enterprise'
};
