import axios from '@/plugins/axios';

export default {
  getCharts(params) {
    return axios.get('/chart', {
      params
    });
  },
  getCurvesVisibility() {
    return axios.get('/chart/curve');
  },
  updateCurveVisibility(params) {
    return axios.put('/chart/curve', null, { params });
  }
};
