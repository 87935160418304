import {
  chartDatasetLegend,
  defaultLineConfig,
  lineConfigByKey
} from '@/config/demand/chart/chart.config';

const getChartDatasets = (chart, chartLegend, tab) => {
  return chart.series.reduce((acc, line) => {
    if (!line.points.length) {
      return acc;
    }

    const key = line.meaning;

    const data = line.points.map((point) => {
      return {
        x: point.x,
        y: point.y ?? point.yTo ?? undefined,
        y_from: point.yFrom ?? undefined,
        tooltipKey: line.tooltipKey ?? key,
        isOutlier: point.isOutlier
      };
    });

    if (!acc[key]) {
      acc[key] = {
        data,
        ...defaultLineConfig,
        ...lineConfigByKey(key, chartLegend, tab)
      };
    } else {
      acc[key].data.push({}, ...data);
    }

    return acc;
  }, {});
};

export default function({ data, tab, forecastTitle, isColorblind }) {
  const chartLegend = chartDatasetLegend(forecastTitle, isColorblind);
  const datasets = Object.values(getChartDatasets(data, chartLegend, tab));

  datasets.sort((a, b) => a.layoutIndex - b.layoutIndex);

  return {
    type: tab,
    datasets,
    dates: data.dates
  };
}
