<template>
  <SlModal
    :id="id"
    :width="380"
    no-header
    @on-enter="handleProceed"
    @created="onCreated"
    @hide="onModalHide"
  >
    <div class="modal-content sl-confirm-modal">
      <icon
        v-if="icon"
        :data="modalIcon"
        class="fill-off size-48"
      />
      <div class="sl-confirm-modal__title heading-4-sb grey-90">
        {{ title }}
      </div>
      <div class="sl-confirm-modal__text body-1 grey-70">
        {{ text }}
      </div>
    </div>

    <template #footer>
      <SlModalFooter>
        <SlButton
          variant="secondary"
          color="grey"
          full-width
          @click="handleCancel"
        >
          {{ cancelText || $t('Common.Cancel') }}
        </SlButton>
        <SlButton
          v-if="discardText"
          min-width="136px"
          variant="secondary"
          @click="handleDiscard"
        >
          {{ discardText }}
        </SlButton>
        <SlButton
          full-width
          @click="handleProceed"
        >
          {{ confirmText }}
        </SlButton>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { modal } from '@/mixins/modal';
import modalsId from '@/config/shared/modalsId.config';

export default {
  name: 'SlConfirmModal',
  mixins: [modal],
  data() {
    return {
      id: modalsId.SL_CONFIRM_MODAL,
      title: '',
      text: '',
      icon: '',
      confirmText: '',
      discardText: '',
      cancelText: '',
      confirmCallback: null,
      discardCallback: null
    };
  },
  computed: {
    modalIcon() {
      return require(`@icons/${this.icon}.svg`);
    }
  },
  methods: {
    onModalHide() {
      this.title = '';
      this.text = '';
      this.confirmText = '';
      this.discardText = '';
      this.cancelText = '';
      this.confirmCallback = null;
      this.discardCallback = null;
    },
    handleCancel() {
      this.hideModal(this.id);
    },
    handleProceed() {
      this.confirmCallback && this.confirmCallback();
      this.hideModal(this.id);
    },
    handleDiscard() {
      this.discardCallback && this.discardCallback();
      this.hideModal(this.id);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/utils/_color.scss";
@import "@/style/utils/_variable.scss";
@import "@/style/utils/_mixins.scss";

.sl-confirm-modal {
  max-width: 380px;
  text-align: center;

  &__title {
    margin: 8px 0 6px;

    overflow-wrap: break-word;
  }

  &__text {
    color: $grey-80;
    white-space: pre-line;
  }
}
</style>
