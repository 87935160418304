import { getSplitTooltip } from '@/helpers/shared/tooltip';

export const shortcutsConfig = (context) => {
  return {
    GENERAL: {
      title: context.$t('Web.ShortcutsModal.General'),
      values: {
        SWITCH_TABS: {
          title: context.$t('Web.ShortcutsModal.SwitchTabs'),
          shortcut: ['ALT', '1', 'or', '2', 'or', '...']
        },
        SAVE_PROJECT: {
          title: context.$t('Web.ShortcutsModal.SaveProject'),
          shortcut: ['CTRL', 'S']
        },
        UPDATE_DATA: {
          title: context.$t('Web.ShortcutsModal.UpdateData'),
          shortcut: ['CTRL', 'D']
        },
        FREEZE_CALC: {
          title: context.$t('Web.ShortcutsModal.FreezeCalc'),
          shortcut: ['CTRL', 'SHIFT', 'D']
        },
        OPEN_BILL: {
          title: context.$t('Web.ShortcutsModal.OpenBill'),
          shortcut: ['CTRL', 'B']
        },
        OPEN_REMOVAL_RULES: {
          title: context.$t('Web.ShortcutsModal.OpenRemovalRules'),
          shortcut: ['CTRL', 'U']
        },
        OPEN_UPDATE_DATA: {
          title: context.$t('Web.ShortcutsModal.OpenUpdateData'),
          shortcut: ['ALT', 'SHIFT', 'Q']
        },
        OPEN_ACTIVITY_LOGS: {
          title: context.$t('Web.ShortcutsModal.OpenActivityLogs'),
          shortcut: ['CTRL', 'SHIFT', 'Q']
        }
      }
    },
    INVENTORY_REPORTS_TABLE: {
      title: context.$t('Web.ShortcutsModal.InventoryReportsTable'),
      values: {
        COPY_ITEM: {
          title: context.$t('Web.ShortcutsModal.CopyItem'),
          shortcut: ['CTRL', 'C']
        },
        SELECT_ALL_ROWS: {
          title: context.$t('Web.ShortcutsModal.SelectAllRows'),
          shortcut: ['CTRL', 'A']
        },
        OPEN_MATERIAL_PRODUCT_TREE: {
          title: context.$t('Web.ShortcutsModal.OpenMaterialProductTree'),
          shortcut: ['CTRL', 'M']
        },
        REMOVE_FROM_IMPORT: {
          title: context.$t('Web.ShortcutsModal.RemoveFromImport'),
          shortcut: ['ALT', 'DEL']
        }
      }
    },
    DEMAND_TREE: {
      title: context.$t('Web.ShortcutsModal.DemandTree'),
      values: {
        COPY_CODE: {
          title: context.$t('Web.ShortcutsModal.CopyCode'),
          shortcut: ['CTRL', 'C']
        },
        OPEN_MATERIAL_PRODUCT_TREE: {
          title: context.$t('Web.ShortcutsModal.OpenMaterialProductTree'),
          shortcut: ['CTRL', 'M']
        },
        SET_APPROVE_UNAPPROVE: {
          title: context.$t('Web.ShortcutsModal.SetApproveUnapprove'),
          shortcut: ['ALT', 'A']
        },
        SET_ATTENTION: {
          title: context.$t('Web.ShortcutsModal.SetAttention'),
          shortcut: ['ALT', 'N']
        },
        SET_CHECKED: {
          title: context.$t('Web.ShortcutsModal.SetChecked'),
          shortcut: ['ALT', 'C']
        },
        REMOVE_FROM_IMPORT: {
          title: context.$t('Web.ShortcutsModal.RemoveFromImport'),
          shortcut: ['ALT', 'DEL']
        },
        TRUNCATED_PROJECT: {
          title: context.$t('Web.ShortcutsModal.DownloadTruncated'),
          shortcut: ['CTRL', 'ALT', 'SHIFT', 'L'],
          info: getSplitTooltip({
            title: context.$t('Web.ShortcutsModal.TooltipDownloadTruncatedTitle'),
            body: context.$t('Web.ShortcutsModal.TooltipDownloadTruncatedBody')
          })
        }
      }
    }
  };
};

export const winToMacConfig = {
  'ALT': '⌥',
  'CTRL': '⌘',
  'SHIFT': '⇧'
};

export const shortcutDelimiters = (context) => {
  return {
    'or': context.$t('Web.ShortcutsModal.Or'),
    '...': '...'
  };
};

export const bomShortcuts = [
  'OPEN_BILL',
  'OPEN_MATERIAL_PRODUCT_TREE'
];
