import Vue from 'vue';
import backups from './backups';
import vueI18n from '@/plugins/vue-i18n';
import router from '@/router';
import manageProjectsApi from '@/api/manageProjects';
import logger from '@/api/logger';
import { projectListResponseAdapter } from '@/adapters/response/ProjectResponse.adapter';
import { archiveActonTypes, projectStatuses, projectTypes } from '@/config/project';
import { routeNames, mainRoutes } from '@/config/router/router.config';
import { SALES_DEMO_URL } from '@/config/shared/resources.config';
import { notifyLinkTypes } from '@/config/plugins/notify.config';
import { toArray } from '@/helpers/utils/toArray';
import { fileToFormData } from '@/helpers/utils/fileUpload';
import { getRouteName } from '@/helpers/shared/router';
import { resetReportModules } from '@/helpers/report';
import { needCacheUpdate } from '@/helpers/shared/cache';
import { systemRedirect } from '@/helpers/router';
import { openLink } from '@/helpers/shared/webAPI';
import { getIndexAndProjectById } from '@/helpers/manageProjects';

const types = {
  SET_PROJECT_ID: 'SET_PROJECT_ID',
  SET_PROJECT_LIST: 'SET_PROJECT_LIST',
  SET_AVAILABLE_CONNECTORS: 'SET_AVAILABLE_CONNECTORS',
  SET_PROJECT_LIST_INTERVAL: 'SET_PROJECT_LIST_INTERVAL',
  UPDATE_PROJECT_DATA: 'UPDATE_PROJECT_DATA',
  SET_FILTER: 'SET_FILTER',
  SET_PROJECT_EXAMPLES: 'SET_PROJECT_EXAMPLES',
  SET_IS_LOADING: 'SET_IS_LOADING'
};

const state = () => ({
  projectId: null,
  projectList: {},
  availableConnectors: [],
  projectListRefetchInterval: null,
  examples: null,
  filters: {
    running: false,
    bulkEdit: false
  },
  isLoading: false
});

const getters = {
  runningProjects: (state) => {
    return Object.keys(state.projectList).reduce((acc, key) => {
      if (key !== 'timestamp') {
        acc[key] = state.projectList[key].filter(p => p.status === projectStatuses.RUNNING);
      }

      return acc;
    }, {});
  },
  activeProjects: (state) => {
    return [...toArray(state.projectList.permanent), ...toArray(state.projectList.temporary)];
  },
  hasProjectsList: (state) => {
    return state.projectList.permanent?.length + state.projectList.temporary?.length > 0;
  },
  currentProjectMeta: (state, getters) => {
    const projectId = state.projectId;

    if (!projectId) {
      return null;
    }

    return Object.values(getters.activeProjects).flat(1).find(p => p.id === projectId);
  },
  isTimeMachineActive: (state, getters) => {
    const projectId = state.projectId;

    if (!getters.runningProjects.temporary?.length || !projectId) {
      return false;
    }

    const currentTemporaryProject = getters.runningProjects.temporary.find(p => p.id === projectId);

    return currentTemporaryProject?.type === projectTypes.TIME_MACHINE;
  },
  isTimeMachineRunning: (state, getters) => {
    const projectId = state.projectId;

    if (!getters.runningProjects.temporary?.length || !projectId) {
      return false;
    }

    if (getters.isTimeMachineActive) {
      return true;
    }

    return getters.runningProjects.temporary.find(p => p.originId === projectId);
  },
  existingNames: (state) => {
    const allProjects = [
      ...toArray(state.projectList.permanent),
      ...toArray(state.projectList.temporary),
      ...toArray(state.projectList.archived)
    ];

    return allProjects.map(({ name }) => name);
  },
  getProjectById: (_, getters) => (id) => {
    return getters.activeProjects.find(p => p.id === id);
  }
};

const mutations = {
  [types.SET_PROJECT_ID](state, value) {
    state.projectId = value;
  },
  [types.SET_PROJECT_LIST](state, value) {
    state.projectList = value;
  },
  [types.SET_AVAILABLE_CONNECTORS](state, value) {
    state.availableConnectors = value;
  },
  [types.SET_PROJECT_LIST_INTERVAL](state, value) {
    state.projectListRefetchInterval = value;
  },
  [types.UPDATE_PROJECT_DATA](state, { index, value }) {
    Vue.set(state.projectList.permanent, index, value);
  },
  [types.SET_FILTER](state, { key, value }) {
    Vue.set(state.filters, key, value);
  },
  [types.SET_PROJECT_EXAMPLES](state, value) {
    state.examples = value;
  },
  [types.SET_IS_LOADING](state, value) {
    state.isLoading = value;
  }
};

const actions = {
  resetStores(_, resetAccess) {
    this.dispatch('demand/tree/resetState');
    this.dispatch('project/resetItemCodes');
    this.dispatch('changes/resetState');
    this.dispatch('dashboard/resetState');
    resetReportModules();
    localStorage.clear();

    if (resetAccess) {
      this.dispatch('project/resetState');
      this.dispatch('user/resetState');
      this.dispatch('access/resetState');
    }
  },
  async setupCurrentProject() {
    const responses = await Promise.allSettled([
      this.dispatch('project/fetchProjectTabs'),
      this.dispatch('user/fetchUserInfo'),
      this.dispatch('project/fetchProject')
    ]);

    await this.dispatch('user/fetchUserStats');

    const hasFailedRequest = responses.some(res => res.reason);

    if (hasFailedRequest) {
      throw hasFailedRequest;
    }

    await this.dispatch('access/setUserAccess', responses);
  },
  clearCurrentProject({ dispatch }) {
    this.dispatch('lastChanges/clearUpdateInterval');
    dispatch('setProjectId', null);
    dispatch('resetStores', true);
  },
  setProjectId({ commit }, pid) {
    commit(types.SET_PROJECT_ID, pid);
  },
  async pingProject({ dispatch }, { pid }) {
    try {
      dispatch('setProjectId', pid);

      await manageProjectsApi.pingProject({ pid });

      return true;
    } catch (e) {
      dispatch('setProjectId', null);

      throw e;
    }
  },
  async openProject({ state, rootState, rootGetters, dispatch }, {
    pid,
    type = projectTypes.PERMANENT,
    redirect = routeNames.DEMAND
  }) {
    try {
      // used like global loader & page remount for project switch
      this.dispatch('initialization/setInitializing', true);
      this.dispatch('lastChanges/clearUpdateInterval');

      const isUninitialized = type === projectTypes.UNINITIALIZED;
      const sameRoute = redirect === getRouteName();
      const resetAccess = sameRoute || isUninitialized;
      const needLastChange = ![projectTypes.UNINITIALIZED, projectTypes.TIME_MACHINE].includes(type);

      if (pid !== state.projectId) {
        await dispatch('resetStores', resetAccess);
      }

      await dispatch('pingProject', { pid });

      if (!isUninitialized) {
        await dispatch('setupCurrentProject');

        const isEmptyPeriod = !rootGetters['project/period'];
        const isUnsupportedPeriod = !rootGetters['project/isSupportedPeriod'];

        // period guard
        if (isEmptyPeriod || isUnsupportedPeriod) {
          dispatch('clearCurrentProject');

          systemRedirect({
            name: routeNames.PROJECT_LIST
          });

          return Vue.notify({
            type: 'error',
            title: isEmptyPeriod
              ? vueI18n.tc('Web.Error.OpenProjectError')
              : vueI18n.tc('Web.Error.UnsupportedPeriod'),
            text: isEmptyPeriod
              ? ''
              : vueI18n.tc('Web.NoAccess.NotInvitedText'),
            duration: -1
          });
        }
      }

      systemRedirect({
        name: redirect,
        params: {
          ...router.currentRoute.params,
          projectId: pid
        }
      });

      if (needLastChange) {
        this.dispatch('lastChanges/initLastChangeInterval');
      }

      dispatch('getProjectList');
    } catch (e) {
      dispatch('clearCurrentProject');

      if (e?.message) {
        Vue.notify({
          type: 'error',
          text: e?.message
        });
      }

      systemRedirect({
        name: routeNames.PROJECT_LIST
      });
    } finally {
      if (rootState.initialization.initializing) {
        this.dispatch('initialization/setInitializing', false);
      }
    }
  },
  async getProjectList({ state, dispatch, commit }, { cache = false } = {}) {
    try {
      const needUpdate = needCacheUpdate(state.projectList.timestamp || 0);

      if (cache && !needUpdate) {
        return;
      }

      const response = await manageProjectsApi.getProjectList();

      if (!response?.data) {
        return;
      }

      commit(types.SET_PROJECT_LIST, projectListResponseAdapter(response.data));

      if (response.data.loadingProjects) {
        dispatch('setProjectListRefetchInterval');
      } else {
        clearInterval(state.projectListRefetchInterval);
        commit(types.SET_PROJECT_LIST_INTERVAL, null);
      }
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'getProjectList' });
    }
  },
  async getAvailableConnectors({ commit }) {
    try {
      const response = await manageProjectsApi.getAvailableConnectors();

      if (!response?.data) {
        return;
      }

      commit(types.SET_AVAILABLE_CONNECTORS, response.data.connectors);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'getAvailableConnectors' });
    }
  },
  setProjectListRefetchInterval({ state, commit, dispatch }) {
    if (state.projectListRefetchInterval) {
      return;
    }

    const refetchInterval = setInterval(() => {
      if (!mainRoutes.includes(getRouteName())) {
        dispatch('getProjectList');
      }
    }, 1000);

    commit(types.SET_PROJECT_LIST_INTERVAL, refetchInterval);
  },
  async handleProjectStop({ state, commit, dispatch }, { id }) {
    try {
      commit(types.SET_IS_LOADING, true);

      const ids = toArray(id);

      await manageProjectsApi.stopProject({ ids });

      if (ids.includes(state.projectId)) {
        dispatch('clearCurrentProject');
      }
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'handleProjectStop' });

      Vue.notify({
        type: 'error',
        text: e?.message
      });

    } finally {
      await dispatch('getProjectList');

      commit(types.SET_IS_LOADING, false);
    }
  },
  async handleProjectStart({ state, commit, dispatch }, { id }) {
    try {
      const ids = toArray(id);

      ids.forEach(id => {
        const { index, project } = getIndexAndProjectById(state.projectList.permanent, id);

        if (index === -1) {
          return;
        }

        commit(types.UPDATE_PROJECT_DATA, {
          index,
          value: {
            ...project,
            progress: {
              min: 0,
              max: 100,
              current: 0
            },
            status: projectStatuses.LOADING
          }
        });
      });

      const response = await manageProjectsApi.startProject({ ids });
      const operations = toArray(response.data);

      await dispatch('getProjectList');

      const requests = operations.map((operation) => {
        return this.dispatch('operations/subscribe', {
          subscriber: () => Promise.resolve({
            data: {
              ...operation.response,
              data: {
                pid: operation.projectId
              }
            }
          }),
          in_progress: ({ progress, subscriberData }) => {
            const { index, project } = getIndexAndProjectById(state.projectList.permanent, subscriberData.data.pid);

            if (index === -1) {
              return;
            }

            commit(types.UPDATE_PROJECT_DATA, {
              index,
              value: {
                ...project,
                progress,
                status: projectStatuses.LOADING
              }
            });
          },
          finished: ({ operationData }) => {
            if (operationData.payload?.restrictedProjectItems) {
              Vue.notify({
                type: 'warn',
                title: vueI18n.tc('Web.FreeVersion.ImportWarningTitle', { 1: operationData.payload.restrictedProjectItems }),
                text: vueI18n.tc('Web.FreeVersion.ImportWarningText'),
                data: {
                  type: notifyLinkTypes.BUILTIN,
                  text: vueI18n.tc('Web.FreeVersion.ImportWarningLink'),
                  callback: () => openLink(SALES_DEMO_URL, true)
                }
              });
            }

            dispatch('getProjectList');
          }
        });
      });

      const results = await Promise.allSettled(requests);

      results.forEach(({ reason }) => {
        if (!reason) {
          return;
        }

        const errorMessage = reason?.description;

        if (errorMessage) {
          Vue.notify({
            type: 'error',
            text: errorMessage,
            duration: 10000
          });
        }
      });
    } catch (e) {
      dispatch('getProjectList');

      const message = e?.message;

      if (message) {
        Vue.notify({
          type: 'error',
          text: message
        });
      }

      this.dispatch('user/logout', { e, from: 'handleProjectStart' });
    }
  },
  handleProjectDownload(_, { id, portable }) {
    return manageProjectsApi.downloadProject({ id, portable });
  },
  async handleProjectArchive({ state, commit, dispatch }, { id }) {
    try {
      commit(types.SET_IS_LOADING, true);

      const ids = toArray(id);

      await manageProjectsApi.archiveProject(
        { action: archiveActonTypes.ARCHIVE },
        { ids }
      );

      if (ids.includes(state.projectId)) {
        dispatch('clearCurrentProject');
      }
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'handleProjectArchive' });
    } finally {
      await dispatch('getProjectList');

      commit(types.SET_IS_LOADING, false);
    }
  },
  async handleProjectUnarchive({ commit, dispatch }, { id }) {
    try {
      commit(types.SET_IS_LOADING, true);

      const ids = toArray(id);

      await manageProjectsApi.archiveProject(
        { action: archiveActonTypes.UNARCHIVE },
        { ids }
      );
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'handleProjectUnarchive' });
    } finally {
      await dispatch('getProjectList');

      commit(types.SET_IS_LOADING, false);
    }
  },
  async handleProjectDelete({ state, commit, dispatch }, { id }) {
    try {
      commit(types.SET_IS_LOADING, true);

      const ids = toArray(id);

      await manageProjectsApi.deleteProject({ ids });

      if (ids.includes(state.projectId)) {
        dispatch('clearCurrentProject');
      }
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'handleProjectDelete' });
    } finally {
      await dispatch('getProjectList');

      commit(types.SET_IS_LOADING, false);
    }
  },
  async handleProjectRename({ commit, dispatch }, { id, name }) {
    try {
      commit(types.SET_IS_LOADING, true);

      await manageProjectsApi.renameProject(
        { id },
        { name }
      );
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'handleProjectRename' });
    } finally {
      await dispatch('getProjectList');

      commit(types.SET_IS_LOADING, false);
    }
  },
  async uploadProject({ dispatch }, file) {
    try {
      const { operationData } = await this.dispatch('operations/subscribe', {
        subscriber: () => manageProjectsApi.uploadProject(fileToFormData(file, 'TranFile'))
      });

      const id = operationData?.payload?.projectId;

      if (!id) {
        throw new Error('Internal error: Server responded with empty projectId');
      }

      await dispatch('getProjectList');

      return id;
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'uploadProject' });

      throw e;
    }
  },
  async fetchExamples({ commit, state }) {
    if (state.examples) {
      return;
    }

    try {
      const response = await manageProjectsApi.getExamples();

      if (!response?.data?.projects) {
        return;
      }

      commit(types.SET_PROJECT_EXAMPLES, response.data.projects);
    } catch (e) {
      logger.formatAndWriteError({
        e,
        from: 'fetchExamples'
      });
    }
  },
  async openExample({ dispatch }, id) {
    try {
      this.dispatch('initialization/setInitializing', true);

      const response = await manageProjectsApi.openExample({ id });

      const pid = response?.data?.pid;

      if (!pid) {
        return this.dispatch('initialization/setInitializing', false);
      }

      dispatch('openProject', {
        pid,
        type: projectTypes.EXAMPLE
      });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'openExample' });
      this.dispatch('initialization/setInitializing', false);
    }
  },
  updateFilter({ commit }, payload) {
    commit(types.SET_FILTER, payload);
  },
  async createProject({ dispatch }, name) {
    try {
      const response = await manageProjectsApi.createProject({ name });

      const pid = response?.data?.pid;

      if (!pid) {
        return;
      }

      return dispatch('openProject', {
        pid,
        type: projectTypes.UNINITIALIZED,
        redirect: routeNames.INTEGRATIONS
      });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'createEmptyProject' });
    }
  }
};

export default {
  namespaced: true,
  modules: {
    backups
  },
  state,
  mutations,
  actions,
  getters
};
