import bomApi from '@/api/bom';
import GenericTableAdapter from '@/adapters/response/GenericTable.adapter';
import { bomRequestParamByType, bomTypes, emptyBomLocation } from '@/config/item/bom.config';
import { getOpenNodes } from '@/helpers/boms';

const types = {
  SET_LOCATIONS: 'SET_LOCATIONS',
  SET_ACTIVE_LOCATION: 'SET_ACTIVE_LOCATION',
  SET_TABLE: 'SET_TABLE',
  REMOVE_ROWS: 'REMOVE_ROWS',
  APPEND_ROWS: 'APPEND_ROWS',
  SET_OPEN_NODES: 'SET_OPEN_NODES',
  SET_SELECTED_ENTRY: 'SET_SELECTED_ENTRY',
  RESET_STATE: 'RESET_STATE'
};

const initialState = () => ({
  locations: [],
  active_location: null,
  table: null,
  selected_entry: null,
  open_nodes: []
});

const state = initialState();

const mutations = {
  [types.SET_LOCATIONS](state, value) {
    state.locations = value;
  },
  [types.SET_ACTIVE_LOCATION](state, value) {
    state.active_location = value;
  },
  [types.SET_TABLE](state, value) {
    state.table = value;
  },
  [types.REMOVE_ROWS](state, { index, count }) {
    state.table.rows.splice(index + 1, count);
  },
  [types.APPEND_ROWS](state, { rowIndex, rows }) {
    state.table.rows.splice(rowIndex + 1, 0, ...rows);
  },
  [types.SET_OPEN_NODES](state, value) {
    state.open_nodes = value;
  },
  [types.SET_SELECTED_ENTRY](state, value) {
    state.selected_entry = value;
  },
  [types.RESET_STATE](state) {
    const initial = initialState();

    Object.keys(state).forEach(key => {
      state[key] = initial[key];
    });
  }
};

const getters = {
  commonRequestParams: (state) => (type) => {
    const { item, location } = state.selected_entry || {};

    return {
      item,
      type: bomRequestParamByType[type],
      location: state.active_location !== emptyBomLocation
        ? location || state.active_location || ''
        : ''
    };
  },
  openNodesParam: (state) => (id) => {
    if (!id) {
      return;
    }

    return [id, ...state.open_nodes].join(',');
  }
};

const actions = {
  async fetchBomLocations({ commit }) {
    try {
      const response = await bomApi.getLocations();
      const locations = response?.data?.locations;

      if (!locations) {
        return;
      }

      commit(types.SET_LOCATIONS, locations);

      if (locations.length) {
        commit(types.SET_ACTIVE_LOCATION, locations[0] || emptyBomLocation);
      }
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchBomLocations' });
    }
  },
  async fetchBomTree({ commit, getters }, { type, id } = {}) {
    try {
      const response = await bomApi.getTree({
        ...getters.commonRequestParams(type),
        expand: type === bomTypes.ITEM,
        nodes: getters.openNodesParam(id)
      });

      const table = GenericTableAdapter(response.data);

      commit(types.SET_TABLE, table);
      commit(types.SET_OPEN_NODES, getOpenNodes({ rows: table.rows, type }));
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchBomTree' });
    }
  },
  async fetchBomTreeNodes({ state, getters, commit }, { type, nodeId, rowIndex }) {
    try {
      const response = await bomApi.getTreeByNode({
        ...getters.commonRequestParams(type),
        node: nodeId
      });

      if (!response?.data) {
        return;
      }

      const rows = GenericTableAdapter(response.data).rows;

      commit(types.APPEND_ROWS, {
        rowIndex,
        rows
      });

      commit(types.SET_OPEN_NODES, [...state.open_nodes, nodeId]);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchBomTreeNodes' });
    }
  },
  collapseBomNode({ state, commit }, { index, depth, rowId }) {
    let numberOfChildNodes = 0;
    const collapsedNodes = [rowId];
    const rows = state.table.rows;

    for (let i = index + 1; i < rows.length; i++) {
      const currentRow = rows[i];

      if (currentRow.depth > depth) {
        numberOfChildNodes++;
        collapsedNodes.push(currentRow.id);
      } else {
        break;
      }
    }

    const openNodes = state.open_nodes.filter(id => !collapsedNodes.includes(id));

    commit(types.REMOVE_ROWS, {
      index,
      count: numberOfChildNodes
    });
    commit(types.SET_OPEN_NODES, openNodes);
  },
  async fetchTreeSearch({ getters }, { type, query }) {
    try {
      const response = await bomApi.getTreeSearch({
        ...getters.commonRequestParams(type),
        query
      });

      return response.data.nodes;
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchTreeSearch' });
    }
  },
  exportToXlsx({ getters }, type) {
    return bomApi.exportTree(getters.commonRequestParams(type));
  },
  setSelectedEntry({ commit }, entry) {
    commit(types.SET_SELECTED_ENTRY, entry);

    if (entry.location !== undefined) {
      commit(types.SET_ACTIVE_LOCATION, entry.location || emptyBomLocation);
    }
  },
  setActiveLocation({ commit }, location) {
    commit(types.SET_ACTIVE_LOCATION, location);
  },
  resetState({ commit }) {
    commit(types.RESET_STATE);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
