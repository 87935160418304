import Vue from 'vue';
import promotionApi from '@/api/promotion';
import GenericTableAdapter from '@/adapters/response/GenericTable.adapter';
import { getTableConfigParams, getTableConfigStructure, sortingParamsAdapter } from '@/helpers/shared/tableConfig';

const types = {
  SET_PROMOTIONS: 'SET_PROMOTIONS',
  UPDATE_TABLE_CONFIG: 'UPDATE_TABLE_CONFIG',
  SET_TABLE_CONFIG: 'SET_TABLE_CONFIG'
};

const state = () => ({
  promotions: null,
  tableConfig: getTableConfigStructure()
});

const mutations = {
  [types.SET_PROMOTIONS](state, value) {
    state.promotions = value;
  },
  [types.UPDATE_TABLE_CONFIG](state, { key, value }) {
    Vue.set(state.tableConfig, key, value);
  },
  [types.SET_TABLE_CONFIG](state, payload) {
    Object.assign(state.tableConfig, payload);
  }
};

const actions = {
  async fetchPromotions({ state, commit, rootGetters }) {
    try {
      const nodeId = rootGetters['demand/tree/activeNodeId'];

      const response = await promotionApi.getPromotions({
        nodeId,
        ...getTableConfigParams(state.tableConfig)
      });
      const table = response?.data;

      if (table) {
        commit(types.SET_PROMOTIONS, GenericTableAdapter(table));
        commit(types.SET_TABLE_CONFIG, sortingParamsAdapter(table));
      }
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchPromotions' });
    }
  },
  updateTableConfig({ commit }, payload) {
    commit(types.UPDATE_TABLE_CONFIG, payload);
  },
  async importFromXlsx(_, file) {
    try {
      await promotionApi.importFromXlsx(file);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'promotions/importFromXlsx' });

      throw e;
    }
  },
  exportToXlsx({ rootGetters }) {
    const nodeId = rootGetters['demand/tree/activeNodeId'];

    return promotionApi.exportToXlsx({ nodeId });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
