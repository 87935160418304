import Vue from 'vue';
import i18n from '@/plugins/vue-i18n';
import collectionsApi from '@/api/reports/inventoryReport/collections';
import GenericTableAdapter from '@/adapters/response/GenericTable.adapter';
import { containerTypes, collectionCols, containersNames } from '@/config/report/inventoryReport/collections.config';
import statusCodes from '@/config/utils/statusCodes.config';
import { getTableConfigParams, getTableConfigStructure, sortingParamsAdapter } from '@/helpers/shared/tableConfig';
import { findCurrentRowIndex } from '@/helpers/shared/slTable';
import { FilterObject } from '@/helpers/api/adapters';

const actionByType = {
  [containerTypes.CONTAINER]: 'fetchContainers',
  [containerTypes.GROUP]: 'fetchGroups',
  [containerTypes.MANUFACTURE_GROUP]: 'fetchManufactureGroups'
};

const types = {
  SET_TABLE: 'SET_TABLE',
  SET_TAB: 'SET_TAB',
  DELETE_ITEM: 'DELETE_ITEM',
  SELECT_ROW: 'SELECT_ROW',
  SUBMIT_CELL: 'SUBMIT_CELL',
  CREATE_ITEM: 'CREATE_ITEM',
  UPDATE_TABLE_CONFIG: 'UPDATE_TABLE_CONFIG',
  SET_TABLE_CONFIG: 'SET_TABLE_CONFIG',
  RESET_STATE: 'RESET_STATE'
};

const initialState = () => ({
  [containerTypes.CONTAINER]: {
    table: null,
    tableConfig: getTableConfigStructure()
  },
  [containerTypes.GROUP]: {
    table: null,
    tableConfig: getTableConfigStructure()
  },
  [containerTypes.MANUFACTURE_GROUP]: {
    table: null,
    tableConfig: getTableConfigStructure()
  },
  tab: containerTypes.CONTAINER
});

const state = initialState();

const getters = {
  getExistingIds: state => table => {
    return state[table]?.table?.rows?.map(row => row.identifier.val);
  },
  getCreateData: (state, _, __, rootGetters) => type => {
    const defaultValues = {
      loadWeight: rootGetters['settings/containerWeight'],
      loadVolume: rootGetters['settings/containerVolume'],
      loadQty: rootGetters['settings/containerQty'],
      orderingCost: rootGetters['settings/containerCost']
    };

    return state[type]?.table?.headers?.reduce((acc, { key, name: text }) => {
      const newItem = {
        key,
        text
      };

      if (type === containerTypes.CONTAINER && defaultValues[key]) {
        newItem.default = defaultValues[key];
      }

      if (!Object.values(collectionCols).includes(key)) {
        acc.push(newItem);
      }

      return acc;
    }, []);
  },
  collectionOptionsByType: state => table => {
    return state[table]?.table?.rows?.map((el) => ({
      value: el.identifier.val,
      text: el.identifier.val
    })) || [];
  },
  isEdited: state => {
    return Object.values(containerTypes).some(type => {
      return state[type]?.table?.metadata?.edited;
    });
  }
};

const mutations = {
  [types.SET_TABLE](state, { type, table }) {
    Vue.set(state[type], 'table', table);
  },
  [types.SET_TAB](state, tab) {
    state.tab = tab;
  },
  [types.UPDATE_TABLE_CONFIG](state, { key, value }) {
    Vue.set(state[state.tab].tableConfig, key, value);
  },
  [types.SET_TABLE_CONFIG](state, { type, value }) {
    Object.assign(state[type].tableConfig, value);
  },
  [types.SELECT_ROW](state, { cell, cellKey, value }) {
    Vue.set(cell, cellKey, value);
  },
  [types.SUBMIT_CELL](state, { cell, cellKey, value }) {
    Vue.set(cell, cellKey, value);
  },
  [types.RESET_STATE](state) {
    const initial = initialState();

    Object.keys(initial).forEach(key => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  setTab({ commit }, tab) {
    commit(types.SET_TAB, tab);
  },
  updateTableConfig({ commit }, payload) {
    commit(types.UPDATE_TABLE_CONFIG, payload);
  },
  async fetchContainers({ state, commit, getters }) {
    try {
      const response = await collectionsApi.getEntities({
        type: containerTypes.CONTAINER,
        ...getTableConfigParams(state[state.tab].tableConfig)
      });
      const table = response?.data;

      if (!table) {
        return;
      }

      commit(types.SET_TABLE, {
        type: containerTypes.CONTAINER,
        table: GenericTableAdapter(table)
      });

      this.dispatch('inventoryReport/setDropdown', {
        key: containerTypes.CONTAINER,
        value: getters.collectionOptionsByType(containerTypes.CONTAINER)
      });

      commit(types.SET_TABLE_CONFIG, {
        type: containerTypes.CONTAINER,
        value: sortingParamsAdapter(table)
      });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchContainers' });
    }
  },
  async fetchAllGroups({ dispatch }) {
    try {
      await Promise.allSettled([
        dispatch('fetchGroups'),
        dispatch('fetchManufactureGroups')
      ]);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchAllGroups' });
    }
  },
  async fetchGroups({ state, commit, getters }) {
    try {
      const response = await collectionsApi.getEntities({
        type: containerTypes.GROUP,
        ...getTableConfigParams(state[state.tab].tableConfig)
      });
      const table = response?.data;

      commit(types.SET_TABLE, {
        type: containerTypes.GROUP,
        table: GenericTableAdapter(table)
      });

      this.dispatch('inventoryReport/setDropdown', {
        key: containerTypes.GROUP,
        value: getters.collectionOptionsByType(containerTypes.GROUP)
      });

      commit(types.SET_TABLE_CONFIG, {
        type: containerTypes.GROUP,
        value: sortingParamsAdapter(table)
      });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchGroups' });
    }
  },
  async fetchManufactureGroups({ state, commit, getters }) {
    try {
      const response = await collectionsApi.getEntities({
        type: containerTypes.MANUFACTURE_GROUP,
        ...getTableConfigParams(state[state.tab].tableConfig)
      });
      const table = response?.data;

      commit(types.SET_TABLE, {
        type: containerTypes.MANUFACTURE_GROUP,
        table: GenericTableAdapter(table)
      });

      this.dispatch('inventoryReport/setDropdown', {
        key: containerTypes.MANUFACTURE_GROUP,
        value: getters.collectionOptionsByType(containerTypes.MANUFACTURE_GROUP)
      });

      commit(types.SET_TABLE_CONFIG, {
        type: containerTypes.MANUFACTURE_GROUP,
        value: sortingParamsAdapter(table)
      });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchManufactureGroups' });
    }
  },
  async fetchEntities({ dispatch }) {
    try {
      await Promise.allSettled([
        dispatch('fetchContainers'),
        dispatch('fetchGroups'),
        dispatch('fetchManufactureGroups')
      ]);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchEntities' });
    }
  },
  async fetchEntityByType({ state, dispatch }, type = state.tab) {
    try {
      await dispatch(actionByType[type]);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchEntityByType' });
    }
  },
  async updateOverrides({ state }, { id, ...body }) {
    try {
      await collectionsApi.updateEntity({
        global: !id,
        id,
        type: state.tab,
        ...(!id && { query: getTableConfigParams(state[state.tab].tableConfig).query })
      }, body);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'collections/updateOverrides' });
    }
  },
  submitCell({ state, commit }, { value, rowId, cellKey }) {
    const rows = state[state.tab].table.rows;
    const rowIndex = findCurrentRowIndex({
      table: rows,
      rowId,
      key: 'id'
    });

    if (rowIndex !== -1) {
      const oldCell = rows[rowIndex][cellKey];
      const newValue = {
        ...oldCell,
        val: value
      };

      commit(types.SUBMIT_CELL, {
        cell: rows[rowIndex],
        cellKey,
        value: newValue
      });
    }
  },
  selectRow({ state, commit }, { rowId, cellKey, value }) {
    const rows = state[state.tab].table.rows;
    const rowIndex = rows.findIndex(row => row.id === rowId);

    commit(types.SELECT_ROW, {
      cell: rows[rowIndex],
      cellKey,
      value: {
        val: value
      }
    });
  },
  async deleteEntities({ state }) {
    try {
      await collectionsApi.deleteEntities({
        type: state.tab
      });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'collections/deleteCollections' });
    }
  },
  async createEntity({ state }, { type = state.tab, ...data }) {
    try {
      const response = await collectionsApi.createEntity(
        { type },
        FilterObject(data)
      );
      const entityName = containersNames[type];

      if (response.status === statusCodes.OK) {
        Vue.notify({
          type: 'success',
          title: i18n.t('Web.Notification.CreatedSuccessfully', { 1: entityName })
        });
      }
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'collections/createOrder' });

      throw e;
    }
  },
  async saveOverrides() {
    try {
      await collectionsApi.saveOverrides();
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'collections/saveOverrides' });

      throw e;
    }
  },
  async discardOverrides() {
    try {
      await collectionsApi.discardOverrides();
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'collections/discardOverrides' });
    }
  },
  async createEntityAndSave({ dispatch }, { type, ...data }) {
    try {
      await dispatch('createEntity', { type, ...data });
      await dispatch('saveOverrides');
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'collections/createEntityAndSave' });
    }
  },
  resetState({ commit }) {
    commit(types.RESET_STATE);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
