<template>
  <component
    :is="rootComponent"
    class="sl-tab body-3-md"
    :to="to"
    :class="{
      'sl-tab--active': active,
      'sl-tab--disabled': disabled,
      'sl-tab--horizontal': horizontal
    }"
  >
    <span
      :class="[{
        'sl-required': required
      }, 'sl-tab__text',
      ]"
    >
      <slot />
      <slot name="append" />
    </span>
  </component>
</template>

<script>
export default {
  name: 'SlTab',
  props: {
    to: {
      type: Object,
      default: () => {}
    },
    active: Boolean,
    disabled: Boolean,
    required: Boolean,
    isLink: Boolean,
    horizontal: Boolean
  },
  computed: {
    rootComponent() {
      return this.isLink ? 'router-link' : 'div';
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/ui-kit/sl-tabs/sl-tab.scss";
</style>
