import { formatFullTime, utcTimezoneOffsetTime } from '@/helpers/date/date';

export const getNoteTooltip = (note) => {
  if (!note) {
    return null;
  }

  return {
    theme: 'dark',
    content: getTooltipContent(note),
    html: true
  };
};

export const getTooltipContent = (data) => {
  if (!data) {
    return '';
  }

  const { body, author, editTime } = data;

  return `
  <div class="split-tooltip">
    <div class="split-tooltip__title">${author}</div>
    <div class="split-tooltip__title">${formatFullTime(editTime)} UTC ${utcTimezoneOffsetTime}</div>
    <div class="split-tooltip--divider"></div>
    <div class="split-tooltip--message multiline-ellipsis">${body.replace(/\n/g, '<br/>')}</div>
  </div>`;
};

export const getNoteKey = (data) => {
  if (!data) {
    return null;
  }

  return Object.values(data).join('_');
};
