import { inventoryTime } from '@/helpers/date/date';
import { DEFAULT_INVENTORY_CELL_INDEX } from '@/config/report';

export default function(data) {
  const dataArray = Array.isArray(data.value) ? data.value : [data.value];

  const rowsValue = dataArray.reduce((acc, _val) => {
    if (_val !== '') {
      acc.push({
        _val
      });
    }

    return acc;
  }, []);

  const entities = data.rows.map(entity => {
    return {
      _item: entity.item,
      _location: entity.location
    };
  });

  return {
    note: {
      _author: 'Streamline Web Client',
      _time: inventoryTime()
    },
    entities: {
      _n: entities.length,
      entity: entities
    },
    cols: {
      _n: 1,
      col: {
        _class: data.class,
        _index: data.index || DEFAULT_INVENTORY_CELL_INDEX
      }
    },
    tableData: {
      _nCols: 1,
      _nRows: dataArray.length,
      col: {
        row: rowsValue
      }
    }
  };
}
