import Vue from 'vue';
import demandApi from '@/api/demand';
import DemandTableResponseAdapter from '@/adapters/response/DemandTableResponse.adapter';
import { colKeys, forecastVersionsMagicIds, headerTitlesMap } from '@/config/demand/table.config';
import { nodeFlags } from '@/config/shared/fgs.config';
import { getResizedIndexesFromDates } from '@/helpers/demand/table';

const MAX_CELL_INDEX = 4294967295;

const types = {
  SET_TABLE: 'SET_TABLE',
  SET_COL_SIZES: 'SET_COL_SIZES',
  UPDATE_TABLE_CELL: 'UPDATE_TABLE_CELL',
  UPDATE_FORECAST_OVERRIDE_CELL: 'UPDATE_FORECAST_OVERRIDE_CELL',
  SET_IS_UPDATING: 'SET_IS_UPDATING'
};

const state = () => ({
  header: null,
  table: null,
  properties: null,
  nodeFlags: null,
  colSizes: null,
  is_updating: false
});

const getters = {
  availableRows: (state) => {
    const titlesMap = headerTitlesMap();
    const altForecastVersions = [];
    const availableRows = state.header?.reduce((rows, row) => {
      const rowClass = row.class;
      const rowObj = {
        fallback: row?.name,
        class: titlesMap[rowClass].requestKey,
        visible: row.visible,
        index: row.index
      };

      if (rowClass === colKeys.customRowOverrides.headerClass && row.index) {
        altForecastVersions.push(rowObj);
      } else {
        rows.push(rowObj);
      }

      return rows;
    }, []);

    if (altForecastVersions.length) {

      availableRows.unshift({
        sublist: altForecastVersions,
        fallback: titlesMap[colKeys.customRowOverrides.headerClass].locale,
        class: titlesMap[colKeys.customRowOverrides.headerClass].requestKey,
        visible: true
      });
    }

    return availableRows;
  },
  visibleRows: (state) => state.header?.reduce((titles, title) => {
    if (title.visible) {
      if (title.class === colKeys.customRowOverrides.headerClass && title.index && title?.name !== '') {
        titles.push(title.name);
      } else {
        titles.push(title.class);
      }
    }

    return titles;
  }, []),
  headers: (state) => {
    return state.header?.reduce((rows, row) => {
      if (row.visible && row.name) {
        rows.push(row.name);
      }

      return rows;
    }, []);
  },
  hasStatisticalForecastRow: (state) => {
    return state.header?.find(header => header.class === colKeys.statisticalForecast.headerClass);
  },
  hasFinalForecastRow: (state) => {
    return state.header?.find(header => header.class === colKeys.customRowOverrides.headerClass && !header.index);
  },
  forecastVersionNames: (state) => {
    const customRowOverrides = state.header?.filter(header => header.class === colKeys.customRowOverrides.headerClass);
    const permanentStatisticalForecast = null;

    return [permanentStatisticalForecast, ...customRowOverrides];
  },
  tablePoints: (state) => state.table || [],
  pointsNumber: (state) => state.table.n,
  useModelFromStatus: (state) => state.properties?.useModelFrom,
  editableNode: (state) => state.nodeFlags & nodeFlags.HAS_EDITABLE_PERMISSION,
  nodeFlagByShift: state => shift => state.nodeFlags & shift
};

const mutations = {
  [types.SET_TABLE](state, { header, points, properties, nodeFlags }) {
    state.header = Object.freeze(header);
    state.table = points;
    state.properties = Object.freeze(properties);
    state.nodeFlags = Object.freeze(nodeFlags);
  },
  [types.UPDATE_TABLE_CELL](state, { index, key, value }) {
    const newCell = {
      ...state.table[index][key],
      val: value
    };

    Vue.set(state.table[index], key, newCell);
  },
  [types.UPDATE_FORECAST_OVERRIDE_CELL](state, { index, key, overrideIndex, value }) {
    const overrides = state.table[index][key];
    const newOverride = {
      ...overrides[overrideIndex],
      val: value,
      ov: true
    };

    Vue.set(overrides, overrideIndex, newOverride);
  },
  [types.SET_COL_SIZES](state, value) {
    state.colSizes = value;
  },
  [types.SET_IS_UPDATING](state, value) {
    state.is_updating = value;
  }
};

const actions = {
  async fetchTable({ commit, rootGetters }) {
    try {
      const response = await demandApi.getTable({
        nodeId: rootGetters['demand/tree/activeNodeId'],
        fgs: 0
      });

      if (response?.data) {
        commit(
          types.SET_TABLE,
          DemandTableResponseAdapter.table({
            data: response.data,
            pKnown: rootGetters['project/pKnown']
          })
        );

        this.dispatch('demand/chart/setForecastTitle',
          response.data.modelTypeName);
      }
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchTable' });
    } finally {
      commit(types.SET_IS_UPDATING, false);
    }
  },
  async updateTable({ commit, rootGetters }, data) {
    try {
      commit(types.SET_IS_UPDATING, true);

      await demandApi.postTable(
        {
          nodeId: rootGetters['demand/tree/activeNodeId']
        },
        data
      );
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'updateTable' });
    }
  },
  async updateOverridePrediction({ commit, getters, rootGetters }, { p, val, isOver }) {
    try {
      commit(types.SET_IS_UPDATING, true);

      const forecastVersion = getters.forecastVersionNames[isOver + 1];
      const body = {
        val: p,
        row: forecastVersion.index ? forecastVersion.name : '',
        formula: `${val}`
      };

      await demandApi.postOverridePrediction(
        {
          nodeId: rootGetters['demand/tree/activeNodeId']
        },
        body
      );
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'updateOverridePrediction' });
    }
  },
  async updateVisibleRows(_, params) {
    await demandApi.postVisibleRows(params);
  },
  async clearNodeOverrides({ commit, rootGetters }) {
    try {
      commit(types.SET_IS_UPDATING, true);

      await demandApi.clearNodeOverrides({
        nodeId: rootGetters['demand/tree/activeNodeId']
      });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'clearNodeOverrides' });
    }
  },
  async clearNodeSettings({ commit, rootGetters }, type) {
    try {
      commit(types.SET_IS_UPDATING, true);

      const editableForSomeChildren = rootGetters['demand/tree/editableForSomeChildren'];
      const params = {
        nodeId: rootGetters['demand/tree/activeNodeId'],
        type,
        allowForAvailableChildren: editableForSomeChildren
      };

      await demandApi.clearNodeSettings(params);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'clearNodeSettings' });
    }
  },
  async applyCellFinalForecast({ dispatch }, { name, position }) {
    dispatch('applyFinalForecast', {
      val: position,
      row: name
    });
  },
  async applyRowFinalForecast({ dispatch }, { name }) {
    dispatch('applyFinalForecast', {
      val: MAX_CELL_INDEX,
      row: name
    });
  },
  async applyFinalForecast({ commit, rootGetters }, payload) {
    try {
      commit(types.SET_IS_UPDATING, true);

      await demandApi.postApplyCell(
        {
          nodeId: rootGetters['demand/tree/activeNodeId']
        },
        payload
      );
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'applyFinalForecast' });
    }
  },
  async propagateValue({ getters, dispatch }, data) {
    const cellIndex = getters.forecastVersionNames.findIndex(f => +f?.index === data.index);

    const payload = {
      p: data.position,
      val: data.value + '>',
      isOver: cellIndex - 1
    };

    dispatch('updateOverridePrediction', payload);
  },
  async copyFromStatisticalForecast({ commit, rootGetters }, payload) {
    try {
      commit(types.SET_IS_UPDATING, true);

      await demandApi.copyFromStatisticalForecast(
        {
          nodeId: rootGetters['demand/tree/activeNodeId']
        },
        payload
      );
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'copyFromStatisticalForecast' });
    }
  },
  async copyCellFromStatisticalForecast({ dispatch }, { name, position }) {
    try {
      dispatch('copyFromStatisticalForecast', {
        val: position,
        row: name,
        type: name ? forecastVersionsMagicIds.CUSTOM : forecastVersionsMagicIds.FINAL
      });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'copyFromStatisticalForecast' });
    }
  },
  async copyRowFromStatisticalForecast({ dispatch }, { name }) {
    try {
      dispatch('copyFromStatisticalForecast', {
        val: MAX_CELL_INDEX,
        row: name,
        type: name ? forecastVersionsMagicIds.CUSTOM : forecastVersionsMagicIds.FINAL
      });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'copyFromStatisticalForecast' });
    }
  },
  updateTableCell({ commit }, payload) {
    commit(types.UPDATE_TABLE_CELL, payload);
  },
  updateForecastOverrideCell({ commit }, payload) {
    commit(types.UPDATE_FORECAST_OVERRIDE_CELL, payload);
  },
  setResizedColumns({ getters, commit }, data) {
    const resizedColumns = getResizedIndexesFromDates(getters.tablePoints, data);

    commit(types.SET_COL_SIZES, resizedColumns);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
